export const OurProcessTextComponents = {
  title: prefix => {
    const title = {
      au: "It's easy, convenient and all about you!",
      ca: "It's easy, convenient and all about you!",
      "fr-ca": "C'est simple, pratique et entièrement axé sur vous !",
      ph: "It's easy, convenient and all about you!",
      nz: "It's easy, convenient and all about you!",
      uk: "It's easy, convenient and all about you!",
    }
    return title[prefix]
  },

  cardOneText: prefix => {
    const cardOneText = {
      au: "We carefully look over your health profile with scrutiny to verify your eligibility for treatment, so that you get the best, appropriate options.",
      ca: "Once your health profile is fully assessed, we shall be in a position to advise on the best available options suited to fit your situation and requirements in a personalised way.",
      "fr-ca":
        "Une fois votre profil de santé entièrement évalué, nous serons en mesure de vous conseiller sur les meilleures options disponibles adaptées à votre situation et à vos besoins de manière personnalisée.",
      nz: "We will carefully review your health profile and determine if you qualify for our treatments to ensure you have the most effective options.",
      uk: "An in-depth review of your health profile is conducted to ensure that we offer the best-suited, most suitable, and effective options according to your needs.",
    }
    return cardOneText[prefix]
  },
  cardTwoText: prefix => {
    const cardTwoText = {
      au: "Our licensed Australian doctors work on a customised treatment plan just for you, according to your needs and lifestyle.",
      ca: "After determining that you are eligible, our licensed Canadian doctors will proceed to design an individualised treatment plan that perfectly suits your lifestyle and health goals.",
      "fr-ca":
        "Après avoir déterminé que vous êtes admissible, nos médecins canadiens agréés travailleront sur un plan de traitement personnalisé qui correspond à votre style de vie et à vos objectifs de santé.",
      nz: "After qualifying, our licensed NZ doctors will collaborate with you to develop a bespoke treatment plan that fits your lifestyle and health goals.",
      uk: "Once approved, our UK-licensed doctors will work with you to put together a treatment package that is bespoke to you, and perfectly suited to your lifestyle.",
    }
    return cardTwoText[prefix]
  },
  cardThreeText: prefix => {
    const cardThreeText = {
      au: "Your treatment will be delivered at your doorstep with a delicate care system, ensuring full privacy and discretion.",
      ca: "Your treatment will be sent out to you quickly, yet carefully, so that you can feel fully assured in your privacy.",
      "fr-ca":
        "Votre traitement sera envoyé à votre porte rapidement, mais avec le plus grand soin, afin que vous puissiez vous sentir complètement assuré de votre confidentialité.",
      nz: "Your treatment will be swift and discreet. Delivered to your door with the highest level of care and absolute privacy.",
      uk: "Your order is delivered in the shortest time possible and with maximum carefulness, which guarantees complete privacy and secrecy.",
    }
    return cardThreeText[prefix]
  },
  cardFourText: prefix => {
    const cardFourText = {
      au: "We offer monthly follow-up meetings in order to monitor progress, whereby maximum results are achieved with the highest level of care.",
      ca: "We offer month-to-month follow-up support, monitoring your progress to ensure optimal results with consistent and personalised care.",
      "fr-ca":
        "Nous offrons un suivi mensuel pour surveiller votre progression, garantissant des résultats optimaux avec une attention personnalisée et continue.",
      nz: "We conduct monthly follow-ups to monitor your progress closely, ensuring you receive the best possible care and achieve lasting, effective results.",
      uk: "We have monthly support for monitoring your progress while working to achieve the best possible outcome and care.",
    }
    return cardFourText[prefix]
  },
}
