import * as React from "react"
import SelectArrowDown from "../../../../assets/common/selectArrowDown.svg"

export const Select = ({
  className,
  isSelectOpen,
  setIsSelectOpen,
  selectedCategory,
  setSelectedCategory,
  optionArray,
  setSubmitData,
  selectFieldName = "pdf_selection",
}) => {
  return (
    <div
      className={`${
        className ? className : "border-with-radius-10 w-full bg-F5F5F5"
      }`}
    >
      <div
        className={`relative cursor-pointer ${!className && "p-20"}`}
        onClick={() => setIsSelectOpen(!isSelectOpen)}
      >
        <SelectArrowDown
          className={`absolute right-30 ${className ? "top-8" : "top-30"}`}
        />
        {selectedCategory}
      </div>
      {isSelectOpen && (
        <>
          {optionArray.map((item, index) => {
            return (
              <div
                className={`cursor-pointer text-828282 ${
                  className ? "pt-10" : "p-20"
                }`}
                onClick={() => {
                  if (setSubmitData) {
                    setSubmitData(prev => {
                      return { ...prev, [selectFieldName]: item.value }
                    })
                  }
                  setSelectedCategory(item.text)
                  setIsSelectOpen(false)
                }}
                key={index}
              >
                {item.text}
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}
