import React from "react"
import ThankYou from "../../components/PageComponents/thank-you"

const ThankYouPage = ({ location }) => {
  const prefix = "au"

  return (
    <ThankYou 
      prefix={prefix} 
      location={location} 
      link="https://www.youtube.com/embed/5lfI4rsCBRg?si=scd2AEJv284du4bn" 
    />
  )
}

export default ThankYouPage
