import BoxBottleImg from "../images/mocks/boxBottle2.webp"
import DropperImg from "../images/mocks/dropperMockup.webp"
import BoxImg from "../images/mocks/boxMockup.webp"
import DrFred from "../images/experts/DrFred.webp"
import DrNick from "../images/experts/DrNick.webp"
import DrJonathan from "../images/experts/DrJonathan.webp"
import { TextCopies } from "../helpers/countryTextCopies"

export const assessmentQuizzesArr = (
  source,
  formCompleted,
  formUrl,
  login,
  prefix
) => {
  switch (prefix) {
    case "au":
      return [
        {
          text: "Erectile Dysfunction",
          img: BoxBottleImg,
          link: `https://app.menshealthclinic.com/assessment/quiz?category_id=1&source=${source}&formCompleted=${formCompleted}&prefix=${prefix}&formUrl=?${formUrl}`,
        },
        {
          text: "Premature Ejaculation",
          img: BoxImg,
          link: `https://app.menshealthclinic.com/assessment/quiz?category_id=2&source=${source}&formCompleted=${formCompleted}&prefix=${prefix}&formUrl=?${formUrl}`,
        },
        {
          text: "Chronic Pain",
          img: DropperImg,
          link: `https://app.menshealthclinic.com/assessment/quiz?category_id=3&source=${source}&formCompleted=${formCompleted}&prefix=${prefix}&formUrl=?${formUrl}`,
        },
      ]

    case "ca":
      return [
        {
          text: "Erectile Dysfunction",
          img: BoxBottleImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=1",
        },
        {
          text: "Premature Ejaculation",
          img: BoxImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=2",
        },
      ]
    case "fr-ca":
      return [
        {
          text: "Dysfonction érectile",
          img: BoxBottleImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=1",
        },
        {
          text: "Éjaculation précoce",
          img: BoxImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=2",
        },
      ]
    case "ph":
      return [
        {
          text: "Erectile Dysfunction",
          img: BoxBottleImg,
          link: TextCopies.assessmentQuizLinks("ph"),
        },
        {
          text: "Premature Ejaculation",
          img: BoxImg,
          link: TextCopies.assessmentQuizLinks("ph"),
        },
      ]
    case "nz":
      return [
        {
          text: "Erectile Dysfunction",
          img: BoxBottleImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=1",
        },
        {
          text: "Premature Ejaculation",
          img: BoxImg,
          link: "https://app.menshealthclinic.com/assessment/quiz?category_id=2",
        },
      ]
    case "uk":
      return [
        {
          text: "Erectile Dysfunction",
          img: BoxBottleImg,
          link: TextCopies.assessmentQuizLinks("uk"),
        },
        {
          text: "Premature Ejaculation",
          img: BoxImg,
          link: TextCopies.assessmentQuizLinks("uk"),
        },
      ]
  }
}

export const experts = [
  {
    doctor: "Dr. Fred Edwards",
    img: DrFred,
    text: "Dr. Fred Edwards is a qualified GP and has over 30+ years’ experience. He aims to widen the scope of treatment within MHC to include general health issues to help men.",
    imgText: "MBBS, FRACGP",
  },
  {
    doctor: "Dr. Nick Travers",
    img: DrNick,
    text: "Dr. Nick Travers is a GP who completed industry leading training in the United Kingdom. Dr. Travers has a special interest in male Sexual Health with a focus on improving male mental health.",
    imgText: "MBBS, FRACGP",
  },
  {
    doctor: "Dr. Jonathan Herald",
    img: DrJonathan,
    text: "Voted as Best Doctor (orthopaedics) on the internationally doctor-rated Best Doctor website; Dr Herald has 20 years’ surgical experience and has a passion for helping patients with their Chronic Pain management.",
    imgText: "MBBS, MSpMed",
  },
]
