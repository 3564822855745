import * as React from "react"

const CAPrivacyPolicy = () => {
  return (
    <main>
      <section className="width__container mb-165 flex justify-center">
        <div>
          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              À propos de MHC et de cette déclaration de confidentialité.
            </h2>
            <p className="mb-20">
              MHC Men's Health Clinic (BC 1317300), faisant affaire sous le nom
              de Men’s Health Clinic (<b>MHC, nous, nous </b>ou <b>notre</b>),
              fournit des solutions personnalisées et gérées pour le traitement
              de la santé sexuelle des hommes.
            </p>
            <p className="mb-20">
              MHC s'engage à la protection de vos informations personnelles.
            </p>
            <p className="mb-20">
              Cette politique de confidentialité (<b>cette politique</b>) traite
              de la manière dont nous collecterons, utiliserons, divulguerons,
              stockerons et protégerons les informations personnelles collectées
              auprès de vous. Cette politique décrit également la manière dont
              vous pouvez accéder à vos informations personnelles, les corriger,
              et comment nous contacter en cas de plaintes concernant votre vie
              privée.
            </p>

            <p>
              Nous traiterons vos informations personnelles conformément aux
              lois applicables sur la confidentialité et les dossiers médicaux,
              notamment la Loi sur la protection de la vie privée, la Loi sur la
              protection des renseignements personnels et les documents
              électroniques (LPRPDE), la Loi de 2004 sur la protection des
              renseignements personnels sur la santé et la Loi sur l'accès à
              l'information.
            </p>
          </div>
          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Qu'est-ce que l'« information personnelle » ?
            </h2>
            <p className="mb-20">
              Cette politique s'applique à notre traitement des informations
              personnelles. « Information personnelle » désigne des informations
              concernant une personne identifiable ou une personne
              raisonnablement identifiable. Cela inclut des informations,
              qu'elles soient vraies ou non, et que les informations soient
              enregistrées sous une forme matérielle ou non.
            </p>
            <p>
              L'information personnelle inclut les « renseignements sur la santé
              », qui est un type particulier d'information personnelle. Les
              renseignements sur la santé comprennent des renseignements
              d'identification sur votre santé (comme des détails sur votre
              santé et antécédents médicaux, des informations sur les handicaps
              que vous avez ou avez eus, ou les services de santé ou services
              aux personnes handicapées que vous recevez ou avez reçus, des
              informations sur tout don, test ou examen d'une partie du corps ou
              d'une substance corporelle), et inclut également des informations
              vous concernant qui sont recueillies avant, au cours de, et
              incidentes à la fourniture de tout service de santé ou service aux
              personnes handicapées.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Pourquoi recueillons-nous vos informations personnelles ?
            </h2>
            <p className="mb-20">
              Nous pouvons recueillir vos informations personnelles lorsque cela
              est nécessaire pour nos fonctions et activités, y compris la
              prestation de services de santé pour hommes et d'autres services
              que nous fournissons. En particulier, nous pouvons collecter vos
              informations personnelles :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                pour vous fournir des services de santé pour hommes et d'autres
                services ;
              </li>
              <li className="list-disc">
                pour vous fournir des informations concernant nos services ;
              </li>
              <li className="list-disc">
                pour organiser des rendez-vous, des facturations et des
                paiements avec vous pour nos services ; ou
              </li>
              <li className="list-disc">
                pour obtenir votre consentement aux services et activités
                mentionnés ci-dessus.
              </li>
            </ul>
            <p>
              Vous n'êtes pas obligé de divulguer vos informations personnelles.
              Cependant, si vous ne fournissez pas les informations demandées,
              nous pourrions ne pas être en mesure de vous fournir des services
              ou des traitements appropriés, de vous fournir des informations
              pertinentes concernant nos services ou de remplir toute autre fin
              applicable de la collecte.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Comment recueillons-nous vos informations personnelles?
            </h2>
            <p className="mb-20">
              Nous recueillerons vos informations personnelles de manière légale
              et équitable, et de manière qui n'empiète pas de manière
              déraisonnable sur vos affaires personnelles.
            </p>
            <p className="mb-20">
              Nous ne recueillerons vos informations personnelles et sensibles
              que si vous y avez consenti, ou autrement conformément à la loi.
            </p>
            <p className="mb-20">
              Nous recueillerons généralement vos informations personnelles
              directement auprès de vous par le biais de vos interactions avec
              nous. Cela peut se faire par communication directe entre vous et
              le personnel du MHC, ou par soumission d'un formulaire
              d'inscription ou d'un autre formulaire en ligne sur le site web du
              MHC, par exemple lorsque vous vous inscrivez à notre newsletter,
              achetez un produit ou un service, ou participez à un quiz. Le site
              web du MHC n'est pas destiné aux enfants et nous ne recueillons
              délibérément aucune donnée concernant les enfants.
            </p>
            <p className="mb-20">
              Nous pouvons parfois recueillir des informations personnelles vous
              concernant auprès d'un tiers lorsque cela est permis par la loi.
              Cela peut inclure, sans s'y limiter, la collecte auprès des tiers
              suivants : d'autres professionnels de la santé impliqués dans
              votre traitement, des membres de la famille ou d'autres personnes
              que vous avez autorisées à nous fournir vos informations (telles
              qu'un représentant légal ou un tuteur), des centres de diagnostic
              et des laboratoires de pathologie, des spécialistes, des
              pharmaciens et des services de prescription électronique,
              Medicare, votre assureur santé privé ou autre assureur. Si vous
              êtes candidat à un emploi, entrepreneur ou prestataire de
              services, nous pouvons recueillir vos informations personnelles
              auprès de vos références et lors de vérifications nécessaires
              telles qu'une vérification de casier judiciaire.
            </p>
            <p className="mb-20">
              Lorsque cela est permis par la loi, nous pouvons également
              recueillir des informations techniques liées à votre interaction
              avec le site web du MHC (telles que l'adresse protocole Internet
              (IP), le type et la version du navigateur, le paramètre de fuseau
              horaire et de localisation, le système d'exploitation et la
              plate-forme, et d'autres technologies) auprès des parties
              suivantes : fournisseurs d'analyses tels que Google ; réseaux
              publicitaires ; et fournisseurs d'informations de recherche.
            </p>
            <p>
              Lorsque nous recueillons vos informations personnelles, nous
              prendrons dès que possible des mesures raisonnables pour vous
              informer des détails de la collecte (y compris en vous informant
              par le biais de cette Politique), tels que les finalités pour
              lesquelles les informations ont été recueillies, les organisations
              (le cas échéant) auxquelles les informations seront divulguées, et
              vous informer également que cette Politique contient des détails
              sur la manière dont vous pouvez accéder ou corriger vos
              informations, ou soulever des plaintes.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Quels types d'informations personnelles recueillons-nous
              habituellement?
            </h2>
            <p className="mb-20">
              Nous pouvons recueillir des informations personnelles (y compris
              des informations de santé) telles que :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                votre nom, date de naissance, genre, adresse postale, adresse
                électronique et numéros de téléphone ;
              </li>
              <li className="list-disc">
                votre historique de santé et médical, historique de mode de vie,
                historique familial, état civil, proche parent et autres membres
                de la famille ;
              </li>
              <li className="list-disc">
                votre profession et détails d'emploi, religion, pays de
                naissance, statut autochtone et origine raciale ou ethnique ;
              </li>

              <li className="list-disc">
                votre fonds de santé privé et les détails de votre assurance
                santé privée le cas échéant ;
              </li>
              <li className="list-disc">
                les détails de votre réclamation ACC ou autre assurance le cas
                échéant ;
              </li>
              <li className="list-disc">
                notes de vos symptômes ou diagnostic et le traitement qui vous a
                été administré ;
              </li>
              <li className="list-disc">
                vos rapports médicaux et résultats d'analyses ;
              </li>
              <li className="list-disc">
                vos ordonnances et autres achats pharmaceutiques ;
              </li>
              <li className="list-disc">
                vos détails de rendez-vous, de paiement et de facturation, tels
                que les détails de la carte de crédit ;
              </li>
              <li className="list-disc">
                détails des produits et services que vous avez achetés chez
                nous, ainsi que vos intérêts et préférences recueillis à partir
                de vos commentaires et réponses à des sondages ; et
              </li>
              <li className="list-disc">
                vos préférences en matière de réception de marketing de notre
                part et/ou de tiers, ainsi que vos préférences en matière de
                communication.
              </li>
            </ul>
            <p>
              Nous pouvons recueillir des informations personnelles auprès de
              personnes qui ne sont pas des patients du MHC, telles que des
              prestataires de services ou des entrepreneurs, afin de nous
              permettre de travailler ou de faire des transactions avec eux.
              Cela peut inclure des informations personnelles fournies dans le
              cadre de demandes d'emploi et de curriculum vitae, de propositions
              et de contrats.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Comment utilisons-nous vos informations personnelles?
            </h2>
            <p className="mb-20">
              Si vous êtes patient du MHC, nous utilisons généralement vos
              informations personnelles aux fins principales suivantes :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                pour communiquer avec vous concernant le service de santé pour
                hommes qui vous est fourni, et pour évaluer et comprendre votre
                santé et vos besoins afin de vous fournir des services et des
                conseils appropriés ;
              </li>
              <li className="list-disc">
                pour répondre de manière appropriée à toute demande ou plainte
                que vous pourriez formuler ;
              </li>
              <li className="list-disc">
                pour vous contacter en relation avec vos soins de santé, en cas
                d'urgence, ou pour obtenir votre autorisation en relation avec
                tout service ;
              </li>
              <li className="list-disc">pour vérifier votre identité ;</li>
              <li className="list-disc">
                pour nous aider à gérer nos comptes et services administratifs,
                y compris le paiement et la facturation, ainsi que les
                arrangements avec Medicare, les fonds de santé privés et autres
                assureurs ;
              </li>
              <li className="list-disc">
                pour obtenir, analyser et discuter des résultats d'analyses
                provenant des laboratoires de pathologie ;
              </li>
              <li className="list-disc">
                pour fournir des ordonnances lorsque cela est approprié ;
              </li>
              <li className="list-disc">
                pour solliciter votre participation <span>à la recherche</span>{" "}
                ;
              </li>
              <li className="list-disc">
                si vous avez donné votre consentement, pour communiquer avec
                vous au sujet de nos services, événements ou promotions qui
                pourraient vous concerner ;
              </li>
              <li className="list-disc">
                si vous êtes candidat à un emploi, entrepreneur ou prestataire
                de services, pour (le cas échéant) évaluer votre candidature,
                proposition ou contrat, contacter vos références, faciliter et
                évaluer toute vérification nécessaire (comme une vérification de
                casier judiciaire), et également pour gérer notre relation avec
                vous ;
              </li>
              <li className="list-disc">
                pour entreprendre des activités d'accréditation, d'assurance
                qualité et de sécurité, et d'audit clinique ;
              </li>
              <li className="list-disc">
                pour dé-identifier les informations (c'est-à-dire en supprimant
                toutes vos informations personnelles d'identification) afin de
                produire des informations statistiques ou démographiques
                dé-identifiées.
              </li>
            </ul>
            <p className="mb-20">
              Nous pouvons également utiliser vos informations personnelles à
              des fins directement liées à ces objectifs principaux, dans des
              circonstances où vous vous attendriez raisonnablement à ce que
              nous utilisions vos informations à ces fins.
            </p>
            <p className="mb-20">
              Nous pouvons également utiliser vos informations personnelles à
              des fins permises par les lois sur la vie privée applicables, ce
              qui peut inclure ce qui suit :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                pour la gestion, la planification ou l'évaluation de nos
                services, où nous prenons toutes les mesures raisonnables pour
                dé-identifier tout matériel utilisé ; et
              </li>
              <li className="list-disc">
                pour la formation et l'éducation de notre personnel, où nous
                prenons toutes les mesures raisonnables pour dé-identifier tout
                matériel utilisé.
              </li>
            </ul>
            <p>
              Nous n'utiliserons pas vos informations personnelles à d'autres
              fins sauf si vous avez donné votre consentement, ou si nous sommes
              par ailleurs autorisés ou tenus de le faire par la loi.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Est-ce que nous divulguons vos informations personnelles à
              d'autres?
            </h2>
            <p className="mb-20">
              Nous respectons la confidentialité de vos informations
              personnelles et nous prendrons des mesures raisonnables pour les
              maintenir confidentielles et protégées.
            </p>
            <p className="mb-20">
              Nous ne divulguerons pas vos informations personnelles à des tiers
              sauf si vous avez donné votre consentement, ou si nous sommes par
              ailleurs autorisés ou tenus de le faire par la loi.
            </p>
            <p className="mb-20">
              Nous pouvons divulguer vos informations personnelles à des
              personnes extérieures au MHC, telles que :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                d'autres prestataires de services de santé lorsque cela est
                nécessaire pour votre suivi, votre soutien et/ou d'autres
                options de traitement ;
              </li>
              <li className="list-disc">
                des pharmaciens impliqués dans la fourniture de votre médication
                prescrite (le cas échéant) ; ou
              </li>
              <li className="list-disc">
                votre assureur santé privé ou d'autres assureurs en relation
                avec les services qui vous sont fournis
                <span> en vertu de votre police d'assurance</span>.
              </li>
            </ul>
            <p>
              Nous ne divulguerons vos informations personnelles sans votre
              consentement que si nous sommes autorisés ou tenus de le faire par
              la loi, notamment : lorsque nous croyons raisonnablement que cela
              est nécessaire pour prévenir ou réduire une menace grave pour la
              vie, la santé ou la sécurité de toute personne, ou pour la santé
              ou la sécurité publique ; ou lorsque cela est nécessaire pour
              éviter de porter atteinte à la préservation de la loi par un
              organisme du secteur public ou pour la conduite de procédures
              devant un tribunal (procédures engagées ou raisonnablement
              envisagées).
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Allons-nous transférer vos informations personnelles en dehors du
              Canada?
            </h2>
            <p className="mb-20">
              Nous respectons les exigences de la Loi sur la protection des
              renseignements personnels, de la Loi sur la protection des
              renseignements personnels et les documents électroniques (LPRPDE),
              de la Loi de 2004 sur la protection des renseignements personnels
              sur la santé et de la Loi sur l'accès à l'information lors de la
              divulgation d'informations personnelles en dehors du Canada.
            </p>
            <p className="mb-20">
              Nous ne divulguerons vos informations personnelles en dehors du
              Canada que si :
            </p>
            <p className="mb-20">
              Dans tous les cas, nous prendrons des mesures raisonnables pour
              nous assurer que tout destinataire de vos informations
              personnelles ne viole pas les règles de la Loi sur la protection
              des renseignements personnels et les documents électroniques
              (LPRPDE).
            </p>
            <p className="mb-20">
              Nous pouvons divulguer vos informations personnelles à notre
              personnel de gestion de la performance aux Philippines, afin
              qu'ils puissent vous fournir de l'assistance tout au long de la
              durée de votre traitement, y compris en prenant de vos nouvelles
              chaque mois pour surveiller votre performance et votre bien-être
              général, les résultats étant ensuite transmis à votre médecin du
              MHC. Nous pouvons également divulguer vos informations
              personnelles à des membres de notre équipe marketing situés au
              Royaume-Uni, en Inde et aux Émirats arabes unis, lorsque cela est
              conforme à vos préférences de communication. Ces divulgations ne
              seront faites que dans la mesure minimale nécessaire à ces fins,
              et ces membres du personnel à l'étranger traiteront vos
              informations personnelles conformément aux règles de la Loi sur la
              protection des renseignements personnels, de la Loi sur la
              protection des renseignements personnels et les documents
              électroniques (LPRPDE) et de la Loi de 2004 sur la protection des
              renseignements personnels sur la santé. Vous consentez à ce que
              nous divulguions ces informations personnelles à nos membres du
              personnel à l'étranger à ces fins.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Marketing direct
            </h2>
            <p className="mb-20">
              Si le MHC a l'intention de s'engager dans d'autres communications
              marketing, nous pouvons vous envoyer de telles communications
              conformément à tout consentement précédemment fourni ou à toute
              préférence de communication marketing que vous nous avez notifiée,
              ou comme autrement autorisé en vertu de la
              <span>
                {" "}
                Loi sur la protection des renseignements personnels et la
                Législation canadienne anti-pourriel.
              </span>
            </p>
            <p className="mb-20">
              Si vous avez précédemment accepté de recevoir de telles
              communications marketing, mais ne souhaitez plus les recevoir,
              vous pouvez nous contacter en utilisant les coordonnées ci-dessous
              pour modifier vos préférences, ou vous pouvez simplement vous
              désabonner de telles communications en utilisant les instructions
              ou le lien de désabonnement fourni dans la communication marketing
              qui vous a été envoyée.
            </p>
            <p>
              Nous obtiendrons votre consentement explicite avant de partager
              vos informations personnelles avec un tiers à des fins de
              marketing. Vous pouvez demander à ces tiers de cesser de vous
              envoyer des communications marketing en utilisant les instructions
              ou le lien de désabonnement fourni dans la communication marketing
              qui vous a été envoyée par le tiers.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Révoquer votre consentement
            </h2>
            <p>
              Vous pouvez révoquer votre consentement à tout usage particulier
              ou divulgation de vos informations personnelles à tout moment.
              Vous pouvez nous informer de toute révocation en utilisant nos
              coordonnées ci-dessous, ou en informant le membre du personnel du
              MHC auprès duquel vous recevez des services.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Comment conservons-nous et protégeons-nous vos informations
              personnelles?
            </h2>
            <p className="mb-20">
              Nous protégerons votre vie privée et la sécurité de vos
              informations personnelles en prenant des mesures pour garantir que
              vos informations personnelles soient protégées contre toute
              utilisation abusive, interférence, perte, accès non autorisé,
              modification ou divulgation.
            </p>
            <p className="mb-20">
              Nous utilisons également diverses mesures de sécurité physiques et
              technologiques pour protéger les informations personnelles que
              nous détenons.
            </p>
            <p className="mb-20">
              Nous pouvons détenir vos informations personnelles de plusieurs
              manières, y compris électroniquement et sous format physique.
            </p>
            <p className="mb-20">
              Tous les dossiers seront conservés de manière conforme aux
              exigences de la Loi sur la protection des renseignements
              personnels et de la Loi de 2004 sur la protection des
              renseignements personnels sur la santé, ainsi qu'à la législation
              applicable sur les transactions électroniques.
            </p>
            <p>
              Nous ne conserverons pas vos informations plus longtemps que
              nécessaire aux fins pour lesquelles elles peuvent être utilisées
              ou tel que requis par la loi. Lorsque vos informations
              personnelles ne sont plus nécessaires à conserver en vertu de la
              loi (et dans le cas de vos informations de santé, que les
              informations ont été conservées pour les périodes requises en
              vertu du
              <span>Règlement</span>
              ), nous prendrons des mesures pour détruire les informations de
              manière sécurisée ou pour nous assurer que les informations sont
              dé-identifiées de manière permanente.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Qualité des informations personnelles que nous détenons
            </h2>
            <p>
              Nous prenons des mesures raisonnables pour nous assurer que les
              informations personnelles que nous collectons, utilisons et
              divulguons sont exactes, à jour, complètes et pertinentes pour nos
              fonctions et activités. Vous pouvez nous aider à maintenir
              l'exactitude de vos informations personnelles en nous informant de
              toute mise à jour de vos informations personnelles en utilisant
              nos coordonnées ci-dessous.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Violations de données
            </h2>
            <p>
              Nous sommes tenus de respecter l'obligation obligatoire en vertu
              de la Loi de 2004 sur la protection des renseignements personnels
              sur la santé. Cela s'applique lorsqu'une violation de la vie
              privée 'notifiable' des informations personnelles se produit.
            </p>
            <p className="mb-20">
              Une violation de la vie privée 'notifiable' se produit lorsque :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                il y a un accès non autorisé ou une divulgation non autorisée ou
                une altération d'informations personnelles, ou une perte ou une
                destruction d'informations personnelles, détenues par une
                organisation ; et
              </li>
              <li className="list-disc">
                il est raisonnable de croire qu'elle a causé un préjudice grave
                à une personne ou à plusieurs personnes concernées, ou qu'elle
                est susceptible de le faire.
              </li>
            </ul>
            <p>
              Si nous avons des motifs raisonnables de croire que nous avons
              connu une violation de la vie privée 'notifiable', nous
              notifierons rapidement le Commissariat à la protection de la vie
              privée et les personnes concernées de la violation conformément à
              la Loi de 2004 sur la protection des renseignements personnels sur
              la santé et à la Loi sur la protection de la vie privée.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Pouvez-vous accéder et corriger vos informations personnelles?
            </h2>
            <p className="mb-20">
              Vous pouvez demander à accéder aux informations personnelles que
              nous détenons à votre sujet en utilisant nos coordonnées
              ci-dessous.
            </p>
            <p className="mb-20">
              Dans certaines circonstances, nous pouvons refuser de vous
              permettre d'accéder à vos informations personnelles lorsque cela
              est autorisé par la loi, par exemple lorsque : fournir l'accès
              aurait un impact déraisonnable sur la vie privée d'autres
              personnes ; ou fournir l'accès poserait une menace sérieuse à
              votre vie ou à votre santé ; ou fournir l'accès serait illégal.
            </p>
            <p className="mb-20">
              Nous prenons toutes les mesures raisonnables pour nous assurer que
              les informations personnelles que nous collectons, utilisons et
              divulguons sont exactes, à jour et complètes, pertinentes et non
              trompeuses.
            </p>
            <p className="mb-20">
              Si vous estimez que les informations personnelles que nous
              détenons à votre sujet nécessitent une correction, vous pouvez
              demander que les informations soient corrigées en utilisant nos
              coordonnées ci-dessous.
            </p>
            <p>
              Si nous refusons votre demande d'accès ou de correction, nous vous
              fournirons les raisons du refus par écrit, ainsi que des détails
              sur la manière dont vous pouvez demander un examen de notre
              décision.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Informations dé-identifiées
            </h2>
            <p className="mb-20">
              Les informations dont nous avons supprimé tout élément qui vous
              identifierait raisonnablement, ou toute autre personne («
              <b>informations dé-identifiées</b> »), ne sont pas des
              informations personnelles.
            </p>
            <p className="mb-20">
              Nous pouvons collecter, utiliser et divulguer des informations
              agrégées dé-identifiées telles que des informations statistiques
              ou démographiques à toute fin, comme la gestion, la planification
              ou l'évaluation de nos services. Par exemple, ces informations
              peuvent être utilisées pour calculer le pourcentage d'utilisateurs
              accédant à un service spécifique fourni par le MHC.
            </p>
            <p>
              Cependant, si nous devions jamais combiner ou connecter de telles
              informations dé-identifiées avec vos informations personnelles de
              manière à pouvoir vous identifier directement ou indirectement,
              nous traiterons les informations combinées comme des informations
              personnelles que nous traiterons conformément à cette Politique.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Questions et plaintes liées à la vie privée
            </h2>
            <p className="mb-20">
              Nous respectons votre vie privée et nous prenons toutes les
              plaintes et préoccupations concernant la vie privée très au
              sérieux.
            </p>
            <p className="mb-20">
              Si vous avez des questions sur des problèmes liés à la vie privée
              ou si vous souhaitez porter plainte concernant une violation de
              votre vie privée ou le traitement de vos informations personnelles
              par nos soins, vous pouvez soumettre votre question ou votre
              plainte par écrit au MHC en utilisant les coordonnées ci-dessous.
              Nous vous répondrons dès que possible, mais au plus tard 30 jours
              après réception de votre question ou plainte.
            </p>
            <p>
              Si vous n'êtes pas satisfait de notre réponse, vous pouvez
              contacter :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                Commissariat à la protection de la vie privée du Canada ou
                Commissariat à la protection de la vie privée de l'Ontario.{" "}
                <b>Ou consultez ici https://www.oipc.bc.ca/about/contact-us/</b>
              </li>
            </ul>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Nos coordonnées
            </h2>
            <p className="mb-20">
              Si vous souhaitez nous contacter concernant des questions de
              confidentialité, notamment si :
            </p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                vous souhaitez demander l'accès ou la correction de vos
                informations personnelles ;
              </li>
              <li className="list-disc">
                vous avez une plainte ou une préoccupation concernant votre vie
                privée ; ou
              </li>
              <li className="list-disc">
                vous souhaitez des informations supplémentaires sur cette
                politique,
              </li>
            </ul>
            <p>veuillez nous contacter aux coordonnées suivantes :</p>
            <ul className="mb-20 pl-30 text-16">
              <li className="list-disc">
                Adresse e-mail : <b>info@menshealthclinic.ca</b> (à l'attention
                du responsable de la confidentialité) ; ou
              </li>
              <li className="list-disc">
                Numéro de téléphone : <b>+1 (800) 422 8149</b>
              </li>
            </ul>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"terms"}>
              Mise à jour de cette politique
            </h2>
            <p>
              Nous pouvons réviser cette politique de temps à autre. Nous vous
              informerons de tout changement à cette politique via notre site
              web à l'adresse www.menshealthclinic.com/ca, et nous mettrons la
              version la plus récente de la politique à votre disposition
              lorsque vous recevrez des services de notre part, ou sur demande.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default CAPrivacyPolicy
