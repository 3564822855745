import React from "react"
import { Layout } from "../../../components/layout"
import { returnLink } from "../../../helpers/returnLink"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Treatment } from "../../../components/Treatment/treatment"
import { TextCopies, experts } from "../../../helpers/countryTextCopies"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Experts } from "../../../components/Experts/experts"
import HonestyIcon from "../../../assets/about/honestyIcon.svg"
import QualityIcon from "../../../assets/about/qualityIcon.svg"
import CareIcon from "../../../assets/about/careIcon.svg"
import TeamworkIcon from "../../../assets/about/teamworkIcon.svg"
import ResearchIcon from "../../../assets/about/researchAndDevelopmentIcon.svg"
import { ProcessCardIcon } from "../../../components/ProcessCard/processCardIcon"
import { CustomButton } from "../../../components/common/CustomButton"
import { Video } from "../../../components/Video/video"
import { Faqs } from "../../../components/Faqs/faqs"
import { Schemas } from "../../../schemas/schemas"
import { Reviews } from "../../../components/Reviews/reviews"
import { Articles } from "../../../components/Articles/articles"
import { graphql } from "gatsby"

const TestosteroneReplacementTherapy = ({ data }) => {
  const prefix = "nz"

  const processCards = [
    {
      titleText: "Quality",
      text: `Our treatments have the highest degree of quality in Australia. We use advanced, industry-leading formulations.`,
      img: QualityIcon,
    },
    {
      titleText: "Research & Development",
      text: "We continuously seek ways to enhance our treatment plans and improve the experiences of our patients by leveraging the most advanced technology available.",
      img: ResearchIcon,
    },
    {
      titleText: "Care",
      text: "Our treatments are tailor made to the individual patient. No two people are the same and that’s why treatment is created based on your individual needs.",
      img: CareIcon,
    },
    {
      titleText: "Teamwork",
      text: "Understanding your goals and collaboratively working towards achieving them are fundamental principles for our team.",
      img: TeamworkIcon,
    },
    {
      titleText: "Honesty",
      text: "Ensuring the qualification of our patients for treatment is of utmost importance to us. Your safety is our top priority, and we will always provide honest and transparent advice",
      img: HonestyIcon,
    },
  ]

  const questions = [
    {
      question:
        "Who is a suitable candidate for Testosterone Replacement in NZ?",
      answer:
        "Suitable candidates often include individuals with clinically low testosterone levels and symptoms such as fatigue, low libido, or muscle loss. Consultation with a healthcare provider is essential to determine eligibility.",
    },
    {
      question: "How is Testosterone Replacement administered in NZ?",
      answer:
        "Testosterone Replacement can be administered through various methods, including injections, gels, patches, or implants. The choice of method depends on individual needs and preferences, and it is determined by a healthcare professional.",
    },
    {
      question:
        "Are there any potential side effects of Testosterone Replacement in NZ?",
      answer:
        "While generally safe, potential side effects may include acne, mood swings, or changes in blood pressure. Monitoring by a healthcare provider helps minimise risks, and any side effects should be promptly reported.",
    },
    {
      question:
        "Can Testosterone Replacement therapy enhance muscle and athletic performance in NZ?",
      answer:
        "Testosterone Replacement is not intended for athletic enhancement. Its purpose is to restore normal testosterone levels in individuals with medical needs, such as low testosterone-related symptoms.",
    },
    {
      question:
        "How long does it take to experience the benefits of Testosterone Replacement in NZ?",
      answer:
        "The time to notice benefits varies, but some individuals may experience improved energy and mood within a few weeks. Full effects may take several months, and the treatment plan is often tailored to individual needs.",
    },
    {
      question:
        "Is Testosterone Replacement in NZ covered by health insurance or government programs?",
      answer:
        "Coverage may vary depending on the specific insurance plan or government programs available in NZ. It's advisable to check with your healthcare provider and insurance provider for details on coverage and reimbursement options.",
    },
  ]

  const articles = data.allPrismicBothArticle.nodes
    .filter(article => article.lang === `en-${prefix === "uk" ? "gb" : prefix}`)
    .filter(article => article.tags.includes("Sexual Dysfunction"))
    .slice(0, 2)

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "trtnzmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "trtnzbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Boost Vitality with Testosterone Replacement in NZ"
        description="Discover Comprehensive and Effective Testosterone Replacement in New Zealand. Regain Vitality and Well-being. Expert Guidance for Men's Health. Contact us now!"
      />
      <JsonLd json={Schemas.localBusinessSchemaTRTNZ(prefix)} />
      <JsonLd json={Schemas.serviceSchemaTRTNZ(prefix)} />
      <section className="treatments__inner--container mb-60 flex flex-wrap justify-between pt-40 max1360:justify-center">
        <div className="mb-60 w-full 1330:mr-15 max480:px-25 max680:mb-85">
          <h1 className="section__title mb-20">
            Enhance Your Vitality with Testosterone Replacement in NZ
          </h1>
        </div>
      </section>
      <section className="width__container mb-145 max680:mb-60">
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              What is Testosterone Replacement Therapy (TRT)?
            </h2>
            <p className="mb-20 whitespace-pre-line">
              Testosterone Replacement Therapy, commonly known as TRT, is a
              medical procedure aimed at restoring optimal testosterone levels
              in individuals experiencing testosterone deficiency.{" "}
              <a
                href="https://menshealthclinic.com/nz/treatments/testosterone-replacement-therapy/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Testosterone
              </a>
              , a vital hormone predominantly produced in the testicles, plays a
              fundamental role in various bodily functions, including muscle
              mass maintenance, bone density regulation, and sperm production.
              However, factors such as ageing, certain medical conditions, or
              lifestyle choices can lead to decreased testosterone levels,
              resulting in symptoms such as fatigue, reduced libido, and mood
              changes.
            </p>
            <p className="mb-20 whitespace-pre-line">
              TRT involves the administration of testosterone through a variety
              of methods, including injections, patches, gels, or implants
              tailored to suit individual needs. This therapy seeks to reduce
              symptoms associated with low testosterone by replenishing
              testosterone levels to normal ranges while also improving general
              well-being and quality of life.
            </p>
          </div>
        </div>
        <div className="mb-75 flex flex-row-reverse flex-wrap items-center justify-between max680:mb-45 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Signs and Symptoms of Low Testosterone
            </h2>
            <p className="mb-20 whitespace-pre-line">
              Recognising the signs and symptoms of low testosterone is crucial
              for individuals to address potential health concerns and seek
              appropriate treatment. Common indicators encompass various aspects
              of physical and mental well-being. These may include persistent
              fatigue, where individuals experience a constant lack of energy
              despite adequate rest, limiting their capacity to participate in
              daily activities effectively. Additionally, decreased libido and
              erectile dysfunction are prevalent symptoms that can affect
              intimate relationships and overall quality of life.
            </p>
            <p className="mb-20 whitespace-pre-line">
              Furthermore, individuals with low testosterone levels often notice
              changes in their body composition, such as an increase in body fat
              and a decrease in muscular mass, which can affect their confidence
              and self-image. Emotional symptoms, including mood swings,
              impatience, and difficulties concentrating, may also appear in
              addition to these physical manifestations, impacting personal and
              professional life. Individuals can address low testosterone levels
              and enhance their general health and well-being by proactively
              detecting these early indications and symptoms.
            </p>
            <p className="mb-20 whitespace-pre-line">
              At{" "}
              <a
                href="https://menshealthclinic.com/nz/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Men's Health Clinic
              </a>
              , we understand the impact low testosterone levels can have on
              daily life. Our comprehensive approach involves thorough
              evaluation and assessment to identify symptoms and determine the
              most suitable testosterone treatment options for each individual.
            </p>
          </div>
        </div>
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Benefits of Our Testosterone Replacement Therapy in NZ
            </h2>
            <p className="mb-20 whitespace-pre-line">
              Experience the numerous benefits of testosterone replacement
              therapy in NZ. Our TRT programme is designed to address each
              client's unique needs, providing personalised treatment plans
              tailored to optimise testosterone levels and improve overall
              well-being. From increased energy levels and enhanced libido to
              improved muscle mass and mood stability, our treatment offers a
              range of benefits for men looking to reclaim their vitality.
            </p>
            <p className="mb-20 whitespace-pre-line">
              Moreover, our comprehensive care extends beyond symptom
              alleviation. We provide ongoing support and guidance to ensure our
              clients achieve long-lasting results and enjoy a higher quality of
              life. With our expertise and personalised approach to{" "}
              <a
                href="https://en.wikipedia.org/wiki/Androgen_replacement_therapy"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                testosterone replacement
              </a>
              , we empower individuals to reclaim their vitality and thrive in
              every aspect of their lives.
            </p>
            <p className="mb-20 whitespace-pre-line">
              At Men's Health Clinic, we prioritise your comfort,
              confidentiality, and satisfaction. We take pride in helping you
              regain control of your health and vitality, empowering you to lead
              a fulfilling and active lifestyle. Contact us today to learn more
              about how our testosterone replacement in NZ can make a positive
              difference in your life.
            </p>
          </div>
        </div>
      </section>
      <Treatment
        isBtn={true}
        blueText={"Our Treatments"}
        titleText={"View our range of bespoke treatment plans."}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmenttreatmentquiz",
            false,
            prefix
          )
        }
        prefix={prefix}
      />
      <OurProcess prefix={prefix} />
      <Reviews link={
        returnLink(
          "Website-Quiz",
          "Supplementary-Quiz",
          "treatmentreviewsquiz",
          false,
          prefix
        )
      } prefix={prefix} />
      <Experts
        experts={experts}
        slider={true}
        className="flex-wrap"
        prefix={prefix}
      />
      <section className="width__container mb-125 max680:mb-60">
        <div className="flex flex-wrap justify-between max1360:justify-center">
          <div className="about__process--title-box">
            <h2 className="dashed__text--blue treatments__blue--text blueText">
              Our Principles / Values
            </h2>
            <h2 className="section__title company__title">
              Our values align with yours.
            </h2>
          </div>
          {processCards.map((item, index) => (
            <ProcessCardIcon
              Component={item.img}
              titleText={item.titleText}
              text={item.text}
              key={index}
              className={index % 2 === 0 ? "1000:ml-5" : "1000:mr-5"}
            />
          ))}
        </div>

        <CustomButton
          className="orangeFilled slim__btn ml-[50%] mt-40 translate-x-[-50%] max680:mt-0"
          text="Online Assessment"
          link={
            returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "aboutvaluesquiz",
              false,
              prefix
            )
          }
        />
      </section>
      <Video blueDashedText={"Meet Dr. Fred Edwards"} prefix={prefix} />
      <Faqs
        blueText="FAQS"
        titleText="Frequently Asked Questions"
        isButton={true}
        bottomText={true}
        className="mb-100"
        prefix={prefix}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "trtfaqsquiz",
            false,
            prefix
          )
        }
        questions={questions}
      />
      <Articles
        blueText="Articles"
        titleText="Learn More About Testosterone Replacement"
        slider={true}
        buttonClass="bottom-0"
        className="mb-145 pb-75"
        navLink={`/${prefix}/resources/#resourcesArticles`}
        articles={articles}
        prefix={prefix}
      />
    </Layout>
  )
}
export default TestosteroneReplacementTherapy

export const pageQuery = graphql`
  query NzTrtQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`