import * as React from "react"
import { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { ArticleCard } from "../../../components/Articles/articleCard"
import { ImageTextEllipse } from "../../../components/common/ImageTextEllipse"
import { returnLink } from "../../../helpers/returnLink"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import ArrowLeft from "../../../assets/common/sliderOrangeArrowLeft.svg"
import ArrowRight from "../../../assets/common/sliderOrangeArrowRight.svg"

const blueBg = "bg-030654 text-white"
const whiteBg = "bg-white text-333333"
const articlesInitialCount = 6
const metaTitle =
  "Informations sur la dysfonction érectile et l'éjaculation précoce"
const metaDescription =
  "Informations sur la dysfonction érectile et l'éjaculation précoce"

const Resources = ({ pageContext: { data, prefix, skip, page }, location }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: metaTitle,
    description: metaDescription,
    url: `https://menshealthclinic.com/${prefix}/resources`,
  }
  // WEBPAGE SCHEMA END //

  const [activeCategory, setActiveCategory] = useState("all")
  const [allArticles, setAllArticles] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([])
  const [articles, setArticles] = useState([])
  const articlesCount = articlesInitialCount + skip
  const [numberOfPages, setNumberOfPages] = useState(0)

  useEffect(() => {
    setNumberOfPages(
      activeCategory === "all"
        ? Math.ceil(allArticles.length / articlesInitialCount)
        : Math.ceil(filteredArticles.length / articlesInitialCount)
    )
  }, [activeCategory, allArticles, filteredArticles])

  useEffect(() => {
    if (activeCategory === "all") {
      setArticles(
        allArticles
          .filter(article => article.uid !== allArticles[0].uid)
          .slice(articlesCount - articlesInitialCount, articlesCount)
      )
      return
    }
    setArticles(
      filteredArticles
        .filter(article => article.uid !== allArticles[0].uid)
        .slice(articlesCount - articlesInitialCount, articlesCount)
    )
  }, [activeCategory, articlesCount, allArticles, filteredArticles])

  useEffect(() => {
    setActiveCategory(location?.state?.activeCategory ?? "all")
  }, [location?.state?.activeCategory])

  useEffect(() => {
    setAllArticles(
      [
        ...data.allPrismicBothArticle.nodes,
        ...data.allPrismicWebsiteArticle.nodes,
      ]
        .filter(article => article.lang === `fr-ca`)
        .sort(
          (a, b) =>
            new Date(b.first_publication_date) -
            new Date(a.first_publication_date)
        )
    )
  }, [data])

  useEffect(() => {
    setFilteredArticles(
      allArticles.filter(item => item.tags.includes(activeCategory))
    )
  }, [activeCategory, allArticles])

  const translateTags = tags => {
    const translatedTags = tags?.map(tag => {
      switch (tag) {
        case "Erectile Dysfunction":
          return "Dysfonction érectile"
        case "Premature Ejaculation":
          return "Éjaculation précoce"
        case "Chronic Pain":
          return "Santé et bien-être"
        case "Sexual Dysfunction":
          return "Dysfonction sexuelle"
        case "Health and Wellness":
          return "La santé et le bien-être"
      }
    })

    return translatedTags
  }

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "resourcemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "resourcebottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="MHC Men's Health Clinic | Leaders dans le traitement de la dysfonction érectile et de l'éjaculation précoce"
        description={metaDescription}
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/resources/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/resources/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/resources/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/resources/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/resources/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/resources/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/resources/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="left-blue-semicircle relative mb-[180px] h-420 bg-030654 pt-85 text-center 440:mb-[180px] 600:mb-[150px] 900:mb-[280px]">
        <HeaderRightSvg className="absolute bottom-0 right-0" />
        <h1 className="header__image--title inline-block pb-20">
          Articles et ressources
        </h1>
        <Link
          to={`/${prefix}/resource/${allArticles[0]?.uid}`}
          state={{
            img: allArticles[0]?.data.featured_image.url,
            text: allArticles[0]?.data.text,
            title: allArticles[0]?.data.title.text,
            articles: articles,
            imgText: allArticles[0]?.tags,
            date: allArticles[0]?.data.date,
          }}
          rel="follow"
          className="relative flex cursor-pointer justify-center max900:hidden"
        >
          <div className="width__container flex justify-end max1300:justify-center">
            <div
              className="relative h-500 w-full max-w-800 rounded-20"
              style={{
                backgroundImage: allArticles[0]?.data.featured_image.url
                  ? `url(${allArticles[0].data.featured_image.url})`
                  : "",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="centered absolute left-min300 top-120 z-20 max-w-[80%] rounded-20 bg-F5F5F5 p-40 shadow 730:top-250 1400:top-120 max730:p-25">
                <h2 className="mb-20 text-left text-4xl font-bold text-333333 max730:text-xl">
                  {allArticles[0]?.data.title.text}
                </h2>
                <p className="mb-20 text-left">
                  {allArticles[0]?.data.text.richText[0].text.substr(0, 199)}
                  ...
                </p>
              </div>
              <ImageTextEllipse
                textArr={translateTags(allArticles[0]?.tags)}
                className="imageTextEllipse imageTextEllipseWhiteBg absolute right-25 top-25 z-20"
              />
            </div>
          </div>
        </Link>

        {/* same block as above but without container MOBILE*/}
        <Link
          to={`/${prefix}/resource/${allArticles[0]?.uid}`}
          state={{
            img: allArticles[0]?.data.featured_image.url,
            text: allArticles[0]?.data.text,
            title: allArticles[0]?.data.title.text,
            articles: articles,
            imgText: allArticles[0]?.tags,
            date: allArticles[0]?.data.date,
          }}
          rel="follow"
          className="relative hidden cursor-pointer justify-center 1000:px-100 max900:flex"
        >
          <div
            className="h-316 w-full max-w-800 rounded-20 max480:h-250"
            style={{
              backgroundImage: allArticles[0]?.data.featured_image.url
                ? `url(${allArticles[0].data.featured_image.url})`
                : "",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="translate__left-half absolute top-100 z-20 w-[90%] rounded-20 bg-F5F5F5 p-25 shadow 440:top-150 440:w-[80%]">
              <h2 className="mb-20 text-left text-4xl font-bold text-333333 max730:text-xl">
                {articles[0]?.data.title.text}
              </h2>
              <p className="mb-20 text-left">
                {articles[0]?.data.text.richText[0].text.substr(0, 99)}
                ...
              </p>
            </div>
            <ImageTextEllipse
              textArr={translateTags(allArticles[0]?.tags)}
              className="imageTextEllipse imageTextEllipseWhiteBg absolute right-[6%] top-25 z-20"
            />
          </div>
        </Link>
        {/* end */}
      </article>

      <main>
        <section
          className="width__container mb-180 border-t pt-85 max680:mb-145 max730:pt-0"
          id={"resourcesArticles"}
        >
          <div className="mb-30 flex items-center justify-between max1024:block">
            <h2 className="section__title max1024:mb-10">Derniers articles</h2>
            <div className="flex items-center justify-between gap-2 rounded-42 bg-E0E0E0 px-4 py-2 max1024:space-x-5 max1024:overflow-x-scroll">
              <span
                onClick={() => {
                  if (activeCategory === "all") return
                  navigate(`/${prefix}/resources`, {
                    state: {
                      activeCategory: "all",
                      disableScrollUpdate: true,
                    },
                  })
                }}
                className={`inline-block cursor-pointer rounded-42 px-6 py-3 font-bold ${
                  activeCategory === "all" ? blueBg : whiteBg
                }`}
              >
                Tous
              </span>
              <ImageTextEllipse
                onClick={() => {
                  if (activeCategory === "Erectile Dysfunction") return
                  navigate(`/${prefix}/resources`, {
                    state: {
                      activeCategory: "Erectile Dysfunction",
                      disableScrollUpdate: true,
                    },
                  })
                }}
                text="Dysfonction érectile"
                className={`imageTextEllipse flex cursor-pointer items-center justify-center border border-solid border-BDBDBD text-14 max1024:w-min ${
                  activeCategory === "Erectile Dysfunction" ? blueBg : whiteBg
                }`}
              />
              <ImageTextEllipse
                onClick={() => {
                  if (activeCategory === "Premature Ejaculation") return
                  navigate(`/${prefix}/resources`, {
                    state: {
                      activeCategory: "Premature Ejaculation",
                      disableScrollUpdate: true,
                    },
                  })
                }}
                text="Éjaculation précoce"
                className={`imageTextEllipse flex cursor-pointer items-center justify-center border border-solid border-BDBDBD text-14 max1024:w-min ${
                  activeCategory === "Premature Ejaculation" ? blueBg : whiteBg
                }`}
              />
              <ImageTextEllipse
                onClick={() => {
                  if (activeCategory === "Sexual Dysfunction") return
                  navigate(`/${prefix}/resources`, {
                    state: {
                      activeCategory: "Sexual Dysfunction",
                      disableScrollUpdate: true,
                    },
                  })
                }}
                text="Dysfonction sexuelle"
                className={`imageTextEllipse flex cursor-pointer items-center justify-center border border-solid border-BDBDBD text-14 max1024:w-min ${
                  activeCategory === "Sexual Dysfunction" ? blueBg : whiteBg
                }`}
              />
              <ImageTextEllipse
                onClick={() => {
                  if (activeCategory === "Health and Wellness") return
                  navigate(`/${prefix}/resources`, {
                    state: {
                      activeCategory: "Health and Wellness",
                      disableScrollUpdate: true,
                    },
                  })
                }}
                text="Santé et bien-être"
                className={`imageTextEllipse flex cursor-pointer items-center justify-center border border-solid border-BDBDBD text-14 max1024:w-min ${
                  activeCategory === "Health and Wellness" ? blueBg : whiteBg
                }`}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between gap-40 max1400:justify-center max1400:gap-100">
            {articles.map((item, index) => {
              return (
                <ArticleCard
                  key={index}
                  img={item.data.featured_image.url}
                  imgText={translateTags(item.tags)}
                  title={item.data.title.text}
                  text={item.data.text}
                  date={item.data.date}
                  uid={item.uid}
                  index={index}
                  articles={articles}
                  prefix={prefix}
                />
              )
            })}
          </div>

          <div className="mt-80 flex justify-center border-t-[2px] border-solid border-slate-200">
            {articlesCount <= articlesInitialCount ? (
              <div className="mt-[7.5px] flex cursor-not-allowed items-center">
                <div className="disabledResourcesArrow flex h-40 w-40 items-center justify-center">
                  <span>
                    <ArrowLeft className="arrowSvg" />
                  </span>
                </div>
                <p className="ml-5 text-slate-400">Précédent</p>
              </div>
            ) : (
              <Link
                to={
                  page - 1 === 1
                    ? `/${prefix}/resources`
                    : `/${prefix}/resources/page-${page - 1}`
                }
                state={{ activeCategory }}
                rel="follow"
                className="mt-[7.5px] flex items-center "
              >
                <div className="resourcesArrow flex h-40 w-40 items-center justify-center">
                  <span>
                    <ArrowLeft className="arrowSvg" />
                  </span>
                </div>
                <p className="ml-5 text-slate-700">Précédent</p>
              </Link>
            )}
            <div className="mx-auto flex pr-[3%]">
              <Link
                to={`/${prefix}/resources`}
                state={{ activeCategory }}
                rel="follow"
                className={`relative w-40 cursor-pointer pt-15 text-center ${
                  page === 1
                    ? "font-semibold text-030654 before:absolute before:left-0 before:top-[-2px] before:h-[2px] before:w-[100%] before:bg-030654"
                    : "text-slate-700"
                }`}
              >
                1
              </Link>
              {page > 3 && numberOfPages > 4 && (
                <div className="relative w-40 cursor-pointer pt-15 text-center text-slate-700">
                  ...
                </div>
              )}
              {[
                page - 3,
                page - 2,
                page - 1,
                page,
                page + 1,
                page + 2,
                page + 3,
              ].map((pageNumber, idx) => {
                // Page out of range
                if (pageNumber <= 1 || pageNumber >= numberOfPages) return
                // Only first page should load page+3 link, and only last page should load page-3 link
                if (
                  page > 1 &&
                  page < numberOfPages &&
                  (idx === 0 || idx === 6)
                )
                  return
                // Pages in between second to first and second to last should only load one page on each side
                if (
                  page > 2 &&
                  page < numberOfPages - 1 &&
                  (idx <= 1 || idx >= 5) &&
                  page !== numberOfPages
                )
                  return
                return (
                  <Link
                    key={idx}
                    rel="follow"
                    to={`/${prefix}/resources/page-${pageNumber}`}
                    state={{ activeCategory }}
                    className={`relative w-40 cursor-pointer pt-15 text-center ${
                      pageNumber === page
                        ? "font-semibold text-030654 before:absolute before:left-0 before:top-[-2px] before:h-[2px] before:w-[100%] before:bg-030654"
                        : "text-slate-700"
                    }`}
                  >
                    {pageNumber}
                  </Link>
                )
              })}
              {numberOfPages > 1 && (
                <>
                  {page < numberOfPages - 2 && numberOfPages > 4 && (
                    <div className="relative w-40 cursor-pointer pt-15 text-center text-slate-700">
                      ...
                    </div>
                  )}
                  <Link
                    to={`/${prefix}/resources/page-${numberOfPages}`}
                    state={{ activeCategory }}
                    rel="follow"
                    className={`relative w-40 cursor-pointer pt-15 text-center ${
                      page === numberOfPages
                        ? "font-semibold text-030654 before:absolute before:left-0 before:top-[-2px] before:h-[2px] before:w-[100%] before:bg-030654"
                        : "text-slate-700"
                    }`}
                  >
                    {numberOfPages}
                  </Link>
                </>
              )}
            </div>
            {(activeCategory === "all" &&
              allArticles.length <= articlesCount) ||
            (activeCategory !== "all" &&
              filteredArticles.length <= articlesCount) ? (
              <div className="mt-[7.5px] flex cursor-not-allowed items-center">
                <p className="mr-5 text-slate-400">Suivant</p>
                <div className="disabledResourcesArrow flex h-40 w-40 items-center justify-center">
                  <span>
                    <ArrowRight className="arrowSvg" />
                  </span>
                </div>
              </div>
            ) : (
              <Link
                to={`/${prefix}/resources/page-${page + 1}`}
                state={{ activeCategory }}
                rel="follow"
                className="mt-[7.5px] flex items-center"
              >
                <p className="mr-5 text-slate-700">Suivant</p>
                <div className="resourcesArrow flex h-40 w-40 items-center justify-center">
                  <span>
                    <ArrowRight className="arrowSvg" />
                  </span>
                </div>
              </Link>
            )}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Resources
