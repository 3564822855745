import React from "react"
import About from "../../components/PageComponents/about"

const AboutPage = () => {
  const prefix = "ca"

  return <About prefix={prefix} />
}

export default AboutPage
