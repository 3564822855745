import React from "react"
import Treatments from "../../../components/PageComponents/fr-ca/treatments"

const Index = () => {
  const prefix = "fr-ca"

  return <Treatments prefix={prefix} />
}

export default Index
