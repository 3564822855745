import React from "react"
import Author from "../../../../components/PageComponents/author"
import DrThomasStock from "../../../../images/experts/DrStock.webp"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const DrThomasStockAuthorPage = () => {
  const prefix = "nz"
  const authorInfo = {
    name: "Dr. Thomas Stock",
    title: "MBChB (Hon) MSc.",
    bio: "Dr. Stock is a qualified GP who recognises the effect ED and PE can have on the male psyche. He brings both his knowledge and expertise to MHC providing patient centred care.",
    img: DrThomasStock,
    filterText: "Dr. Thomas Stock, MBChB (Hon) MSc."
  }
  const data = useStaticQuery(graphql`
    query DrStockNZQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Author prefix={prefix} data={data} authorInfo={authorInfo} />
}



DrThomasStockAuthorPage.propTypes = {
  data: PropTypes.object
}

export default DrThomasStockAuthorPage
