import React from "react"
import ContactUs from "../../components/PageComponents/fr-ca/contact-us"

const ContactUsPage = () => {
  const prefix = "fr-ca"

  return <ContactUs prefix={prefix} />
}

export default ContactUsPage
