import * as React from "react"
import { useEffect, useState } from "react"
import { Layout } from "../layout"
import { Link, navigate } from "gatsby"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Articles } from "../Articles/articles"
import { RichText } from "prismic-reactjs"
import { HeaderMarks } from "../common/HeaderMarks"
import { TextCopies } from "../../helpers/countryTextCopies"
import { returnLink } from "../../helpers/returnLink"
import HeaderRightSvg from "../../assets/header/headerRight.svg"
import PropTypes from "prop-types"

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case "paragraph":
      return (
        <p key={key} className="pb-25">
          {children}
        </p>
      )
    case "heading2":
      return (
        <p key={key} className="text-20_36 font-medium">
          {children}
        </p>
      )
    case "strong":
      return (
        <span key={key} className="font-medium">
          {children}
        </span>
      )
    case "list-item":
      return (
        <li key={key} className="cms__list--type ml-50 list-disc">
          {children}
        </li>
      )
    case "o-list-item":
      return (
        <li key={key} className="cms__list--type ml-50 list-decimal">
          {children}
        </li>
      )
    case "group-o-list-item":
      return (
        <ul key={key} className="mb-25">
          {children}
        </ul>
      )
    case "embed":
      return (
        <div className="resource__iframe--wrapper">
          <iframe
            className="resource__iframe"
            src={element.oembed.embed_url}
            key={key}
          ></iframe>
        </div>
      )
    case "img":
      return <img className="mb-25" src={element.url} alt="Men's Health Clinic resource article image" />
    case "hyperlink":
      return (
        <a
          key={key}
          href={element.data.url}
          className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
        >
          {children}
        </a>
      )
    default:
      return null
  }
}

const ResourcesInner = ({ pageContext: { uid, prefix, data } }) => {
  const [relatedArticles, setRelatedArticles] = useState([])

  let allArticles = [
    ...data.allPrismicBothArticle.nodes,
    ...data.allPrismicWebsiteArticle.nodes,
  ]

  const checkRegionLocale = (lang, prefix) => {
    switch (prefix) {
      case "au":
        return lang === `en-au`
      case "ca":
        return lang === `en-ca`
      case "nz":
        return lang === `en-nz`
      case "uk":
        return lang === `en-gb`
    }
  }

  let article = allArticles.filter(
    data => data.uid == uid && checkRegionLocale(data.lang, prefix)
  )[0]

  const isBrowser = typeof window !== "undefined"

  if (isBrowser && !article) {
    navigate(`/${prefix}/resources`)
  }

  const { img, text, title, alt, description } = {
    img: article?.data.featured_image.url,
    text: article?.data.text,
    title: article?.data.title.text,
    articles: article,
    imgText: article?.tags,
    date: article?.data.date,
    alt: article?.data.featured_image.alt,
    description: article?.data?.description?.richText[0]?.text,
  }

  const findRelatedArticles = currentArticle => {
    const resourceArticles = []
    const resourceArticlesUid = []

    // Filter out current article
    const filteredArticles = allArticles.filter(
      filteredArticle => currentArticle.uid !== filteredArticle.uid
    )

    // Find 2 separate articles
    for (let i = 0; resourceArticles.length < 2; i++) {
      const articleToAdd = filteredArticles[i]

      if (!resourceArticlesUid.includes(articleToAdd.uid)) {
        resourceArticlesUid.push(articleToAdd.uid)
        resourceArticles.push(articleToAdd)
      }

      i++
    }

    setRelatedArticles(resourceArticles)
  }

  useEffect(() => {
    if (allArticles.length > 0) findRelatedArticles(article)
  }, [])

  useEffect(() => {
    if (!uid) {
      navigate(`/${prefix}/resources`)
    }
  }, [])

  const articleBody = text?.richText.map(item => item.text).join(" ")

  // BLOGPOSTING SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://menshealthclinic.com/${prefix}/resource/${uid}`,
    },
    name: `${title}`,
    description: `${description}`,
    headline: `${title}`,
    articleBody: `${articleBody}`,
    image: {
      "@type": "ImageObject",
      url: `${img}`,
    },
    url: `https://menshealthclinic.com/${prefix}/resource/${uid}`,
  }
  // BLOGPOSTING SCHEMA END //

  const returnAuthorLink = (byliner) => {
    switch (byliner.trim()) {
      case "Dr. Fred Edwards, MBBS, FRACGP":
        return "dr-fred-edwards"
      case "Dr. Suren Naidoo, MRCGP, MBBCh":
        return "dr-suren-naidoo"
      case "Dr. Thomas Stock, MBChB (Hon) MSc.":
        return "dr-thomas-stock"
    }
  }

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "blogmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "blogbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title={title}
        description={description}
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: `https://menshealthclinic.com/au/resource/${uid}/`,
          },
          {
            hrefLang: "en-au",
            href: `https://menshealthclinic.com/au/resource/${uid}/`,
          },
          {
            hrefLang: "en-ca",
            href: `https://menshealthclinic.com/ca/resource/${uid}/`,
          },
          {
            hrefLang: "en-nz",
            href: `https://menshealthclinic.com/nz/resource/${uid}/`,
          },
          {
            hrefLang: "en-ph",
            href: `https://menshealthclinic.com/ph/resource/${uid}/`,
          },
          {
            hrefLang: "en-gb",
            href: `https://menshealthclinic.com/gb/resource/${uid}/`,
          },
        ]}
        openGraph={{
          type: "article",
          url: `https://menshealthclinic.com/${prefix}/resource/${uid}`,
          title: title,
          description: description,
          images: [
            {
              url: img,
              width: 700,
              height: 450,
              alt: "",
            },
          ],
        }}
      />
      <JsonLd json={schema} />
      <article className="relative text-center mb-[90px] 600:mb-150 900:mb-250">
        <div className="left-blue-semicircle h-420 bg-030654 pt-55">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <div className="width__container">
            <h1 className="header__image--title-full inline-block px-30">
              {title}
            </h1>
            <div className="relative">
              <img
                className="mx-auto w-full max-w-700 rounded-20"
                src={img}
                alt={alt}
              />
            </div>
          </div>
        </div>
      </article>
      <section className="width__container">
        <div className="mb-40 text-center">
          <h2 className="section__title mb-20">{title}</h2>
          {article?.data?.byliner?.richText[0]?.text && (
            <div className="flex justify-center space-x-1">
              <p>by</p>
              <Link to={`/${prefix}/resources/author/${returnAuthorLink(article?.data?.byliner?.richText[0]?.text)}`}>
                <RichText
                  render={article?.data?.byliner?.richText}
                  htmlSerializer={htmlSerializer}
                />
              </Link>
            </div>
          )}
          <hr className="my-2" />
        </div>
        <RichText render={text?.richText} htmlSerializer={htmlSerializer} />
      </section>

      <section className="width__container relative mb-145 overflow-hidden max680:mb-60 max1150:h-full">
        <div className="absolute left-0 top-0 w-6/12 max1150:hidden"></div>
        <div className="flex max1150:flex-wrap max1150:justify-center max1150:pt-0">
          <div className="relative ml-100 mt-100 max-w-524 pr-35 max600:px-25 max1150:ml-0 max1150:mt-0 max1150:pr-0">
            <h2 className="mb-25 text-52 font-bold text-333333 max600:text-28_45">
              Schedule a FREE appointment today
            </h2>
            <p className="mb-85 max1150:mb-40">
              With one of our Senior Patient Coordinators who can provide you
              with more information regarding bespoke treatment options.
            </p>
            <HeaderMarks
              className="block text-4F4F4F max1150:hidden"
              firstText={`Licenced ${TextCopies.countryAsAdjective(prefix)} doctors.`}
            />
          </div>
          <div className="max600:pb-160 relative h-full w-full max-w-600 1150:mr-25 max600:rounded-t-20 max1150:ml-0">
            <iframe
              src={TextCopies.acuityLink(prefix)}
              style={{ height: "700px", width: "100%" }}
              title="appointment"
            ></iframe>
          </div>
        </div>
      </section>
      <Articles
        blueText="Articles"
        titleText="Related Articles"
        className="mb-165 pb-80 max600:mb-125 max680:pb-65"
        buttonClass="max800:bottom-0"
        articles={relatedArticles}
        prefix={prefix}
      />
    </Layout>
  )
}

export default ResourcesInner

ResourcesInner.propTypes = {
  pageContext: {
    data: PropTypes.object,
    params: PropTypes.object,
    prefix: PropTypes.string,
  },
}
