import React from "react"
import Author from "../../../../components/PageComponents/author"
import DrFredEdwards from "../../../../images/experts/DrFred.webp"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const DrFredEdwardsAuthorPage = () => {
  const prefix = "nz"
  const authorInfo = {
    name: "Dr. Fred Edwards",
    title: "MBBS, FRACGP",
    bio: "Dr. Fred Edwards is a qualified GP and has over 30+ years’ experience. He aims to widen the scope of treatment within MHC to include general health issues to help men.",
    img: DrFredEdwards,
    filterText: "Dr. Fred Edwards, MBBS, FRACGP"
  }
  const data = useStaticQuery(graphql`
    query DrEdwardsNZQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Author prefix={prefix} data={data} authorInfo={authorInfo} />
}



DrFredEdwardsAuthorPage.propTypes = {
  data: PropTypes.object
}

export default DrFredEdwardsAuthorPage
