import * as React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ExternalRedirect from "../../components/PageComponents/externalRedirect"

const EDInfoPackAU = () => {
  return (
    <>
      <GatsbySeo
        title="Men's Health Clinic | Download ED Treatment Information"
        description="Men's Health Clinic helps treat erectile dysfunction in Australia. Download our Information Pack ED treatment now."
      />
      <ExternalRedirect link="https://docs.google.com/gview?url=https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/ED+Info+Pack+-+AU.pdf" />
    </>
  )
}

export default EDInfoPackAU
