import * as React from "react"
import { Layout } from "../layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { AboutStatistics } from "../AboutStatistics/aboutStatistics"
import { AboutCompany } from "../AboutCompany/AboutCompany"
import { ProcessCardIcon } from "../ProcessCard/processCardIcon"
import { Experts } from "../Experts/experts"
import { OurProcess } from "../../components/OurProcess/ourProcess"
import { Video } from "../Video/video"
import { Reviews } from "../Reviews/reviews"
import { TextCopies, experts } from "../../helpers/countryTextCopies"
import { returnLink } from "../../helpers/returnLink"
import { CustomButton } from "../common/CustomButton"
import AboutHeaderImg from "../../images/about/aboutHeaderImg.webp"
import CircleLeft from "../../assets/about/aboutCircleLeft.svg"
import CircleRight from "../../images/about/aboutCircleRight.webp"
import HonestyIcon from "../../assets/about/honestyIcon.svg"
import QualityIcon from "../../assets/about/qualityIcon.svg"
import CareIcon from "../../assets/about/careIcon.svg"
import TeamworkIcon from "../../assets/about/teamworkIcon.svg"
import ResearchIcon from "../../assets/about/researchAndDevelopmentIcon.svg"
import PropTypes from "prop-types"
import "../../styles/about.scss"
import { Schemas } from "../../schemas/schemas"

const About = ({ prefix, treatmentType }) => {
  // ABOUTPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    name: "The authentic Men’s Health Clinic everyone knows and trusts, globally.",
    description:
      "Established in 2012, our journey revolutionised Men’s Health in Australia. Men’s Health Clinic was established locally in Sydney, Australia with a handful of team members. We were one of the first clinics in Australia to openly advocate for men’s sexual health and challenged the associated stigmas related to men seeking help. Since then, our treatments and services have expanded globally. Allowing us to integrate and adapt new technology to provide optimal convenience for all of our patients.",
    url: `https://menshealthclinic.com/${prefix}/about`,
  }
  // ABOUTPAGE SCHEMA END //

  const processCards = [
    {
      titleText: "Quality",
      text: `Our treatments have the highest degree of quality in ${TextCopies.country(
        prefix
      )}. We use advanced, industry-leading formulations.`,
      img: QualityIcon,
    },
    {
      titleText: "Research & Development",
      text: "We continuously seek ways to enhance our treatment plans and improve the experiences of our patients by leveraging the most advanced technology available.",
      img: ResearchIcon,
    },
    {
      titleText: "Care",
      text: "Our treatments are tailor made to the individual patient. No two people are the same and that’s why treatment is created based on your individual needs.",
      img: CareIcon,
    },
    {
      titleText: "Teamwork",
      text: "Understanding your goals and collaboratively working towards achieving them are fundamental principles for our team.",
      img: TeamworkIcon,
    },
    {
      titleText: "Honesty",
      text: "Ensuring the qualification of our patients for treatment is of utmost importance to us. Your safety is our top priority, and we will always provide honest and transparent advice",
      img: HonestyIcon,
    },
  ]

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "aboutmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "aboutbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title={
          prefix === "uk"
            ? "MHC Men's Health Clinic | PE & ED Treatment Reviews"
            : "Men's Health Clinic | PE & ED Treatment Reviews"
        }
        description={
          prefix === "au"
            ? "Men's Health Clinic helps men around Australia with erectile dysfunction, premature ejaculation and chronic pain. Read reviews, and learn more about our treatments."
            : "Men's Health Clinic helps men with erectile dysfunction and premature ejaculation. Read reviews, and learn more about our treatments."
        }
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/about/",
          },
          { hrefLang: "en-au", href: "https://menshealthclinic.com/au/about/" },
          { hrefLang: "en-ca", href: "https://menshealthclinic.com/ca/about/" },
          { hrefLang: "en-nz", href: "https://menshealthclinic.com/nz/about/" },
          { hrefLang: "en-ph", href: "https://menshealthclinic.com/ph/about/" },
          { hrefLang: "en-gb", href: "https://menshealthclinic.com/uk/about/" },
        ]}
      />
      <JsonLd json={schema} />
      <JsonLd json={Schemas.medicalBusiness(prefix)} />
      <JsonLd json={Schemas.organization(prefix)} />
      <JsonLd json={Schemas.webSite(prefix)} />

      <article>
        <div className="about__header--wrapper relative mx-auto w-full px-100 max680:px-50">
          <CircleLeft className="about__header--circle-left" />
          <img
            className="about__header--img-right"
            src={CircleRight}
            alt="Red circle"
          />
          {/* MOBILE */}
          <div className="mx-auto 730:hidden max680:max-w-400">
            <h1 className="w-full pt-40 text-xl font-medium text-white">
              The Men’s Health Clinic everyone knows and trusts, globally.
            </h1>
            <p className="my-40 text-white">
              Our team is ready when you are! All you need to do is{" "}
              {prefix === "nz" || prefix === "uk"
                ? "prioritize"
                : "prioritise"}{" "}
              yourself and get in contact.
            </p>
            <CustomButton
              className="orangeFilled relative z-10 -mb-8 p-8"
              text="Schedule an Appointment"
              link={
                treatmentType === "Chronic Pain Treatments & Solutions"
                  ? "https://menshealthclinic.as.me/chronicpainwebsite"
                  : TextCopies.acuityLink(prefix)
              }
            />
            <img
              className="h-180 w-full rounded-lg object-cover 470:h-250"
              src={AboutHeaderImg}
              alt="Schedule an Appointment at Men's Health Clinic"
            ></img>
          </div>
          {/* DESKTOP */}
          <div className="mx-auto max680:max-w-316 max729:hidden">
            <h1 className="about__header--title header__image--title relative">
              The Men’s Health Clinic everyone knows and trusts, globally.
            </h1>
            <p className="about__header--text relative px-4 text-lg max480:text-sm">
              Our team is ready when you are! All you need to do is{" "}
              {prefix === "nz" || prefix === "uk"
                ? "prioritize"
                : "prioritise"}{" "}
              yourself and get in contact.
            </p>
          </div>
          <div className="relative max729:hidden">
            <img
              className="about__header--img"
              src={AboutHeaderImg}
              alt="Schedule an Appointment at Men's Health Clinic"
            ></img>
            <CustomButton
              className="about__header--btn orangeFilled"
              text="Schedule an Appointment"
              link={
                treatmentType === "Chronic Pain Treatments & Solutions"
                  ? "https://menshealthclinic.as.me/chronicpainwebsite"
                  : TextCopies.acuityLink(prefix)
              }
            />
          </div>
        </div>
        <AboutStatistics
          itemClass="1024:w-[50%] 1100:w-auto"
          className="width__container mb-115 max900:mb-50"
          prefix={prefix}
        />
      </article>

      <main>
        <AboutCompany prefix={prefix} />
        <section className="width__container mb-125 max680:mb-60">
          <div className="flex flex-wrap justify-between max1360:justify-center">
            <div className="about__process--title-box">
              <h2 className="dashed__text--blue treatments__blue--text blueText">
                Our Principles / Values
              </h2>
              <h2 className="section__title company__title">
                Our values align with yours.
              </h2>
            </div>
            {processCards.map((item, index) => (
              <ProcessCardIcon
                Component={item.img}
                titleText={item.titleText}
                text={item.text}
                key={index}
                className={index % 2 === 0 ? "1000:ml-5" : "1000:mr-5"}
              />
            ))}
          </div>

          <CustomButton
            className="orangeFilled slim__btn ml-[50%] mt-40 translate-x-[-50%] max680:mt-0"
            text="Online Assessment"
            link={
              returnLink(
                "Website-Quiz",
                "Supplementary-Quiz",
                "aboutvaluesquiz",
                false,
                prefix
              )
            }
          />
        </section>

        <Experts
          experts={experts}
          slider={prefix == "ca" ? false : true}
          className="flex-wrap"
          prefix={prefix}
        />

        <OurProcess prefix={prefix} treatmentType={treatmentType} />

        <Video blueDashedText={"Meet Dr. Fred Edwards"} prefix={prefix} />

        <Reviews
          className="mb-250 max1260:mb-145"
          link={
            returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "aboutreviewsquiz",
              false,
              prefix
            )
          }
          prefix={prefix}
        />
      </main>
    </Layout>
  )
}

export default About

About.propTypes = {
  prefix: PropTypes.string,
  treatmentType: PropTypes.string,
}
