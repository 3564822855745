import * as React from "react"
import { useRef, useState, useEffect } from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { FeaturedOn } from "../../../components/featuredOn/featuredOn"
import { AcuityModal } from "../../AcuityModal/AcuityModal"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { HeaderMarks } from "../../common/HeaderMarks"
import { returnLink } from "../../../helpers/returnLink"
import HeaderImg from "../../../images/thank-you/thankYouHeader.webp"
import CallImg from "../../../images/thank-you/thankYouCall.webp"
import AssessmentImg from "../../../images/thank-you/processAssessment.webp"
import WhatsAppImg from "../../../images/thank-you/whatsappIcon.webp"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import CallWavy from "../../../assets/thank-you/thankYouWavy.svg"
import { Reviews } from "../../../components/Reviews/reviews"
import queryString from "query-string"

const ThankYou = ({ prefix, location }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Merci",
    description:
      "Félicitations d'avoir franchi la première étape vers l'acquisition de la confiance en vous que vous méritez ! Nous avons aidé des milliers d'hommes dans le monde entier à surmonter ce défi complexe avec des traitements médicaux abordables et personnalisés.",
    url: `https://menshealthclinic.com/${prefix}/thank-you`,
  }
  // WEBPAGE SCHEMA END //

  const acuityLink = useRef({
    "fr-ca":
      "https://app.acuityscheduling.com/schedule.php?owner=24056947&appointmentType=60609363",
  })

  const [acuityInformation, setAcuityInformation] = useState(null)
  const [queryId, setQueryId] = useState("")

  useEffect(() => {
    const cookie = document.cookie.match(
      new RegExp("userInformation" + "=([^;]+)")
    )
    if (cookie) {
      setAcuityInformation(JSON.parse(cookie[1]))
    }
  }, [])

  const queryParamsId = queryString.parse(location.search).queryId

  useEffect(() => {
    setQueryId(queryParamsId)
  }, [queryParamsId])

  return (
    <Layout
      headerLink={
        queryId === "3"
          ? "https://menshealthclinic.as.me/chronicpainwebsite"
          : acuityInformation
            ? acuityLink.current[prefix] +
            `&firstName=${acuityInformation.first_name}&lastName=${acuityInformation.last_name}&email=${acuityInformation.email}&phone=${acuityInformation.mobile}`
            : acuityLink.current[prefix]
      }
      headerMainButtonText="Réservez maintenant"
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "thankyoubottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Men's Health Clinic | Leaders dans le traitement de la dysfonction érectile (DE) et de l'éjaculation précoce (EP)."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/thank-you/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/thank-you/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/thank-you/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/thank-you/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/thank-you/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/thank-you/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/thank-you/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article>
        <header
          className="home__header--article max680:hidden"
          style={{
            backgroundImage: `url(${HeaderImg})`,
            backgroundImage: `linear-gradient(to top,rgba(3, 6, 84, 0.1),rgba(3, 6, 84, 1)),url(${HeaderImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="width__container h-[100%]">
            <div className="home__header--article-wrapper h-[100%]">
              <p className="header__image--dash-text home__header--img-text">
                REDONNER CONFIANCE AUX HOMMES
              </p>
              <h1 className="header__image--title home__header--img-title">
                Félicitations!
              </h1>
              <p className="header__image--text home__header-image--text max600:hidden">
                Pour avoir fait le premier pas vers la confiance en soi que vous méritez ! Nous avons aidé des milliers d'hommes dans le monde à surmonter ce défi complexe grâce à des traitements médicaux abordables et personnalisés.
              </p>
              <AcuityModal
                link={
                  queryId === "3"
                    ? "https://menshealthclinic.as.me/chronicpainwebsite"
                    : acuityInformation
                      ? acuityLink.current[prefix] +
                      `&firstName=${acuityInformation.first_name}&lastName=${acuityInformation.last_name}&email=${acuityInformation.email}&phone=${acuityInformation.mobile}`
                      : acuityLink.current[prefix]
                }
                text="Réservez votre consultation téléphonique gratuite"
                classNames="cursor-pointer slim__btn mx-auto block rounded-3xl bg-FF5733 text-center font-medium text-F5F5F5 text-15 whitespace-nowrap"
              />
              <HeaderMarks
                className="mt-100 flex justify-between text-F5F5F5 max950:mt-20 max950:block"
                firstText="Médecins canadiens agréés."
                secondText="150 000 patients soignés."
                thirdText="Expédition et soins discrets."
              />
            </div>
          </div>
        </header>
        {/* Mobile Header */}
        <header className="left-blue-semicircle relative mb-165 hidden bg-030654 p-50 text-center max680:block">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="mb-40 text-4xl font-semibold leading-[56px] text-F5F5F5 max400:text-3xl">
            Félicitations !
          </h1>
          <p className="mb-40 text-F5F5F5">
            Pour avoir fait le premier pas vers la confiance en soi que vous méritez !
          </p>
          <p className="mb-40 text-F5F5F5">
            Nous avons aidé des milliers d'hommes dans le monde à surmonter ce défi complexe grâce à des traitements médicaux abordables et personnalisés.
          </p>
          <AcuityModal
            link={
              queryId === "3"
                ? "https://menshealthclinic.as.me/chronicpainwebsite"
                : acuityInformation
                  ? acuityLink.current[prefix] +
                  `&firstName=${acuityInformation.first_name}&lastName=${acuityInformation.last_name}&email=${acuityInformation.email}&phone=${acuityInformation.mobile}`
                  : acuityLink.current[prefix]
            }
            text="Réservez votre consultation téléphonique gratuite"
            classNames="cursor-pointer slim__btn mx-auto block rounded-[100px] bg-FF5733 text-center font-medium text-F5F5F5 text-15"
          />
          <div className="absolute bottom-[-110px] left-[50%] translate-x-[-50%]">
            <img
              className="h-[200px] max-w-[90vw] rounded-20 object-cover"
              src={HeaderImg}
              alt="Image d'en-tête"
            />
          </div>
        </header>
        <div className="width__container my-55">
          <p className="mx-auto w-full max-w-1000 text-center">
            Avoir des problèmes liés à votre santé n’a rien de honteux. La plupart des hommes ne chercheront jamais à se faire soigner et souffriront en silence. Prenez le contrôle de votre santé et passez à l’étape suivante maintenant.
          </p>
        </div>
      </article>

      <main>
        <section className="relative mb-100 mt-85 flex max680:hidden max900:mt-0 max900:flex-col">
          <div className="left-top__blue-circle relative h-[350px] w-full bg-030654 max900:h-400 max900:max-w-100%">
            <CallWavy className="absolute right-0 top-0" />
            <img
              className="thank-you__call-now-img absolute right-[-1%] top-[-10%] z-10 max-w-[400px] rounded-20 max900:top-[30%] max900:w-310"
              src={CallImg}
            />
          </div>
          <div className="flex h-[350px] w-full flex-col justify-center bg-E0E0E0 pl-80 max900:bg-F5F5F5 max900:pb-50 max900:pl-0">
            <div className="max900:mt-140 max900:text-center">
              <p className="blueText process__blueText">Connecter</p>
              <h2 className="text-4xl font-bold leading-9 text-333333">
                Contactez-nous!
              </h2>
            </div>
            <div className="flex max900:flex-col">
              <div className="mt-45 flex max-w-500 items-center max600:max-w-240 max600:flex-wrap max600:justify-center max600:px-0 max900:mx-auto max900:mt-50">
                <a
                  href={`tel:${TextCopies.countryPhone(prefix)}`}
                  className="slim__btn mx-auto flex max-w-290 items-center justify-center rounded-3xl bg-030654 text-center  font-medium text-F5F5F5 transition duration-300 hover:bg-[#00003D] max600:whitespace-normal max900:mx-auto"
                >
                  <img className="mr-10 max-w-[24px]" src={AssessmentImg} />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Appelez maintenant
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-65 hidden max680:block">
          <div className="bg-E0E0E0pl-0 flex w-full flex-col justify-center">
            <div className="text-center">
              <p className="blueText process__blueText">Connecter</p>
              <h2 className="section__title">
                Contactez-nous!
              </h2>
            </div>
            <div className="flex flex-col">
              <div className="mt-45 flex items-center justify-center">
                <a
                  href="https://api.whatsapp.com/send?phone=+61415155642"
                  className="slim__btn flex items-center justify-center rounded-3xl bg-030654 text-center font-medium text-F5F5F5"
                >
                  <img className="mr-10 max-w-[24px]" src={WhatsAppImg} />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Discutez maintenant
                  </p>
                </a>
              </div>
              <div className="mt-45 flex items-center justify-center">
                <a
                  href={`tel:${TextCopies.countryPhone(prefix)}`}
                  className="slim__btn flex items-center justify-center rounded-3xl bg-030654 text-center font-medium text-F5F5F5"
                >
                  <img className="mr-10 max-w-[24px]" src={AssessmentImg} />
                  <p className="text-15 font-medium leading-6 text-F5F5F5">
                    Appelez maintenant
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="width__container mb-145 text-center max680:mb-60">
          <p className="blueText process__blueText">RÉSERVER MAINTENANT</p>
          <h2 className="section__title process__section--title-box">
            Réservez votre évaluation GRATUITE
          </h2>
          <div className="h-full w-full">
            <iframe
              src={
                queryId === "3"
                  ? "https://menshealthclinic.as.me/chronicpainwebsite"
                  : acuityInformation
                    ? acuityLink.current[prefix] +
                    `&firstName=${acuityInformation.first_name}&lastName=${acuityInformation.last_name}&email=${acuityInformation.email}&phone=${acuityInformation.mobile}`
                    : acuityLink.current[prefix]
              }
              style={{ height: "640px", width: "100%" }}
              title="book"
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default ThankYou
