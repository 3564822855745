import React from "react"
import DownloadEbook from "../../components/PageComponents/download-ebook"

const DownloadEbookPage = () => {
  const prefix = "au"

  return <DownloadEbook prefix={prefix} />
}

export default DownloadEbookPage
