import React from "react"
import Author from "../../../../components/PageComponents/author"
import DrThomasStock from "../../../../images/experts/DrStock.webp"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const DrThomasStockAuthorPage = () => {
  const prefix = "fr-ca"
  const authorInfo = {
    name: "Dr. Thomas Stock",
    title: "MBChB (Hon) MSc.",
    bio: "Le Dr Stock est un médecin généraliste qualifié qui reconnaît l'effet que la dysfonction érectile et l'éjaculation précoce peuvent avoir sur la psyché masculine. Il apporte à MHC ses connaissances et son expertise en fournissant des soins centrés sur le patient.",
    img: DrThomasStock,
    filterText: "Dr. Thomas Stock, MBChB (Hon) MSc.",
  }
  const data = useStaticQuery(graphql`
    query DrStockCAQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Author prefix={prefix} data={data} authorInfo={authorInfo} />
}

DrThomasStockAuthorPage.propTypes = {
  data: PropTypes.object,
}

export default DrThomasStockAuthorPage
