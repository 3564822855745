import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { returnLink } from "../../../helpers/returnLink"
import FRCAPrivacyPolicy from "../../PrivacyPolicy/frcaPrivacyPolicy"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"

const PrivacyPolicy = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Politique de confidentialité",
    description: "Découvrez davantage sur notre politique de confidentialité.",
    url: `https://menshealthclinic.com/${prefix}/privacy-policy`,
  }
  // WEBPAGE SCHEMA END //

  const CurrentPolicyPage = FRCAPrivacyPolicy

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "tandcmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "tandcbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Men's Health Clinic | Politique de confidentialité du traitement MHC (Men's Health Clinic)."
        description="Men's Health Clinic aide à la dysfonction érectile, à l'éjaculation précoce et aux douleurs chroniques avec des plans de traitement personnalisés. Consultez notre politique de confidentialité."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/privacy-policy/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/privacy-policy/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/privacy-policy/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/privacy-policy/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/privacy-policy/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/privacy-policy/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/privacy-policy/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="relative mb-85 overflow-hidden max680:mb-60">
        <div className="left-blue-semicircle relative h-full min-h-420 overflow-hidden bg-030654 px-25 text-center max680:min-h-320">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="header__image--title relative inline-block pt-125 max680:pt-60">
            Politique de confidentialité
          </h1>
          <p className="relative m-auto w-full max-w-500 pb-25 text-F5F5F5">
            Découvrez-en davantage sur notre Politique de confidentialité.
          </p>
        </div>
      </article>
      <CurrentPolicyPage />
    </Layout>
  )
}

export default PrivacyPolicy
