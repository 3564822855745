import * as React from "react"
import { Link } from "gatsby"
import { ImageTextEllipse } from "../common/ImageTextEllipse"

export const ArticleCard = ({
  img,
  title,
  text,
  date,
  uid,
  imgText,
  articles,
  prefix,
}) => {
  const translateTags = tags => {
    const translatedTags = tags?.map(tag => {
      switch (tag) {
        case "Erectile Dysfunction":
          return "Dysfonction érectile"
        case "Premature Ejaculation":
          return "Éjaculation précoce"
        case "Chronic Pain":
          return "Santé et bien-être"
        case "Sexual Dysfunction":
          return "Dysfonction sexuelle"
        case "Health and Wellness":
          return "La santé et le bien-être"
        default:
          return tag
      }
    })

    return translatedTags
  }

  return (
    <Link
      to={`/${prefix}/resource/${uid}`}
      state={{
        img,
        text,
        title,
        articles,
        imgText,
        date,
      }}
      rel="follow"
      className={`article__card mx-10
        cursor-pointer`}
    >
      <ImageTextEllipse
        textArr={prefix === "fr-ca" ? translateTags(imgText) : imgText}
        className={`imageTextEllipse imageTextEllipseGreyBg absolute right-20 top-30 ${
          imgText.length > 1 ? "max680:top-135" : "max680:top-165"
        }`}
      />
      <img
        className="article__card--img rounded-20"
        src={img}
        alt={title || "Men's Health Clinic resource article image"} // Use a descriptive alt text
        style={{
          objectFit: "cover", // Use objectFit property for image scaling
          width: "100%", // Ensure the image fills its container
          height: "100%",
        }}
      />
      <h3>{title}</h3>
    </Link>
  )
}
