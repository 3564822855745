import * as React from "react"
import { Link } from "gatsby"
import { CustomButton } from "../common/CustomButton"
import { StaticImage } from "gatsby-plugin-image"
import ErectileDysfunction from "../../images/mocks/dashboardTreatmentErectile.webp"
import PrematureEjaculation from "../../images/mocks/dashboardTreatmentPremature.webp"
import ChronicPain from "../../images/mocks/dashboardTreatmentChronic.webp"
import "./treatment.css"
import PropTypes from "prop-types"
import { TreatmentsTextComponents } from "../TextComponents/treatments"

export const Treatment = ({ isBtn, blueText, titleText, link, prefix }) => {
  return (
    <section
      className={`treatments__container ${isBtn && "treatment__btn--true"}`}
    >
      <div className="width__container">
        <h2 className="dashed__text--blue treatments__blue--text blueText">
          {blueText}
        </h2>
        <div className="treatments__title--box">
          <h3
            className={`section__title mr-15 ${
              prefix === "fr-ca" ? "680:max-w-[60%]" : ""
            }`}
          >
            {titleText}
          </h3>
          {isBtn && (
            <CustomButton
              className="orangeFilled treatment__action--btn slim__btn max1000:hidden"
              text={
                prefix === "fr-ca"
                  ? "Vérificateur de symptômes"
                  : "Symptoms Checker"
              }
              link={link}
            />
          )}
        </div>
        <div className="flex justify-between max1000:flex-col max1000:items-center">
          {/* AU TREATMENTS */}
          {prefix === "au" && (
            <>
              <Link
                to={`/${prefix}/treatments/erectile-dysfunction`}
                className="w-full px-5 text-center max1000:max-w-600 max1000:pb-80"
              >
                <div
                  style={{ backgroundImage: `url(${ErectileDysfunction})` }}
                  className="item-carousel relative flex h-215 flex-col items-center justify-center rounded-20 680:h-300 1000:h-215"
                >
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[-10%] w-150 680:!bottom-[-30%] 680:!w-250 1000:!bottom-[-35%] 1000:!left-[-40px] 1000:!w-150 1400:!bottom-[-45%] 1400:!w-200 sm:bottom-[-40%] sm:w-200"
                    src="../../images/mocks/mockupPillBottle2a.webp"
                    alt="Erectile Dysfunction Pills - Men's Health Clinic"
                    width="600"
                    height="480"
                    sizes="(min-width: 1520px) 282px, (min-width: 1020px) calc(20.42vw - 24px), (min-width: 860px) 413px, (min-width: 700px) 50vw, calc(64.47vw - 25px)"
                  />
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[90px] w-100 680:!bottom-[-25%] 680:!left-[32%] 1000:!bottom-[-35%] 1000:!left-80 1000:!w-100 1400:!bottom-[-40%] 1400:!left-120 sm:left-[20%]"
                    src="../../images/treatments/australianMadeLogoTransparent.webp"
                    alt="Australian Made Logo"
                    width="521"
                    height="479"
                    sizes="(min-width: 1520px) 101px, (min-width: 1020px) calc(7.29vw - 8px), (min-width: 860px) 148px, (min-width: 700px) 17.86vw, calc(23.16vw - 9px)"
                  />
                  <span>Erectile Dysfunction</span>
                  <p className="text-F5F5F5">Learn more</p>
                  <h3 className="h-50 px-30 text-xs text-F5F5F5 680:text-sm 1000:px-10 1000:text-xs 1400:text-sm">
                    We offer effective treatment options for erectile
                    dysfunction that can help the male in restoring sexual
                    health and confidence with personalised care and advanced
                    therapies.
                  </h3>
                </div>
              </Link>
              <Link
                to={`/${prefix}/treatments/premature-ejaculation`}
                className="w-full px-5 text-center max1000:max-w-600 max1000:pb-80"
              >
                <div
                  style={{ backgroundImage: `url(${PrematureEjaculation})` }}
                  className="item-carousel relative flex h-215 flex-col items-center justify-center rounded-20 680:h-300 1000:h-215"
                >
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[-10%] w-150 680:!bottom-[-30%] 680:!w-250 1000:!bottom-[-35%] 1000:!left-[-40px] 1000:!w-150 1400:!bottom-[-45%] 1400:!w-200 sm:bottom-[-40%] sm:w-200"
                    src="../../images/mocks/mockupPillBottle3a.webp"
                    alt="Premature Ejaculation Pills - Men's Health Clinic"
                    width="600"
                    height="480"
                    sizes="(min-width: 1520px) 282px, (min-width: 1020px) calc(20.42vw - 24px), (min-width: 860px) 413px, (min-width: 700px) 50vw, calc(64.47vw - 25px)"
                  />
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[90px] w-100 680:!bottom-[-25%] 680:!left-[32%] 1000:!bottom-[-35%] 1000:!left-80 1000:!w-100 1400:!bottom-[-40%] 1400:!left-120 sm:left-[20%]"
                    src="../../images/treatments/australianMadeLogoTransparent.webp"
                    alt="Australian Made Logo"
                    width="521"
                    height="479"
                    sizes="(min-width: 1520px) 101px, (min-width: 1020px) calc(7.29vw - 8px), (min-width: 860px) 148px, (min-width: 700px) 17.86vw, calc(23.16vw - 9px)"
                  />
                  <span>Premature Ejaculation</span>
                  <p className="text-F5F5F5">Learn more</p>
                  <h3 className="h-50 px-30 text-xs text-F5F5F5 680:text-sm 1000:px-10 1000:text-xs 1400:text-sm">
                    Our clinic offers specialised treatments for PE to help men
                    improve control, and consequently better their sexual
                    satisfaction.
                  </h3>
                </div>
              </Link>
              <Link
                to={`/${prefix}/treatments/chronic-pain`}
                className="w-full px-5 text-center max1000:max-w-600 max1000:pb-80"
              >
                <div
                  style={{ backgroundImage: `url(${ChronicPain})` }}
                  className="item-carousel relative flex h-215 flex-col items-center justify-center rounded-20 680:h-300 1000:h-215"
                >
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[-10%] w-150 680:!bottom-[-30%] 680:!w-250 1000:!bottom-[-35%] 1000:!left-[-40px] 1000:!w-150 1400:!bottom-[-45%] 1400:!w-200 sm:bottom-[-40%] sm:w-200"
                    src="../../images/mocks/mockupDropper2.webp"
                    alt="Chronic Pain Tincture - Men's Health Clinic"
                    sizes="(min-width: 1520px) 282px, (min-width: 1020px) calc(20.42vw - 24px), (min-width: 860px) 413px, (min-width: 700px) 50vw, calc(64.47vw - 25px)"
                  />
                  <StaticImage
                    className="!absolute bottom-[-30%] left-[110px] w-100 680:!bottom-[-25%] 680:!left-[32%] 1000:!bottom-[-35%] 1000:!left-100 1000:!w-100 1400:!bottom-[-40%] 1400:!left-140 sm:left-[20%]"
                    src="../../images/treatments/australianMadeLogoTransparent.webp"
                    alt="Australian Made Logo"
                    width="521"
                    height="479"
                    sizes="(min-width: 1520px) 101px, (min-width: 1020px) calc(7.29vw - 8px), (min-width: 860px) 148px, (min-width: 700px) 17.86vw, calc(23.16vw - 9px)"
                  />
                  <span>Chronic Pain</span>
                  <p className="text-F5F5F5">Learn more</p>
                  <h3 className="h-50 px-30 text-xs text-F5F5F5 680:text-sm 1000:px-10 1000:text-xs 1400:text-sm">
                    We offer in-depth management of chronic pain in men,
                    addressing pain linked with conditions like erectile
                    dysfunction and premature ejaculation to improve general
                    well-being.
                  </h3>
                </div>
              </Link>
            </>
          )}

          {/* CA/NZ/UK TREATMENTS */}
          {(prefix === "ca" ||
            prefix === "fr-ca" ||
            prefix === "nz" ||
            prefix === "uk") && (
            <>
              <Link
                to={`/${prefix}/treatments/erectile-dysfunction`}
                className="w-full px-5 text-center max1000:max-w-600 max1000:pb-80"
              >
                <div
                  style={{ backgroundImage: `url(${ErectileDysfunction})` }}
                  className="item-carousel relative flex h-215 flex-col items-center justify-center rounded-20 680:h-300 1000:h-215"
                >
                  <StaticImage
                    className="!absolute bottom-[-20%] left-[-30%] w-[60%] 1000:bottom-[-40%] 1000:left-[-25%] 1000:!w-250 1200:bottom-[-40px] 1200:left-[-100px] sm:w-[45%] md:w-50%"
                    src="../../images/mocks/mockupPillBottle2a.webp"
                    alt="Erectile Dysfunction Pills - Men's Health Clinic"
                    width="600"
                    height="480"
                    sizes="(min-width: 1520px) 275px, (min-width: 1020px) calc(19.79vw - 22px), (min-width: 860px) 266px, (min-width: 700px) 32.14vw, (min-width: 640px) calc(12.5vw + 181px), calc(70vw - 42px)"
                  />
                  <span>
                    {prefix === "fr-ca"
                      ? "Dysfonction érectile"
                      : "Erectile Dysfunction"}
                  </span>
                  <p className="text-F5F5F5">
                    {prefix === "fr-ca"
                      ? "Apprenez-en davantage"
                      : "Learn more"}
                  </p>
                  <h3 className="h-50 px-20 text-xs text-F5F5F5 440:px-60 680:text-sm 1000:text-xs 1400:text-sm">
                    {TreatmentsTextComponents.edText(prefix)}
                  </h3>
                </div>
              </Link>
              <Link
                to={`/${prefix}/treatments/premature-ejaculation`}
                className="w-full px-5 text-center max1000:max-w-600 max1000:pb-80"
              >
                <div
                  style={{ backgroundImage: `url(${PrematureEjaculation})` }}
                  className="item-carousel relative flex h-215 flex-col items-center justify-center rounded-20 680:h-300 1000:h-215"
                >
                  <StaticImage
                    className="!absolute bottom-[-20%] left-[-30%] w-[60%] 1000:bottom-[-40%] 1000:left-[-25%] 1000:!w-250 1200:bottom-[-40px] 1200:left-[-100px] sm:w-[45%] md:w-50%"
                    src="../../images/mocks/mockupPillBottle3a.webp"
                    alt="Premature Ejaculation Pills - Men's Health Clinic"
                    width="600"
                    height="480"
                    sizes="(min-width: 1520px) 275px, (min-width: 1020px) calc(19.79vw - 22px), (min-width: 860px) 266px, (min-width: 700px) 32.14vw, (min-width: 640px) calc(12.5vw + 181px), calc(70vw - 42px)"
                  />
                  <span>
                    {prefix === "fr-ca"
                      ? "Éjaculation précoce"
                      : "Premature Ejaculation"}
                  </span>
                  <p className="text-F5F5F5">
                    {prefix === "fr-ca"
                      ? "Apprenez-en davantage"
                      : "Learn more"}
                  </p>
                  <h3 className="h-50 px-20 text-xs text-F5F5F5 440:px-60 680:text-sm 1000:text-xs 1400:text-sm">
                    {TreatmentsTextComponents.peText(prefix)}
                  </h3>
                </div>
              </Link>
            </>
          )}
          {isBtn && (
            <CustomButton
              className="treatment__action--btn slim__btn mt-80 hidden whitespace-nowrap rounded-[8px] bg-FF5733 font-medium text-F5F5F5 max1000:block"
              text={
                prefix === "fr-ca"
                  ? "Vérificateur de symptômes"
                  : "Symptoms Checker"
              }
              link={link}
            />
          )}
        </div>
      </div>
    </section>
  )
}

Treatment.propTypes = {
  isBtn: PropTypes.string,
  blueText: PropTypes.array,
  titleText: PropTypes.string,
  link: PropTypes.string,
  prefix: PropTypes.string,
}
