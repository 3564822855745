export const blockedLeadEmails = [
  "james.mathews@mhc.com.au",
  "jamesmathews@mhc.com.au",
  "jamesmathews@thewealthcommunity.com",
  "jamesmathews@menshealthclinic.com",
  "james.mathews@menshealthclinic.com",
  "james.mathews@menshealthclinic.co.nz",
  "jamesmathews@menshealthclinic.co.nz",
  "james.mathews@menshealthclinic.ca",
  "jamesmathews@menshealthclinic.ca",
  "jamesmathews@ukmhc.co.uk",
  "james.mathews@ukmhc.co.uk",
  "james.mathews@painclinicaustralia.com.au",
  "jamesmathews@painclinicaustralia.com.au"
]