import React from "react"
import TermsConditions from "../../components/PageComponents/terms-conditions"

const TermsConditionsPage = () => {
  const prefix = "uk"

  return <TermsConditions path="/terms-conditions" prefix={prefix} />
}

export default TermsConditionsPage
