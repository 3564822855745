import React from "react"
import { useState, useEffect } from "react"
import Video from "../../components/PageComponents/fr-ca/video"

const DrNaidooVideoPage = ({ location }) => {
  const prefix = "fr-ca"

  const [showBooking, setShowBooking] = useState(true)
  const URLParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (URLParams.has("video-only")) {
      setShowBooking(false)
    }
  }, [])

  return (
    <Video
      prefix={prefix}
      title={"Rencontrez le Dr. Suren Naidoo"}
      link="https://www.youtube.com/embed/ncObEd3QlTk?si=99HxOw5GxrYZV6Vk"
      showBooking={showBooking}
    />
  )
}

export default DrNaidooVideoPage
