import * as React from "react"
import { TextCopies } from "../../../../helpers/countryTextCopies"

const returnCardText = (title, prefix) => {
  const text = {
    "Presse et actualités":
      "Pour les demandes liées aux médias, veuillez nous contacter en utilisant les coordonnées ci-dessous.",
    "Soutien de la clinique": `Nous desservons les hommes à travers le Canada avec l'aide de notre réseau de médecins canadiens.`,
    "Siège social": `Lundi à vendredi : 8h00 - 20h00\nSamedi : 10h00 - 13h00\nDimanche : Sur rendez-vous`,
  }

  if (prefix === "fr-ca") {
    text[
      "Siège social"
    ] = `Lundi à vendredi : 8h00 - 18h00\nSamedi et dimanche : Fermé`
    return text[title]
  }
}

export const ContactCard = ({ ImgComponent, title, prefix }) => {
  const HeadOfficeComponent = () => {
    const components = {
      "fr-ca": (
        <>
          <a className="pt-20 underline" href={TextCopies.acuityLink("fr-ca")}>
            204 – 1005 Broad St. Victoria,
          </a>
          <a
            className="pb-40 pt-5 underline"
            href={TextCopies.acuityLink("fr-ca")}
          >
            BC V8W 2A1
          </a>
        </>
      ),
    }

    return components[prefix]
  }

  const whatsAppChatNum = {
    "fr-ca": "+1 833 536 5854",
  }

  return (
    <div className="border-with-radius relative mx-5 mt-30 flex w-full max-w-360 flex-col items-center bg-F5F5F5 px-10 text-center">
      <div className="flex w-full max-w-140 justify-center pt-50">
        <ImgComponent />
      </div>
      <h3 className="pt-20">{title}</h3>
      <p className="w-full max-w-270 whitespace-pre-line border-b border-solid border-BDBDBD pb-20 pt-5">
        {returnCardText(title, prefix)}
      </p>
      {title === "Siège social" && <HeadOfficeComponent />}
      {title === "Soutien de la clinique" && (
        <>
          <a
            className="pt-20 text-FF5733 underline"
            href={`mailto:${TextCopies.email(prefix)}`}
          >
            {TextCopies.email(prefix)}
          </a>
          <a
            className="pt-5 text-FF5733 underline"
            href={TextCopies.whatsappApiLink(prefix)}
          >
            Numéro de chat WhatsApp: {whatsAppChatNum[prefix]}
          </a>
          <a
            className="pb-40 pt-5"
            href={`tel:${TextCopies.countryPhone(prefix)}`}
          >
            {TextCopies.countryPhone(prefix)}
          </a>
        </>
      )}
      {title === "Presse et actualités" && (
        <>
          <a
            className="pt-20 text-FF5733 underline"
            href={`mailto:marketing@menshealthclinic.com`}
          >
            marketing@menshealthclinic.com
          </a>
          <a
            className="pb-40 pt-5"
            href={`tel:${TextCopies.countryPhone(prefix)}`}
          >
            {TextCopies.countryPhone(prefix)}
          </a>
        </>
      )}
    </div>
  )
}
