import * as React from "react"
import { useState } from "react"
import { navigate } from "gatsby"
import { CustomButton } from "../../../common/CustomButton"
import SelectArrowDown from "../../../../assets/common/selectArrowDown.svg"
import axios from "axios"
import { blockedLeadEmails } from "../../../../helpers/blockedLeadEmails"

export const ContactUsBox = ({ children, prefix }) => {
  const [error, setError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [submitData, setSubmitData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    inquiry_related: "",
    message: "",
    lead_source: "Website Form",
    web_form_completed: "Contact Us",
    website_form_url: `https://menshealthclinic.com/${prefix}/contact-us`,
    country: "FR-CA",
  })

  const optionArray = [
    {
      text: "Dysfonction érectile",
      value: "Erectile Dysfunction",
    },
    {
      text: "Éjaculation précoce",
      value: "Premature Ejaculation",
    },
  ]

  const url = "https://api.menshealthclinic.com/"

  const handleSubmit = async () => {
    if (
      !submitData.first_name ||
      !submitData.last_name ||
      !submitData.email ||
      !submitData.mobile ||
      !submitData.inquiry_related ||
      !submitData.message ||
      !submitData.country
    ) {
      setError("Le champ est obligatoire")
      return
    }

    if (!validateEmail(submitData.email)) {
      setEmailError("Veuillez entrer une adresse e-mail valide")
      return
    }

    if (blockedLeadEmails.includes(submitData.email)) {
      setEmailError("L'utilisation de l'adresse e-mail n'est pas possible")
      return
    }

    try {
      const response = await axios.post(
        `${url}public/v1/inquiry/contact-us`,
        submitData
      )
      if (response.status === 200) {
        navigate(`/${prefix}/thank-you`)
      }
    } catch (e) {
      console.log(e)
    }
    return
  }

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(/\S+@\S+\.\S+/)
  }

  return (
    <form className="relative w-full max-w-675 rounded-md bg-white px-4 py-30 shadow sm:px-7 max600:p-15 max600:pt-25">
      {children}
      <div className="relative mt-20 flex justify-between 900:mb-25 max900:flex-wrap">
        <div className="w-full 1000:mr-10">
          <label
            htmlFor="first_name"
            className="block text-14 font-medium text-gray-700"
          >
            Prénom
          </label>
          <input
            onChange={e =>
              setSubmitData(prev => {
                return { ...prev, first_name: e.target.value }
              })
            }
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
          />
          {!submitData.first_name && (
            <span className="text-red-500">{error}</span>
          )}
        </div>
        <div className="w-full 1000:ml-10">
          <label
            htmlFor="last_name"
            className="block text-14 font-medium text-gray-700"
          >
            Nom de famille
          </label>
          <input
            onChange={e =>
              setSubmitData(prev => {
                return { ...prev, last_name: e.target.value }
              })
            }
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
          />
          {!submitData.last_name && (
            <span className="text-red-500">{error}</span>
          )}
        </div>
      </div>
      <div className="relative flex justify-between 900:mb-25 max900:flex-wrap">
        <div className="w-full 1000:mr-10">
          <label
            htmlFor="mobile"
            className="block text-14 font-medium text-gray-700"
          >
            Numéro de téléphone
          </label>
          <input
            onChange={e =>
              setSubmitData(prev => {
                return { ...prev, mobile: e.target.value }
              })
            }
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
            type="number"
          />
          {!submitData.mobile && <span className="text-red-500">{error}</span>}
        </div>
        <div className="w-full 1000:ml-10">
          <label
            htmlFor="email"
            className="block text-14 font-medium text-gray-700"
          >
            Adresse e-mail
          </label>
          <input
            onChange={e =>
              setSubmitData(prev => {
                return { ...prev, email: e.target.value }
              })
            }
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
          />
          {!submitData.email && <span className="text-red-500">{error}</span>}
          {emailError && <span className="text-red-500">{emailError}</span>}
        </div>
      </div>
      <div className="border-with-radius-10 mb-25 w-full overflow-hidden">
        <div className="relative">
          <SelectArrowDown className="pointer-events-none absolute right-30 top-[50%] translate-y-[-50%]" />
          <select
            onChange={event =>
              setSubmitData(prev => {
                return { ...prev, inquiry_related: event.target.value }
              })
            }
            className="w-full cursor-pointer appearance-none bg-white p-10 text-sm "
          >
            <option value="" disabled selected hidden>
              Sélectionnez votre demande
            </option>
            {optionArray.map((el, idx) => (
              <option key={idx} value={el.value}>
                {el.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      {!submitData.inquiry_related && (
        <span className="text-red-500">{error}</span>
      )}
      <div className="relative mb-25 w-full">
        <label
          htmlFor="email"
          className="block text-14 font-medium text-gray-700"
        >
          En quoi pouvons-nous vous aider ?
        </label>
        <textarea
          onChange={e =>
            setSubmitData(prev => {
              return { ...prev, message: e.target.value }
            })
          }
          className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300"
        />
        {!submitData.message && <span className="text-red-500">{error}</span>}
      </div>
      <div onClick={handleSubmit}>
        <CustomButton
          className="mb-20 flex w-full justify-center rounded-md border border-transparent bg-030654 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-300 hover:bg-[#00003D] focus:outline-none focus:ring-2 focus:ring-offset-2"
          text="Envoyer un message"
          link="#!"
        />
      </div>
    </form>
  )
}
