import React from "react"
import { useState, useEffect } from "react"
import Video from "../../components/PageComponents/video"
import DrNaidooVideo from "../../videos/dr-naidoo-video.mp4"

const DrNaidooVideoPage = ({ location }) => {
  const prefix = "nz"

  const [showBooking, setShowBooking] = useState(true)
  const URLParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (URLParams.has("video-only")) {
      setShowBooking(false)
    }
  }, [])

  return (
    <Video
      prefix={prefix}
      title={"Meet Dr. Suren Naidoo"}
      videoSrc={DrNaidooVideo}
      showBooking={showBooking}
    />
  )
}

export default DrNaidooVideoPage
