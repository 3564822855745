import * as React from "react"
import { useState, useEffect } from "react"
import HomeHeaderImg300 from "../../../images/homepage/homeHeaderImg/homeHeaderImg300.webp"
import HomeHeaderImg500 from "../../../images/homepage/homeHeaderImg/homeHeaderImg500.webp"
import HomeHeaderImg960 from "../../../images/homepage/homeHeaderImg/homeHeaderImg960.webp"
import HomeHeaderImg1320 from "../../../images/homepage/homeHeaderImg/homeHeaderImg1320.webp"
import HomeHeaderImg1600 from "../../../images/homepage/homeHeaderImg/homeHeaderImg1600.webp"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd, LocalBusinessJsonLd } from "gatsby-plugin-next-seo"
import { HeaderMarks } from "../../../components/common/HeaderMarks"
import { Video } from "../../../components/Video/video"
import { FeaturedOn } from "../../../components/featuredOn/featuredOn"
import { Treatment } from "../../../components/Treatment/treatment"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Reviews } from "../../../components/Reviews/reviews"
import { Experts } from "../../../components/Experts/experts"
import { AssessmentQuiz } from "../../../components/AssessmentQuiz/assessmentQuiz"
import { Faqs } from "../../../components/Faqs/faqs"
import { Articles } from "../../../components/Articles/articles"
import { assessmentQuizzesArr } from "../../../mocks/mocks"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { IndexTextComponents } from "../../TextComponents/index"
import { CustomButton } from "../../../components/common/CustomButton"
import { returnLink } from "../../../helpers/returnLink"
import { use100vh } from "react-div-100vh"
import RedirectModal from "../../RedirectModal/redirect-modal"
import MultiCircles from "../../../assets/homepage/multiCircles.svg"
import BigLinedCircle from "../../../assets/homepage/bigLinedCircle.svg"
import WavyCircle from "../../../assets/homepage/wavyCircle.svg"
import BigWavyCircle from "../../../assets/homepage/bigWavyCircle.svg"
import axios from "axios"
import "../../../styles/home.css"
import { useWindowWidth } from "../../../hooks/useWindowWidth"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const faqSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Quels sont les symptômes de la dysfonction érectile ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Les symptômes de la dysfonction érectile peuvent inclure des difficultés à obtenir ou maintenir une érection, une diminution du désir sexuel et des problèmes pour atteindre une performance sexuelle satisfaisante. Si vous éprouvez l'un de ces symptômes, consultez un professionnel de la santé pour un diagnostic approprié.",
      },
    },
    {
      "@type": "Question",
      name: "Que devrais-je faire si j'ai des problèmes d'éjaculation précoce (PE) ou de dysfonction érectile (ED) ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Si vous soupçonnez avoir des troubles sexuels, commencez par utiliser un outil en ligne d'évaluation des symptômes. Apportez ces résultats à votre rendez-vous médical afin d'identifier une cause. Une fois la cause identifiée, plusieurs options de traitement seront disponibles.",
      },
    },
    {
      "@type": "Question",
      name: "Quels sont les symptômes de l'éjaculation précoce ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Les symptômes de la dysfonction érectile peuvent inclure des difficultés à obtenir ou à maintenir une érection, une diminution du désir sexuel et des défis pour atteindre une performance sexuelle satisfaisante. Si vous éprouvez l'un de ces symptômes, consultez un professionnel de la santé pour un diagnostic approprié.",
      },
    },
    {
      "@type": "Question",
      name: "Comment Men's Health Clinic se distinguent-elles ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Nous sommes la principale clinique nationale au Canada offrant des solutions de traitement entièrement personnalisées et gérées pour les hommes souffrant d'éjaculation précoce et de dysfonction érectile. Chaque homme est différent. Notre traitement sur mesure varie pour chaque personne afin de garantir la meilleure chance de guérison avec des effets secondaires minimes. Nos gestionnaires de performance des patients (PPM) évaluent vos résultats mensuellement pour s'assurer que vous atteignez le plus haut niveau de performance sexuelle. Pour raviver votre vie amoureuse, planifiez une consultation GRATUITE avec Men's Health Clinic Canada.",
      },
    },
    {
      "@type": "Question",
      name: "Comment une Men's Health Clinic peut-elle aider?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Men's Health Clinic est une clinique de télésoins en ligne qui dessert des milliers d'hommes partout au Canada. Notre équipe de médecins canadiens agréés est spécialisée dans la santé des hommes, avec des décennies d'expérience dans le domaine. Nous proposons des plans de traitement personnalisés aux patients luttant contre l'éjaculation précoce. Nous encourageons les hommes à reprendre le contrôle de leur vie sexuelle et à se sentir plus confiants à l'intérieur et à l'extérieur de la chambre à coucher. Pour en savoir plus sur les solutions personnalisées de Men's Health Clinic, appelez-nous dès aujourd'hui au +1 (800) 422 8149.",
      },
    },
    {
      "@type": "Question",
      name: "Comment puis-je traiter ma préoccupation médicale?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Une fois que vous avez reçu votre diagnostic, notre équipe de pharmacie expédiera vos ordonnances directement à votre porte dans un emballage discret. Informez-vous davantage sur la manière dont la Men's Health Clinic peut vous aider à retrouver votre confiance et à recommencer à apprécier la vie sexuelle.",
      },
    },
  ],
}

const AboutUsText = ({ prefix, readMore }) => {
  return (
    <>
      <p className="welcome__text--item relative z-10">
        {IndexTextComponents.aboutUsFirstParagraph(prefix)}
      </p>
      {readMore && (
        <>
          <p className="welcome__text--item relative z-10">
            {IndexTextComponents.aboutUsSecondParagraph(prefix)}
          </p>
          <p className="welcome__text--item relative z-10">
            {IndexTextComponents.aboutUsThirdParagraph(prefix)}
          </p>
          <CustomButton
            className="orangeFilled slim__btn"
            text="Découvrez-en davantage"
            navLink={`/${prefix}/about`}
          />
        </>
      )}
    </>
  )
}

const Home = ({ data, prefix }) => {
  const [readMore, setReadMore] = useState(false)
  const [modal, setModal] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)

  const articles = data.nodes
    .filter(article => article.lang === `fr-ca`)
    .slice(0, 2)
  const height = use100vh() - 98
  const smallScreen = height < 465
  const currentWindowWidth = useWindowWidth()

  useEffect(() => {
    setWindowWidth(currentWindowWidth)
  }, [currentWindowWidth])

  const assessmentQuizLink = TextCopies.assessmentQuizLinks(prefix)
    ? TextCopies.assessmentQuizLinks(prefix)
    : returnLink("Website-Quiz", "Banner-Quiz", "homebannerquiz", false, prefix)

  const toggleModal = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      axios.get("https://ipapi.co/json/").then(res => {
        if (res.data.country !== "AU" && prefix === "au") {
          toggleModal()
          return
        }
        if (res.data.country !== "CA" && prefix === "ca") {
          toggleModal()
          return
        }
        if (res.data.country !== "PH" && prefix === "ph") {
          toggleModal()
          return
        }
        if (res.data.country !== "NZ" && prefix === "nz") {
          toggleModal()
          return
        }
        if (res.data.country !== "UK" && prefix === "uk") {
          toggleModal()
          return
        }
      })
    }
  }, [])

  const returnMetaInfo = prefix => {
    switch (prefix) {
      case "fr-ca":
        return {
          title: "Men's Health Clinic Canada | ED & PE Traitement",
          description:
            "MHC offre des traitements personnalisés et pratiques pour l'éjaculation précoce, la dysfonction érectile et la douleur chronique. Nous prenons soin de la santé des hommes au Canada.",
        }
    }
  }

  return (
    <>
      {modal && <RedirectModal toggleModal={toggleModal} prefix={prefix} />}
      <Layout
        headerLink={returnLink(
          "Website-Quiz",
          "Banner-Quiz",
          "homemenuquiz",
          false,
          prefix
        )}
        footerLink={returnLink(
          "Website-Quiz",
          "Bottom-Quiz",
          "homebottomquiz",
          false,
          prefix
        )}
        prefix={prefix}
      >
        <GatsbySeo
          title={returnMetaInfo(prefix).title}
          description={returnMetaInfo(prefix).description}
          languageAlternates={[
            { hrefLang: "x-default", href: "https://menshealthclinic.com/au/" },
            { hrefLang: "en-au", href: "https://menshealthclinic.com/au/" },
            { hrefLang: "en-ca", href: "https://menshealthclinic.com/ca/" },
            { hrefLang: "en-nz", href: "https://menshealthclinic.com/nz/" },
            { hrefLang: "en-ph", href: "https://menshealthclinic.com/ph/" },
            { hrefLang: "en-gb", href: "https://menshealthclinic.com/uk/" },
            { hrefLang: "fr-ca", href: "https://menshealthclinic.com/fr-ca/" },
          ]}
        />
        <JsonLd
          json={{
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Men's Health Clinic",
            alternateName: "MHC",
            description:
              "MHC propose des solutions de traitement personnalisées pour l'éjaculation précoce, la dysfonction érectile et la douleur chronique. Parlez à l'un de nos médecins agréés en santé masculine dès aujourd'hui !",
            url: `https://menshealthclinic.com/${prefix}`,
            logo: "https://menshealthclinic.com/favicon.svg?v=12d3a5769ff3e8fc2d9af2832aa108ff",
          }}
        />
        <LocalBusinessJsonLd
          id={`https://menshealthclinic.com/${prefix}`}
          name="Men's Health Clinic"
          description="MHC propose des solutions de traitement personnalisées pour l'éjaculation précoce, la dysfonction érectile et la douleur chronique. Parlez à l'un de nos médecins agréés en santé masculine dès aujourd'hui !"
          url={`https://menshealthclinic.com/${prefix}`}
          telephone={`${TextCopies.country(prefix)}`}
          address={TextCopies.schemaAddress(prefix)}
        />
        <JsonLd json={faqSchema} />

        <article className="home__header--article">
          <div className="header__image--background">
            <img
              src={HomeHeaderImg300}
              alt="Men's Health Clinic Erectile Dysfunction and Premature Ejaculation Doctor"
              width="300"
              height="200"
              sizes="100vw"
              srcSet={`
                ${HomeHeaderImg300} 300w,
                ${HomeHeaderImg500} 500w,
                ${HomeHeaderImg960} 960w,
                ${HomeHeaderImg1320} 1320w,
                ${HomeHeaderImg1600} 1600w,
              `}
              fetchPriority="high"
            />
          </div>
          <div className="width__container home__header--article-wrapper">
            <p className="header__image--dash-text home__header--img-text">
              RESTAURER LA CONFIANCE CHEZ LES HOMMES
            </p>

            <h1 className="header__image--title home__header--img-title max680:text-center">
              La principale Men's Health Clinic au Canada
            </h1>

            <p className="header__image--text home__header-image--text">
              {IndexTextComponents.homeHeaderParagraph(prefix)}
            </p>

            <CustomButton
              className="orangeFilled slim__btn home__header_btn"
              text="Évaluation en ligne"
              link={assessmentQuizLink}
            />

            <HeaderMarks
              className="mt-100 flex justify-between text-F5F5F5 max950:mt-20 max950:block"
              firstText="Médecins canadiens agréés"
              secondText="15 000+ patients traités."
              thirdText="Expédition discrète et soins attentifs."
            />
          </div>
        </article>

        <main>
          <Video
            blueDashedText="Rencontrez le Dr Fred Edwards"
            prefix={prefix}
          />

          {prefix == "au" ? <FeaturedOn className="mb-100" /> : null}

          <section className="welcome__container width__container">
            <div className="flex flex-col">
              <div className="mb-40 flex flex-col items-center justify-center gap-x-80 gap-y-30 md:flex-row">
                {(prefix === "ca" || prefix === "fr-ca") && (
                  <a
                    href="https://www.bbb.org/ca/bc/victoria/profile/mens-health/mhc-mens-health-clinic-ltd-0047-235970140?utm_campaign=bbb_seal&utm_content=MHC%20Men%E2%80%99s%20Health%20Clinic%20Ltd&utm_medium=website&utm_source=seal_click_235970140"
                    target="_blank"
                    rel="noreferrer"
                    className="md:pt-20"
                  >
                    <StaticImage
                      src="../../../images/homepage/bbb.webp"
                      alt="BBB Logo"
                    />
                    <div>
                      <span
                        lang="en"
                        style={{
                          color: "#015A75",
                          fontSize: "0.875rem",
                          fontWeight: "bold",
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        CLASSEMENT BBB: A+
                      </span>
                    </div>
                  </a>
                )}
                <a
                  href="https://legitscript.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../../../images/homepage/legitScript.webp"
                    alt="LegitScript Logo"
                  />
                </a>
                {(prefix === "ca" || prefix === "fr-ca") && (
                  <StaticImage
                    src="../../../images/homepage/cbrb.webp"
                    alt="CBRB Logo"
                    className="h-120 w-140"
                  />
                )}
              </div>
              <p className="welcome__blueText py-4">
                10 ans d&apos;excellence
                <StaticImage
                  className="welcome__blueText--img"
                  src="../../../images/homepage/balloons.webp"
                  alt="balloons"
                />
              </p>
            </div>

            <h2 className="section__title welcome__title relative">
              Bienvenue à la MHC, la Men&apos;s Health Clinic.
            </h2>

            <div className="welcome__text--box">
              <AboutUsText prefix={prefix} readMore={readMore} />

              {!readMore && (
                <h3
                  onClick={() => setReadMore(true)}
                  className="cursor-pointer text-xl font-semibold text-FF5733 transition duration-300 hover:text-[#e8401c]"
                >
                  En savoir plus sur nos traitements.
                </h3>
              )}
            </div>

            <BigLinedCircle className="linedCircle absolute" />
            <MultiCircles className="multiCircle absolute z-[-1]" />
          </section>

          <Treatment
            isBtn={true}
            blueText={"Nos traitements"}
            titleText="Consultez notre gamme de plans de traitement personnalisés pour la ED et PE."
            link={assessmentQuizLink}
            prefix={prefix}
          />

          <OurProcess
            prefix={prefix}
            title={
              prefix === "au"
                ? "Our PE, ED and Chronic Pain Treatments are Easy, Convenient and All About You!"
                : prefix === "fr-ca"
                ? "Nos traitements pour PE et la ED sont simples, pratiques et centrés sur vous !"
                : "Our PE and ED Treatments are Easy, Convenient and All About You!"
            }
          />

          <Reviews link={assessmentQuizLink} prefix={prefix} />

          <Experts
            experts={TextCopies.experts(prefix)}
            title={
              prefix === "fr-ca"
                ? "Nos médecins spécialisés en santé masculine offrent un haut niveau de qualité, d'excellence et de soins."
                : "Our men's health doctors offer a high degree of quality, excellence and care."
            }
            prefix={prefix}
          />

          {prefix === "ph" ? null : (
            <section className="eBook">
              <div className="width__container flex items-center py-30 max1000:flex-col max1000:py-0">
                <div className="max1000:align-center flex-1 max1000:flex max1000:flex-col">
                  <h2 className="white relative mb-40 text-4xl font-semibold max1000:text-center max1000:text-3xl">
                    Apprenez-en davantage sur nos différents plans de traitement
                    avec notre eBook
                  </h2>
                  <CustomButton
                    className="slim__btn relative z-20 inline-block rounded-8 bg-3FB1FC font-semibold text-white hover:bg-[#289AE5] max1000:mx-auto"
                    text="Téléchargez un eBook gratuit"
                    navLink={`/${prefix}/download-ebook`}
                  />
                </div>
                {prefix === "au" ? (
                  <StaticImage
                    className="z-10 w-[60%] flex-1 object-cover max1000:hidden"
                    alt="MHC Free Downloadable E-book"
                    src="../../../images/mocks/infopackMockup1.webp"
                  />
                ) : (
                  <StaticImage
                    className="z-10 w-[60%] flex-1 object-cover max1000:hidden"
                    alt="MHC Free Downloadable E-book"
                    src="../../../images/mocks/infopackMockup2.webp"
                  />
                )}
              </div>
              <WavyCircle className="eBook__wavyCircleSVG absolute" />
              <BigWavyCircle className="eBook__bigWavyCircleSVG absolute" />
            </section>
          )}

          <AssessmentQuiz
            arr={assessmentQuizzesArr(
              "Website-Quiz",
              "Supplementary-Quiz",
              "homeselfassessquiz",
              false,
              prefix
            )}
            isBtn={false}
            blueText="Quiz d'auto-évaluation"
            titleText="Vous n'êtes pas sûr de la condition que vous pourriez avoir ? Faites l'un de nos questionnaires d'évaluation !"
            link={returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "homeselfassessquiz",
              false,
              prefix
            )}
            prefix={prefix}
          />

          <Faqs
            blueText="FAQS"
            titleText={
              prefix === "fr-ca"
                ? "Foire aux questions"
                : "Frequently Asked Questions"
            }
            isButton={true}
            bottomText={true}
            className="mb-100"
            prefix={prefix}
            link={assessmentQuizLink}
          />

          <Articles
            blueText="Articles"
            titleText={
              prefix === "fr-ca"
                ? "Dernières ressources et blogs"
                : "Latest Resources & Blogs"
            }
            slider={true}
            buttonClass="bottom-0"
            className="mb-145 pb-75"
            navLink={`/${prefix}/resources/#resourcesArticles`}
            articles={articles}
            prefix={prefix}
          />
        </main>
      </Layout>
    </>
  )
}

export default Home

AboutUsText.propTypes = {
  prefix: PropTypes.string,
  readMore: PropTypes.bool,
}

Home.propTypes = {
  data: PropTypes.array,
  prefix: PropTypes.string,
  titleCountry: PropTypes.string,
}
