import React from "react"
import Treatments from "../../../components/PageComponents/treatments"

const Index = () => {
  const prefix = "nz"

  return <Treatments prefix={prefix} />
}

export default Index
