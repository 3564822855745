import React from "react"
import Authors from "../../../components/PageComponents/authors"

const AuthorsPage = () => {
  const prefix = "fr-ca"

  return <Authors prefix={prefix} />
}

export default AuthorsPage
