import React from "react"
import { Layout } from "../layout"
import { returnLink } from "../../helpers/returnLink"
import VslVideo from "../../videos/vsl-video.mp4"
import PropTypes from "prop-types"

const VideoSalesLetter = ({ prefix }) => {
  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "homemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "homebottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
      displayOnlyEssentialDetails={true}
    >
      <div className="width__container my-50 max680:my-60 flex flex-col items-center gap-20">
        <div className="flex w-full flex-col gap-20 mx-auto text-center">
          <h2 className="text-030654 text-2xl">
            You Don't Have to Struggle with ED & PE...
          </h2>
          <h1 className="text-[40px] leading-[40px] text-[#474747]">
            Reclaim Your <span className="text-FF5733 font-bold">Confidence, Intimate Connections & Self-Esteem</span>
          </h1>
          <p className="text-black text-xl">
            (Watch the Video Below to Find Out How...)
          </p>
        </div>

        <video
          autoPlay
          muted
          controls
          playsInline
          height={370}
          width={660}
          className="rounded-lg mx-auto"
          playsInline
        >
          <source src={VslVideo} type="video/mp4" />
        </video>
      </div>
    </Layout>
  )
}

export default VideoSalesLetter

VideoSalesLetter.propTypes = {
  prefix: PropTypes.string,
  videoSrc: PropTypes.string,
  title: PropTypes.string,
  showBooking: PropTypes.bool,
}
