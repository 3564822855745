import React from "react"
import Resource from "../../../components/PageComponents/resource"
import { graphql, useStaticQuery } from "gatsby"

const Index = ({ params }) => {
  const prefix = "nz"
  const data = useStaticQuery(graphql`
    query ResourceSingleNZQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Resource data={data} prefix={prefix} params={params} />
}

export default Index
