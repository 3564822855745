import React from "react"
import Terms from "../../components/PageComponents/fr-ca/terms-conditions"

const TermsPage = () => {
  const prefix = "fr-ca"

  return <Terms prefix={prefix} />
}

export default TermsPage
