import React from "react"
import VSL from "../../components/PageComponents/vsl"

const VideoSalesLetterPage = () => {
  const prefix = "au"

  return <VSL prefix={prefix} />
}

export default VideoSalesLetterPage
