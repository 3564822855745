import * as React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ExternalRedirect from "../../components/PageComponents/externalRedirect"

const PEInfoPackAU = () => {
  return (
    <>
      <GatsbySeo
        title="Men's Health Clinic | Download PE Treatment Information"
        descirption="Men's Heath Clinic helps treat premature ejaculation in Australia. Download our Information Pack PE treatment now."
      />
      <ExternalRedirect link="https://docs.google.com/gview?url=https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/PE+Info+Pack+-+AU.pdf" />
    </>
  )
}

export default PEInfoPackAU
