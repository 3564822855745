import * as React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

const ExternalRedirect = ({ link }) => {
  React.useEffect(() => {
    navigate(link)
  }, [])

  return (
    <div className="p-[48px]">
      <p className="text-2xl">Loading your PDF...</p>
    </div>
  )
}

export default ExternalRedirect

ExternalRedirect.propTypes = {
  link: PropTypes.string,
}
