export const TreatmentsTextComponents = {
  edText: prefix => {
    const edText = {
      ca: "Specialising in erectile dysfunction treatments, we offer tailor-made solutions that have been designed to help restore your confidence and improve sexual health through the newest advancements in medical science.",
      nz: "Men's Health Clinic offers customised erectile dysfunction treatments designed to restore confidence and improve your sexual health with advanced medical solutions.",
      uk: "Discover how our cutting-edge custom-tailored treatments have already restored the confidence of over 150,000 men worldwide.",
    }
    return edText[prefix]
  },
  peText: prefix => {
    const peText = {
      ca: "Our clinic offers a very successful treatment for premature ejaculation, where we give personal care with proven solutions that will help you get back to control and improve your sexual performance.",
      nz: "We offer personalised treatment for premature ejaculation, providing compassionate care to help regain control and improve sexual performance.",
      uk: "Discover how our cutting-edge custom-tailored treatments have already restored the confidence of over 150,000 men worldwide.",
      "fr-ca":
        "Notre clinique propose un traitement très efficace contre l'éjaculation précoce, où nous offrons des soins personnalisés avec des solutions éprouvées qui vous aideront à reprendre le contrôle et à améliorer vos performances sexuelles.",
    }
    return peText[prefix]
  },
}
