import * as React from "react"
import { useState, useEffect } from "react"

const BookNow = ({ location }) => {
  const [acuityLink, setAcuityLink] = useState("")
  const URLParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (URLParams.has("cp")) {
      setAcuityLink(
        "https://app.acuityscheduling.com/schedule.php?owner=18563844&appointmenttype=24590728"
      )
    } else if (URLParams.has("edpe")) {
      setAcuityLink(
        "https://app.acuityscheduling.com/schedule.php?owner=18563844&appointmenttype=12241015"
      )
    } else {
      setAcuityLink(
        "https://app.acuityscheduling.com/schedule.php?owner=18563844&calendarid=3386645"
      )
    }
  }, [])

  return (
    <div>
      <iframe
        src={
          acuityLink +
          `&firstName=${URLParams.get("firstName")}&lastName=${URLParams.get(
            "lastName"
          )}&email=${URLParams.get("email")}&phone=${URLParams.get("phone")}`
        }
        width="100%"
        height="800"
        frameBorder="0"
      />
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      />
    </div>
  )
}

export default BookNow
