import React from "react"
import TreatmentCost from "../../components/PageComponents/fr-ca/treatment-cost"

const TreatmentCostPage = () => {
  const prefix = "fr-ca"

  return <TreatmentCost prefix={prefix} />
}

export default TreatmentCostPage
