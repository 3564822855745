import React, { useEffect } from "react"
import { Layout } from "../../../components/layout"
import { returnLink } from "../../../helpers/returnLink"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Treatment } from "../../../components/Treatment/treatment"
import { TextCopies, experts } from "../../../helpers/countryTextCopies"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Experts } from "../../../components/Experts/experts"
import HonestyIcon from "../../../assets/about/honestyIcon.svg"
import QualityIcon from "../../../assets/about/qualityIcon.svg"
import CareIcon from "../../../assets/about/careIcon.svg"
import TeamworkIcon from "../../../assets/about/teamworkIcon.svg"
import ResearchIcon from "../../../assets/about/researchAndDevelopmentIcon.svg"
import { ProcessCardIcon } from "../../../components/ProcessCard/processCardIcon"
import { CustomButton } from "../../../components/common/CustomButton"
import { Video } from "../../../components/Video/video"
import { Faqs } from "../../../components/Faqs/faqs"
import { Schemas } from "../../../schemas/schemas"
import { Reviews } from "../../../components/Reviews/reviews"
import { Articles } from "../../../components/Articles/articles"
import { graphql } from "gatsby"

const TestosteroneReplacementTherapy = ({ data }) => {
  const prefix = "au"

  const processCards = [
    {
      titleText: "Quality",
      text: `Our treatments have the highest degree of quality in Australia. We use advanced, industry-leading formulations.`,
      img: QualityIcon,
    },
    {
      titleText: "Research & Development",
      text: "We continuously seek ways to enhance our treatment plans and improve the experiences of our patients by leveraging the most advanced technology available.",
      img: ResearchIcon,
    },
    {
      titleText: "Care",
      text: "Our treatments are tailor made to the individual patient. No two people are the same and that’s why treatment is created based on your individual needs.",
      img: CareIcon,
    },
    {
      titleText: "Teamwork",
      text: "Understanding your goals and collaboratively working towards achieving them are fundamental principles for our team.",
      img: TeamworkIcon,
    },
    {
      titleText: "Honesty",
      text: "Ensuring the qualification of our patients for treatment is of utmost importance to us. Your safety is our top priority, and we will always provide honest and transparent advice",
      img: HonestyIcon,
    },
  ]

  const questions = [
    {
      question: "What is Testosterone Replacement Therapy (TRT)?",
      answer:
        "TRT is a medical treatment that involves replacing or boosting testosterone levels in individuals with diagnosed testosterone deficiency. It aims to alleviate symptoms associated with low testosterone.",
    },
    {
      question: "Who is a candidate for Testosterone Replacement Therapy?",
      answer:
        "Candidates for TRT typically have clinically low testosterone levels and are experiencing symptoms such as fatigue, low libido, mood changes, and reduced muscle mass. A healthcare provider will assess eligibility based on individual circumstances.",
    },
    {
      question:
        "What are the common methods of administering Testosterone Replacement Therapy?",
      answer:
        "TRT can be administered through various methods, including injections, gels, patches, and implants. The choice of method depends on factors like patient preference and the healthcare provider's recommendation.",
    },
    {
      question:
        "Are there potential risks or side effects associated with Testosterone Replacement Therapy?",
      answer:
        "Yes, potential side effects of TRT can include acne, mood swings, fluid retention, and changes in blood pressure. It's essential to discuss these risks with a healthcare provider before starting treatment.",
    },
    {
      question:
        "How long does it take to see results from Testosterone Replacement Therapy?",
      answer:
        "The timeline for experiencing improvements in symptoms can vary from person to person. Some individuals may notice changes within weeks, while others may take several months to see significant benefits. Regular monitoring and adjustments are part of the process.",
    },
  ]

  useEffect(() => {
    console.log(articles)
  }, [articles])

  const articles = data.allPrismicBothArticle.nodes
    .filter(article => article.lang === `en-au`)
    .filter(
      article =>
        article.uid === "low-libido-possible-causes-and-how-to-treat-it" ||
        article.uid === "is-male-menopause-a-real-thing-what-you-must-know"
    )
    .slice(0, 2)

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "trtaumenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "trtaubottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Testosterone Replacement Therapy in Australia | TRT Clinic"
        description="Explore Testosterone Replacement Therapy for improved vitality and well-being. Reclaim your energy with T therapy. Contact us today for expert device."
      />
      <JsonLd json={Schemas.localBusinessSchemaTRT(prefix)} />
      <JsonLd json={Schemas.serviceSchemaTRT(prefix)} />
      <section className="treatments__inner--container mb-60 flex flex-wrap justify-between pt-40 max1360:justify-center">
        <div className="mb-60 w-full 1330:mr-15 max480:px-25 max680:mb-85">
          <h1 className="section__title mb-20">
            Testosterone Replacement Therapy in Australia
          </h1>
        </div>
      </section>
      <section className="width__container mb-145 max680:mb-60">
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">What is TRT?</h2>
            <p className="mb-20 whitespace-pre-line">
              TRT, or{" "}
              <a
                href="https://en.wikipedia.org/wiki/Androgen_replacement_therapy"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Testosterone Replacement Therapy
              </a>
              , is a medical treatment aimed at restoring optimum testosterone
              levels in individuals experiencing testosterone deficiency.
              Testosterone, a vital hormone primarily produced in the testicles,
              plays a crucial role in various bodily functions, including muscle
              mass maintenance, bone density regulation, and sperm production.
              However, factors such as ageing, certain medical conditions, or
              lifestyle choices can lead to decreased testosterone levels,
              resulting in symptoms such as fatigue, reduced libido, and mood
              changes.
            </p>
            <p className="mb-20 whitespace-pre-line">
              TRT involves the individualised administration of testosterone
              through different methods, such as injections, patches, gels, or
              implants, tailored to suit individual needs. Replenishing
              testosterone levels to normal ranges aims to reduce symptoms
              associated with low testosterone and improve general health and
              quality of life.
            </p>
          </div>
        </div>
        <div className="mb-75 flex flex-row-reverse flex-wrap items-center justify-between max680:mb-45 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Types of Testosterone Treatment
            </h2>
            <p className="mb-20 whitespace-pre-line">
              There are several types of testosterone treatments available, each
              with its advantages and considerations. Injectable testosterone,
              administered either intramuscularly or subcutaneously, offers a
              long-lasting solution, requiring fewer administrations compared to
              other forms. Applied to the skin, transdermal patches steadily
              release testosterone throughout the day. Testosterone gels are
              another popular option, applied topically and absorbed through the
              skin, offering convenience and ease of use.
            </p>
            <p className="mb-20 whitespace-pre-line">
              It's essential to consult a competent and qualified healthcare
              professional to decide the most suitable treatment for each
              patient's needs and preferences. Factors such as lifestyle,
              existing medical conditions, and treatment goals are considered to
              ensure optimal outcomes. Regular monitoring and adjustments may be
              necessary to maintain testosterone levels within the desired range
              and to address any emerging concerns.
            </p>
            <p className="mb-20 whitespace-pre-line">
              At Men's Health Clinic, a leading testosterone replacement therapy
              clinic in Australia, we offer specialised low testosterone
              treatment to help individuals reclaim their vitality and
              well-being. Our expert clinicians work closely with clients to
              determine the best treatment plan based on medical history,
              lifestyle, and treatment goals. We ensure clients receive
              personalised care and support throughout their TRT journey,
              whether it's injections, patches, gels, or pellets.
            </p>
          </div>
        </div>
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Benefits of Tesosterone Replacement Therapy
            </h2>
            <p className="mb-20 whitespace-pre-line">
              <a
                href="https://menshealthclinic.com/au/treatments/testosterone-replacement-therapy/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Testosterone Replacement Therapy
              </a>{" "}
              offers a range of significant benefits for individuals with low
              testosterone levels. These include increased energy, improved
              libido, and enhanced muscle mass, collectively contributing to a
              more active and fulfilling lifestyle. Additionally, TRT has been
              shown to alleviate symptoms of depression and improve mood,
              promoting a sense of overall well-being and vitality.
            </p>
            <p className="mb-20 whitespace-pre-line">
              Beyond its physical benefits, TRT can also improve cognitive
              function and mental clarity. Clients often report feeling more
              focused and mentally sharp following treatment, enabling them to
              approach daily tasks with renewed vigour and confidence. At{" "}
              <a
                href="https://menshealthclinic.com/au/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Men’s Health Clinic
              </a>
              , we recognise the holistic impact of TRT and are committed to
              providing individuals with thorough assistance throughout their
              journey to improved health and vitality.
            </p>
          </div>
        </div>
      </section>
      <Treatment
        isBtn={true}
        blueText={"Our Treatments"}
        titleText={"View our range of bespoke treatment plans."}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmenttreatmentquiz",
            false,
            prefix
          )
        }
        prefix={prefix}
      />
      <OurProcess prefix={prefix} />
      <Reviews link={
        returnLink(
          "Website-Quiz",
          "Supplementary-Quiz",
          "treatmentreviewsquiz",
          false,
          prefix
        )
      } prefix={prefix} />
      <Experts
        experts={experts}
        slider={true}
        className="flex-wrap"
        prefix={prefix}
      />
      <section className="width__container mb-125 max680:mb-60">
        <div className="flex flex-wrap justify-between max1360:justify-center">
          <div className="about__process--title-box">
            <h2 className="dashed__text--blue treatments__blue--text blueText">
              Our Principles / Values
            </h2>
            <h2 className="section__title company__title">
              Our values align with yours.
            </h2>
          </div>
          {processCards.map((item, index) => (
            <ProcessCardIcon
              Component={item.img}
              titleText={item.titleText}
              text={item.text}
              key={index}
              className={index % 2 === 0 ? "1000:ml-5" : "1000:mr-5"}
            />
          ))}
        </div>

        <CustomButton
          className="orangeFilled slim__btn ml-[50%] mt-40 translate-x-[-50%] max680:mt-0"
          text="Online Assessment"
          link={
            returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "aboutvaluesquiz",
              false,
              prefix
            )
          }
        />
      </section>
      <Video blueDashedText={"Meet Dr. Fred Edwards"} prefix={prefix} />
      <Faqs
        blueText="FAQS"
        titleText="Frequently Asked Questions"
        isButton={true}
        bottomText={true}
        className="mb-100"
        prefix={prefix}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "trtfaqsquiz",
            false,
            prefix
          )
        }
        questions={questions}
      />
      <Articles
        blueText="Articles"
        titleText="Learn More About Testosterone Replacement Therapy"
        slider={true}
        buttonClass="bottom-0"
        className="mb-145 pb-75"
        navLink={`/${prefix}/resources/#resourcesArticles`}
        articles={articles}
        prefix={prefix}
      />
    </Layout>
  )
}
export default TestosteroneReplacementTherapy

export const pageQuery = graphql`
  query AuTrtQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`
