import * as React from "react"
import { Layout } from "../../components/layout"
import { returnLink } from "../../helpers/returnLink"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import BluePillBottle from "../../images/mocks/mockupPillBottle2a.webp"

const LiveChat = () => {
  return (
    <Layout displayOnlyEssentialDetails prefix={"au"}>
      <GatsbySeo />
      <div className="width__container mb-[10rem] mt-[5rem] flex flex-col 1200:flex-row">
        <div className="relative mr-[5%] h-[70vh] w-[100%] overflow-hidden 1200:h-[500px] 1200:w-[65%]">
          <iframe
            className="absolute inset-0 h-[100%] w-[100%]"
            src="https://secure.livechatinc.com/licence/14231220/open_chat.cgi"
          ></iframe>
        </div>
        <div className="900:relative 1200:w-[35%]">
          <ul className="mt-[10%] flex-1 self-start justify-self-center rounded-[8px] px-[5%] py-[10%] font-medium text-[#030654] shadow-2xl 1200:mt-[5%] 1200:py-[15%] [&>*:not(:last-child)]:mb-[2%]">
            <li>
              60,000<span className="text-FF5733">+</span> Successfully assessed
            </li>
            <li>Discreet delivery</li>
            <li>Private consultations</li>
            <li>
              100<span className="text-FF5733">+</span> 5-star Google reviews
            </li>
          </ul>
          <img
            className="mx-auto h-[300px] w-[300px] object-cover 900:absolute 900:right-[10%] 900:bottom-0 1200:left-[-20%] 1200:right-auto"
            src={BluePillBottle}
            alt="Blue Pill Bottle"
          />
        </div>
      </div>
    </Layout>
  )
}

export default LiveChat
