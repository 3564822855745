import React from "react"
import ThankYouPdf from "../../components/PageComponents/thank-you-pdf"

const ThankYouPdfPage = () => {
  const prefix = "ca"

  return <ThankYouPdf prefix={prefix} />
}

export default ThankYouPdfPage
