import * as React from "react"
import { useEffect, useState } from "react"
import { Layout } from "./layout/layout"
import { navigate } from "gatsby"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Articles } from "../../../components/Articles/articles"
import { RichText } from "prismic-reactjs"
import { HeaderMarks } from "../../../components/common/HeaderMarks"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { returnLink } from "../../../helpers/returnLink"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import PropTypes from "prop-types"

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case "paragraph":
      return (
        <p key={key} className="pb-25">
          {children}
        </p>
      )
    case "heading2":
      return (
        <p key={key} className="text-20_36 font-medium">
          {children}
        </p>
      )
    case "strong":
      return (
        <span key={key} className="font-medium">
          {children}
        </span>
      )
    case "list-item":
      return (
        <li key={key} className="cms__list--type ml-50 list-disc">
          {children}
        </li>
      )
    case "o-list-item":
      return (
        <li key={key} className="cms__list--type ml-50 list-decimal">
          {children}
        </li>
      )
    case "group-o-list-item":
      return (
        <ul key={key} className="mb-25">
          {children}
        </ul>
      )
    case "embed":
      return (
        <div className="resource__iframe--wrapper">
          <iframe
            className="resource__iframe"
            src={element.oembed.embed_url}
            key={key}
          ></iframe>
        </div>
      )
    case "img":
      return <img className="mb-25" src={element.url} />
    case "hyperlink":
      return (
        <a
          key={key}
          href={element.data.url}
          className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
        >
          {children}
        </a>
      )
    default:
      return null
  }
}

const ResourcesInner = props => {
  const [relatedArticles, setRelatedArticles] = useState([])

  let allArticles = [
    ...props.data.allPrismicBothArticle.nodes,
    ...props.data.allPrismicWebsiteArticle.nodes,
  ]

  const checkRegionLocale = (lang, prefix) => {
    switch (prefix) {
      case "au":
        return lang === `en-au`
      case "ca":
        return lang === `en-ca`
      case "nz":
        return lang === `en-nz`
      case "uk":
        return lang === `en-gb`
      case "ph":
        return lang === `en-ph`
      case "fr-ca":
        return lang === `fr-ca`
    }
  }

  let article = allArticles.filter(
    data =>
      data.uid == props.params["*"] &&
      checkRegionLocale(data.lang, props.prefix)
  )[0]

  const isBrowser = typeof window !== "undefined"

  if (isBrowser && !article) {
    navigate(`/${props.prefix}/resources`)
  }

  const { img, text, title, alt, metaTitle, metaDescription } = {
    img: article?.data.featured_image.url,
    text: article?.data.text,
    title: article?.data.title.text,
    articles: article,
    imgText: article?.tags,
    date: article?.data.date,
    alt: article?.data.featured_image.alt,
    metaTitle: article?.data?.body[0]?.primary.title.text,
    metaDescription: article?.data?.body[0]?.primary.description.text,
  }

  const findRelatedArticles = currentArticle => {
    const resourceArticles = []
    const resourceArticlesUid = []

    // Filter out current article
    const filteredArticles = allArticles.filter(
      filteredArticle => currentArticle.uid !== filteredArticle.uid
    )

    // Find 2 separate articles
    for (let i = 0; resourceArticles.length < 2; i++) {
      const articleToAdd = filteredArticles[i]

      if (!resourceArticlesUid.includes(articleToAdd.uid)) {
        resourceArticlesUid.push(articleToAdd.uid)
        resourceArticles.push(articleToAdd)
      }

      i++
    }

    setRelatedArticles(resourceArticles)
  }

  useEffect(() => {
    if (allArticles.length > 0) findRelatedArticles(article)
  }, [])

  useEffect(() => {
    if (!props || !props.params) {
      navigate(`/${props.prefix}/resources`)
    }
  }, [])

  const articleBody = text?.richText.map(item => item.text).join(" ")

  // BLOGPOSTING SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://menshealthclinic.com/${props.prefix}/resource/${props.params["*"]}`,
    },
    name: `${metaTitle}`,
    description: `${metaDescription}`,
    headline: `${title}`,
    articleBody: `${articleBody}`,
    image: {
      "@type": "ImageObject",
      url: `${img}`,
    },
    url: `https://menshealthclinic.com/${props.prefix}/resource/${props.params["*"]}`,
  }
  // BLOGPOSTING SCHEMA END //

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "blogmenuquiz",
        false,
        props.prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "blogbottomquiz",
        false,
        props.prefix
      )}
      prefix={props.prefix}
    >
      <GatsbySeo
        title={metaTitle}
        description={metaDescription}
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: `https://menshealthclinic.com/au/resource/${props.params["*"]}/`,
          },
          {
            hrefLang: "en-au",
            href: `https://menshealthclinic.com/au/resource/${props.params["*"]}/`,
          },
          {
            hrefLang: "en-ca",
            href: `https://menshealthclinic.com/ca/resource/${props.params["*"]}/`,
          },
          {
            hrefLang: "en-nz",
            href: `https://menshealthclinic.com/nz/resource/${props.params["*"]}/`,
          },
          {
            hrefLang: "en-ph",
            href: `https://menshealthclinic.com/ph/resource/${props.params["*"]}/`,
          },
          {
            hrefLang: "en-gb",
            href: `https://menshealthclinic.com/gb/resource/${props.params["*"]}/`,
          },
        ]}
        openGraph={{
          url: `https://menshealthclinic.com/${props.prefix}/resource/${props.params["*"]}`,
          title: metaTitle,
          description: metaDescription,
          images: [
            {
              url: img,
              width: 700,
              height: 450,
            },
          ],
          site_name:
            "Men's Health Clinic | Leaders in ED, PE & Chronic Pain Treatment",
        }}
      />
      <JsonLd json={schema} />
      <article className="relative mb-60 text-center 730:mb-150 900:mb-316">
        <div className="left-blue-semicircle h-420 bg-030654 pt-55">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <div className="width__container">
            <h1 className="header__image--title-full inline-block px-30">
              {title}
            </h1>
            <div className="relative">
              <img
                className="translate__left-half absolute h-300 w-full max-w-700 rounded-20 900:h-450 max500:h-215"
                src={img}
                alt={alt}
              />
            </div>
          </div>
        </div>
        <div></div>
      </article>
      <section className="width__container">
        <div className="mb-40 text-center">
          <h2 className="section__title mb-20">{title}</h2>
          {article?.data?.byliner?.richText[0]?.text && (
            <div className="flex justify-center space-x-1">
              <p>By:</p>
              <RichText
                render={article?.data?.byliner?.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          )}
          <hr className="my-2" />
        </div>
        <RichText render={text?.richText} htmlSerializer={htmlSerializer} />
      </section>

      <section className="width__container relative mb-145 overflow-hidden max680:mb-60 max1150:h-full">
        <div className="absolute left-0 top-0 w-6/12 max1150:hidden"></div>
        <div className="flex max1150:flex-wrap max1150:justify-center max1150:pt-0">
          <div className="relative ml-100 mt-100 max-w-524 pr-35 max600:px-25 max1150:ml-0 max1150:mt-0 max1150:pr-0">
            <h2 className="mb-25 text-52 font-bold text-333333 max600:text-28_45">
              Planifiez un rendez-vous GRATUIT dès aujourd'hui.
            </h2>
            <p className="mb-85 max1150:mb-40">
              Avec l'un de nos coordonnateurs de patients seniors qui peut vous
              fournir plus d'informations sur les options de traitement sur
              mesure.
            </p>
            <HeaderMarks
              className="block text-4F4F4F max1150:hidden"
              firstText="Des médecins canadiens agréés en chair et en os."
              secondText="Plus de 15 000 patients traités."
              thirdText="Expédition discrète et soins confidentiels."
            />
          </div>
          <div className="max600:pb-160 relative h-full w-full max-w-600 1150:mr-25 max600:rounded-t-20 max1150:ml-0">
            <iframe
              src={TextCopies.acuityLink(props.prefix)}
              style={{ height: "700px", width: "100%" }}
              title="appointment"
            ></iframe>
          </div>
        </div>
      </section>
      <Articles
        blueText="Articles"
        titleText="Articles connexes"
        className="mb-165 pb-80 max600:mb-125 max680:pb-65"
        buttonClass="max800:bottom-0"
        articles={relatedArticles}
        prefix={props.prefix}
      />
    </Layout>
  )
}

export default ResourcesInner

ResourcesInner.propTypes = {
  data: PropTypes.object,
  params: PropTypes.object,
  prefix: PropTypes.string,
}
