import React from "react"
import { useState, useEffect } from "react"
import Video from "../../components/PageComponents/video"
import DrEdwardsVideo from "../../videos/dr-edwards-video.mp4"

const DrEdwardsVideoPage = ({ location }) => {
  const prefix = "au"

  const [showBooking, setShowBooking] = useState(true)
  const URLParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (URLParams.has("video-only")) {
      setShowBooking(false)
    }
  }, [])

  return (
    <Video
      prefix={prefix}
      title={"Meet Dr. Fred Edwards"}
      videoSrc={DrEdwardsVideo}
      showBooking={showBooking}
    />
  )
}

export default DrEdwardsVideoPage
