import React from "react"
import TreatmentCost from "../../components/PageComponents/treatment-cost"

const TreatmentCostPage = () => {
  const prefix = "au"

  return <TreatmentCost prefix={prefix} />
}

export default TreatmentCostPage
