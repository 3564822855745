import * as React from "react"
import "./styles.scss"

type PropsType = {
  link: string
  classNames?: string
  text: string
  orangeHover: boolean
}

export const AcuityModal: React.FC<PropsType> = ({
  link,
  classNames,
  text,
  orangeHover = true,
}) => {
  React.useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://embed.acuityscheduling.com/embed/button/18563844.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <div className="group relative inline-block">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={`acuity-embed-button absolute left-0 right-0 z-20 inline-block h-full w-full opacity-0`}
      >
        {" "}
      </a>
      <span
        className={`z-10 ${
          orangeHover ? "group-hover:bg-[#e8401c]" : ""
        } transition duration-300 ${classNames}`}
      >
        {text}
      </span>
    </div>
  )
}
