import { useState, useEffect } from "react"
import { getQuizUrl } from "../helpers/typeform"

const useQuizUrl = (countryCode, location) => {
  const [quizUrl, setQuizUrl] = useState("")

  useEffect(() => {
    if (location && location.href) {
      setQuizUrl(getQuizUrl(countryCode, location.href))
    }
  }, [countryCode, location])

  return quizUrl
}

export default useQuizUrl
