import React from "react"
import { useRef } from "react"
import { Layout } from "./layout/layout"
import { returnLink } from "../../../helpers/returnLink"
import { AcuityModal } from "../../AcuityModal/AcuityModal"
import BigLinedCircleSVG from "../../../assets/homepage/bigLinedCircle.svg"
import { HeaderMarks } from "../../../components/common/HeaderMarks"
import PropTypes from "prop-types"

const Video = ({ prefix, link, title, showBooking }) => {
  const acuityLink = useRef({
    "fr-ca":
      "https://app.squarespacescheduling.com/schedule/b2bc76e9/?appointmentTypeIds%5B%5D=26999663",
  })

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "homemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "homebottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
      displayOnlyEssentialDetails={true}
    >
      <div className="video block overflow-hidden">
        <iframe
          src={`${link}?&autoplay=1&mute=1`}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="bg-030654 px-100 pt-2 max950:hidden">
        <HeaderMarks
          className="flex items-center justify-between text-F5F5F5 max950:block"
          firstText="De vrais médecins canadiens agréés."
          secondText="Plus de 15 000 patients traités."
          thirdText="Expédition discrète et soins."
        />
      </div>
      <div className="mb-85 mt-85">
        <div className="relative h-full text-center">
          <BigLinedCircleSVG className="absolute left-0 top-0" />
          <div className="mx-100 max680:mx-50">
            <h2 className="section__title relative pb-25">
              Vous êtes entre de bonnes mains pour les traitements des
              dysfonctions sexuelles.
            </h2>
            <p className="relative z-10 pb-25">
              La dysfonction érectile (DE) et l'éjaculation précoce (EP) peuvent
              affecter votre confiance, mettre à l'épreuve vos relations et ôter
              la joie de l'intimité.
            </p>
            <p className="relative z-10">
              Libérez-vous du poids de ces défis liés à la santé sexuelle et
              reprenez le contrôle de votre vie. Nos traitements hautement
              personnalisés sont soigneusement formulés pour vous aider à
              atteindre des performances optimales sans effets indésirables.
              Travaillons ensemble pour retrouver votre confiance dans la
              chambre à coucher.
            </p>
            {showBooking && (
              <div className="flex justify-center pt-60">
                <AcuityModal
                  link={acuityLink.current[prefix]}
                  text="Prendre rendez-vous"
                  classNames="cursor-pointer orangeFilled slim__btn whitespace-nowrap text-15"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Video

Video.propTypes = {
  prefix: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  showBooking: PropTypes.bool,
}
