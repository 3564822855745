import * as React from "react"
import CheckMark from "../../assets/common/checkmark.svg"
import AustralianMadeLogo from "../../images/treatments/australianMadeLogoTransparent.webp"
import GoogleWhiteLogo from "../../images/header-marks/google-white-logo.webp"
import Star from "../../assets/reviews/goldStar.svg"
import TrustpilotLogo from "../../images/header-marks/trustpilot-white.png"
import TrustpilotStars from "../../assets/reviews/trustpilot-5-stars.svg"
import CQCLogo from "../../images/homepage/cqcPurple.webp"
import PropTypes from "prop-types"

export const HeaderMarks = ({
  className,
  headerMarksText,
  firstText = "Licenced Australian doctors.",
  secondText = "150,000+ treated patients.",
  thirdText = "Discreet shipping and care.",
  fourthText,
  treatmentsPage = false,
  prefix,
  homePage = false,
}) => {
  return (
    <ul
      className={`${className ? className : "header__image-marks"
        } flex-wrap gap-y-4 text-18`}
    >
      {headerMarksText ? (
        headerMarksText.map((item, index) => (
          <li className="header__image-marks--item" key={index}>
            <CheckMark />
            <span className="pl-10">{item}</span>
          </li>
        ))
      ) : (
        <>
          <li className="header__image-marks--item">
            <CheckMark />
            <span className="pl-10">{firstText}</span>
          </li>
          <li className="header__image-marks--item">
            <CheckMark />
            <span className="pl-10">{secondText}</span>
          </li>
          <li className="header__image-marks--item">
            <CheckMark />
            <span className="pl-10">{thirdText}</span>
          </li>
          {fourthText && treatmentsPage && prefix === "au" ? (
            <li className="header__image-marks--item">
              <CheckMark />
              <span className="pl-10">{fourthText}</span>
              <img
                src={AustralianMadeLogo}
                className="ml-6 w-100 rounded-md"
                alt="Australian Made Logo"
              />
            </li>
          ) : fourthText ? (
            <li className="header__image-marks--item items-center justify-between">
              <CheckMark className={`min-w-[25px] min-h-[25px] ${prefix === "uk" ? "max530:self-start max370:mt-15 max530:mt-15" : ""} `} />
              {homePage && (
                <div className={`flex max530:flex-wrap gap-y-20 gap-x-10 w-full items-center pl-10`}>
                  <div className="flex items-center">
                    <img
                      src={GoogleWhiteLogo}
                      className="w-[25px] mr-12"
                      alt="Google White G Logo"
                      width="50"
                      height="50"
                    />
                    {[...Array(5)].map(index => (
                      <div className="mr-1 pt-10" key={index}>
                        <Star className="w-[15px]" />
                      </div>
                    ))}
                  </div>
                  <div className="w-[110px] flex flex-col items-center gap-y-2">
                    <img
                      src={TrustpilotLogo}
                      alt="Trustpilot Logo"
                    />
                    <TrustpilotStars className="w-[110px]" />
                  </div>
                  {prefix === "uk" && (
                    <div className="w-[110px] absolute top-[20px] left-20">
                      <img
                        className="w-[110px] 880:!hidden"
                        src={CQCLogo}
                        alt="CQC Logo"
                        width="110"
                        height="52"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
              )}
            </li>
          ) : null}
        </>
      )
      }
    </ul >
  )
}

HeaderMarks.propTypes = {
  className: PropTypes.string,
  headerMarksText: PropTypes.array,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  thirdText: PropTypes.string,
  fourthText: PropTypes.string,
  treatmentsPage: PropTypes.string,
  prefix: PropTypes.string,
}
