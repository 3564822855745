import React from "react"
import Home from "../components/PageComponents/index"
import { graphql } from "gatsby"

const Index = ({ data }) => {
  const prefix = "au"

  return <Home data={[...data.allPrismicBothArticle.nodes, ...data.allPrismicWebsiteArticle.nodes]} prefix={prefix} />
}

export const pageQuery = graphql`
  query IndexQuery {
   allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          description {
            richText
          }
          byliner {
            richText
          }
          date
        }
        alternate_languages {
          lang
        }
        lang
        tags
        uid
        first_publication_date
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          description {
            richText
          }
          byliner {
            richText
          }
          date
        }
        alternate_languages {
          lang
        }
        lang
        tags
        uid
        first_publication_date
      }
    }
  }
`
export default Index
