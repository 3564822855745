import React from "react"
import ThankYou from "../../components/PageComponents/fr-ca/thank-you"

const ThankYouPage = ({ location }) => {
  const prefix = "fr-ca"

  return <ThankYou prefix={prefix} location={location} />
}

export default ThankYouPage
