import * as React from "react"
import { Layout } from "../../components/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Treatment } from "../../components/Treatment/treatment"
import { OurProcess } from "../../components/OurProcess/ourProcess"
import { AssessmentQuiz } from "../../components/AssessmentQuiz/assessmentQuiz"
import { Faqs } from "../../components/Faqs/faqs"
import { TextCopies } from "../../helpers/countryTextCopies"
import { HeaderMarks } from "../../components/common/HeaderMarks"
import { returnLink } from "../../helpers/returnLink"
import HeaderRightSvg from "../../assets/header/headerRight.svg"
import PropTypes from "prop-types"
import { Schemas } from "../../schemas/schemas"

const Treatments = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Treatments",
    description:
      "Restoring confidence & quality of life. Bespoke treatment plans available that are suitable to your needs.",
    url: `https://menshealthclinic.com/${prefix}/treatments`,
  }
  // WEBPAGE SCHEMA END //

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "treatmentmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "treatmentbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title={
          prefix === "au"
            ? "Treatment Plans for ED, PE & Chronic Pain | Men's Health Clinic"
            : prefix === "uk"
              ? "Leaders in ED & PE Treatment | MHC Men's Health Clinic"
              : "Treatment Plans for ED & PE | Men's Health Clinic"
        }
        description={
          prefix === "au"
            ? "Men's Health Clinic offers customised PE, ED and chronic pain treatment plans, with expert licensed doctors. Get a free assessment today."
            : "Men's Health Clinic offers customised PE and ED treatment plans, with expert licensed doctors. Get a free assessment today."
        }
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/treatments/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/treatments/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/treatments/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/treatments/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/treatments/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/treatments/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <JsonLd json={Schemas.medicalBusiness(prefix)} />
      <JsonLd json={Schemas.organization(prefix)} />
      <JsonLd json={Schemas.webSite(prefix)} />

      <article className="left-blue-semicircle relative mb-145 h-full min-h-420 bg-030654 pb-75 pt-80 max680:mb-60 max680:pb-60 max680:pt-60 max1000:h-auto">
        <HeaderRightSvg className="absolute bottom-0 right-0" />
        <div className="width__container flex items-center justify-between 1200:mb-60 max1200:flex-wrap">
          <div>
            <p className="header__image--dash-text">
              {prefix === "uk" ? "MHC" : ""} Men’s Health Clinic
            </p>
            <h1 className="header__image--title">
              Restoring confidence & quality of life.
            </h1>
          </div>
          <div>
            <p className="header__image--text w-full max-w-md pb-25">
              Bespoke treatment plans available that are suitable to your needs.
            </p>
          </div>
        </div>
        <HeaderMarks
          className="width__container flex justify-between text-F5F5F5 max950:block"
          firstText={`Licenced ${TextCopies.countryAsAdjective(prefix)} doctors.`}
          fourthText={prefix === "au" ? "Australian Made." : null}
          treatmentsPage={true}
          prefix={prefix}
        />
      </article>
      <main className="mb-180 max680:mb-115">
        <Treatment
          isBtn={true}
          blueText={"Our Treatments"}
          titleText={"View our range of bespoke treatment plans."}
          link={
            returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "treatmenttreatmentquiz",
              false,
              prefix
            )
          }
          prefix={prefix}
        />
        <OurProcess prefix={prefix} />
        <AssessmentQuiz
          source="Website-Quiz"
          formCompleted="Supplementary-Quiz"
          prefix={prefix}
          formUrl="treatmentselfassessquiz"
          isBtn={false}
          blueText="Self-Assessment Quiz"
          titleText="Not sure which condition you may have? Take one of our assessments!"
          link={returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "homeselfassessquiz",
            false,
            prefix
          )}
        />
        <Faqs
          blueText="FAQS"
          titleText="Frequently Asked Questions"
          bottomText={true}
          link={returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmentfaqquiz",
            false,
            prefix
          )}
          prefix={prefix}
        />
      </main>
    </Layout>
  )
}
export default Treatments

Treatments.propTypes = {
  prefix: PropTypes.string,
}
