import * as React from "react"
import ExternalRedirect from "../../components/PageComponents/fr-ca/externalRedirect"

const EDInfoPackCA = () => {
  return (
    <ExternalRedirect link="https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/ED%20Info%20Pack%20-%20CA.pdf" />
  )
}

export default EDInfoPackCA
