import * as React from "react"
import { Layout } from "../../components/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { returnLink } from "../../helpers/returnLink"
import HeaderRightSvg from "../../assets/header/headerRight.svg"

const ComplaintsPolicy = () => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Complaints Policy",
    description: "Learn more about our Complaints Policy",
    url: `https://menshealthclinic.com/nz/complaints-policy`,
  }
  // WEBPAGE SCHEMA END //
  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "tandcmenuquiz",
        false,
        "nz"
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "tandcbottomquiz",
        false,
        "nz"
      )}
      prefix="nz"
    >
      <GatsbySeo />
      <JsonLd json={schema} />
      <article className="relative mb-85 overflow-hidden max680:mb-60">
        <div className="left-blue-semicircle relative h-full min-h-420 overflow-hidden bg-030654 px-25 text-center max680:min-h-320">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="header__image--title relative inline-block pt-125 max680:pt-60">
            Complaints Policy
          </h1>
          <p className="relative m-auto w-full max-w-500 pb-25 text-F5F5F5">
            Learn more about our Complaints Policy.
          </p>
        </div>
      </article>
      <main>
        <section className="width__container mb-165 flex justify-center">
          <div>
            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">
                FEEDBACK AND COMPLAINTS POLICY
              </h2>
              <p>PLEASE READ CAREFULLY BEFORE USING OUR SERVICE OR THIS SITE</p>
            </div>

            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">POLICY</h2>
              <p className="mb-10">
                Men’s Health Clinic aims to provide a high quality, professional
                service for all clients at all times. Your feedback helps us to
                improve our service. As part of this service, we also
                acknowledge you have a right to complain as outlined within the
                Health & Disability Commissioner’s Code of Rights and the Health
                Information Privacy Code. This policy is intended to ensure that
                your feedback or complaint is handled in a manner that is fast,
                simple and fair.
              </p>
            </div>

            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">
                HOW TO GIVE FEEDBACK OR MAKE A COMPLAINT
              </h2>
              <p className="mb-10">
                We welcome your feedback and suggestions to help us to improve
                our service to you. We want to know what we are doing well and
                where we can improve. You can contact us with suggestions,
                compliments or complaints using the following details:
              </p>
              <ul className="mb-50 hidden pl-30 text-16 sm:block">
                <li className="list-disc">
                  Email address: info@menshealthclinic.co.nz
                </li>
                <li className="list-disc">Telephone number: 0800807744</li>
              </ul>
              <p className="mb-10">
                It is important to note that our doctors and pharmacists are
                independent practitioners and are responsible for their own
                clinical practice and conduct. If your complaint is about the
                services or care provided by your doctor or pharmacist, we may
                pass your complaint on to them to respond to directly.
              </p>
            </div>

            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">
                WHEN WE RECIEVE A COMPLAINT
              </h2>
              <p className="mb-10">If you make a complaint, we will:</p>
              <ul className="mb-50 hidden pl-30 text-16 sm:block">
                <li className="list-disc">
                  acknowledge the complaint in writing within five working days
                  of receipt, unless the complaint has been resolved to your
                  satisfaction within that time;
                </li>
                <li className="list-disc">
                  document your complaint and all of the actions that our staff
                  take in relation to your complaint;
                </li>
                <li className="list-disc">
                  give you any information that we hold that is, or may be,
                  relevant to your complaint; and
                </li>
                <li className="list-disc">
                  provide an update to you on the progress of your complaint
                  each month, until the complaint has been resolved.
                </li>
              </ul>
            </div>

            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">
                MAKING DECISIONS ON COMPLAINTS
              </h2>
              <p className="mb-10">
                Within ten working days of acknowledging your complaint in
                writing, we will:
              </p>
              <ul className="mb-50 hidden pl-30 text-16 sm:block">
                <li className="list-disc">
                  decide whether or not we accept that your complaint is
                  justified;
                </li>
                <li className="list-disc">
                  decide whether more time is needed to investigate your
                  complaint; and
                </li>
                <li className="list-disc">
                  if more than 20 working days will be needed to investigate
                  your complaint, advise you that we need more time to make a
                  decision and provide the reasons why more time is needed.
                </li>
              </ul>
            </div>

            <div className="mb-30 w-full max-w-700 sm:mb-115">
              <h2 className="section__title mb-20">WHAT HAPPENS NEXT?</h2>
              <p className="mb-10">
                After we decide whether or not we accept that your complaint is
                justified, we will inform you of:
              </p>
              <ul className="mb-50 hidden pl-30 text-16 sm:block">
                <li className="list-disc">
                  our decision to either accept or not accept that your
                  complaint is justified;
                </li>
                <li className="list-disc">the reasons for our decision;</li>
                <li className="list-disc">
                  any actions that we propose to take; and
                </li>
              </ul>
              <p className="mb-10">
                If you are unhappy with the outcome, we will advise you how to
                complain to the Health and Disability Commissioner or the
                Privacy Commissioner.
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default ComplaintsPolicy
