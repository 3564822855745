import React from "react"
import { useState, useEffect } from "react"
import Video from "../../components/PageComponents/fr-ca/video"

const DrEdwardsVideoPage = ({ location }) => {
  const prefix = "fr-ca"

  const [showBooking, setShowBooking] = useState(true)
  const URLParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (URLParams.has("video-only")) {
      setShowBooking(false)
    }
  }, [])

  return (
    <Video
      prefix={prefix}
      title={"Rencontrez le Dr Fred Edwards"}
      link="https://www.youtube.com/embed/5lfI4rsCBRg?si=scd2AEJv284du4bn"
      showBooking={showBooking}
    />
  )
}

export default DrEdwardsVideoPage
