import * as React from "react"
import TreatmentsInner from "./treatmentsInner"
import { returnLink } from "../../helpers/returnLink"
import { experts } from "../../helpers/countryTextCopies"
import { Schemas } from "../../schemas/schemas"
import {
  EDTextComponents,
  stories,
} from "../TextComponents/erectile-dysfunction"
import HeaderImg from "../../images/erectile-dysfunction/edHeader.webp"
import About1 from "../../images/erectile-dysfunction/edImg1.webp"
import About2 from "../../images/erectile-dysfunction/edImg2.webp"
import NZAbout1 from "../../images/nz/edImg1.webp"
import PropTypes from "prop-types"

const faqLdJSON = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is ED?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "ED is an acronym of Erectile Dysfunction. Erectile Dysfunction is the inability to gain or maintain an erection during sex.",
      },
    },
    {
      "@type": "Question",
      name: "Should I speak to a doctor about ED?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Research shows, that not treating Erectile Dysfunction may lead to further complications and impotency. If you’re consistently struggling to achieve or maintain an erection over a period of three months or longer, you may have Erectile Dysfunction.",
      },
    },
    {
      "@type": "Question",
      name: "How do I get my ED treatment?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "If you’re consistently struggling to achieve or maintain an erection over a period of three months or longer, you may have Erectile Dysfunction. Call us on +61 1300 959 733 or website our website to book a phone consultation today.",
      },
    },
    {
      "@type": "Question",
      name: "How much does ED treatment cost?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Erectile Dysfunction treatment is generally affordable. You can find out more about accessing affordable treatment choices for Erectile Dysfunction with Men’s Health Clinic. All our treatment plans are completely personalised to your needs, as they are based on the seriousness of your condition and the suspected causes behind it.",
      },
    },
    {
      "@type": "Question",
      name: "What treatment is available for ED?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Men’s Health Clinic offers a variety of bespoke treatment plans tailored to one’s individual needs. No two men are the same; rather than following a generic approach to men’s healthcare, we believe that tailored solutions create the opportunity of higher treatment success as it focuses on your journey entirely.\nTreatments include a custom formulation based on your medical history and lifestyle habits. Furthermore, exercises, dietary advice from our clinical nutritionist, and self-administered injections, may be prescribed depending on your requirements. Our highly experienced doctor will provide instructions on how to follow treatment. Private counselling is available to help patients deal with psychological challenges, and monthly support is also available for patients with the help of our dedicated Performance Managers; who’s goal it is to ensure patients are happy with treatment.",
      },
    },
    {
      "@type": "Question",
      name: "How is ED diagnosed?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "To get an ED diagnosis, you will need to speak with a licensed medical practitioner like the ones we have at Men’s Health Clinic. In this discreet consultation, you may be asked to share information about your medical history, lifestyle habits, the erectile dysfunction symptoms you are experiencing, as well as your sexual history.\nOur doctors will then evaluate your information, and if you’re a suitable candidate for our treatment, they will develop a customised treatment plan tailored to your needs.",
      },
    },
    {
      "@type": "Question",
      name: "What causes ED?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "There are many different causes of ED, but it often occurs when the nerves in the penis have been restricted or there is too little blood flow.\nErectile dysfunction can be categorised into two categories: physiological and psychological. Physical causes of ED include but is not limited to diabetes, age, high blood pressure, obesity, high cholesterol, medicinal induced ED. Psychological causes of ED may include mental health conditions, stress, anxiety, relationship breakdown.",
      },
    },
  ],
}

const videoLdJSON = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Meet Dr. Fred Edwards",
  description:
    "Take a look into the world of the Men's Health Clinic, an established telehealth clinic with over 10 years of experience, a team of 150+ members, and a track record of treating 150,000 men worldwide.",
  thumbnailUrl: [
    "https://i.vimeocdn.com/filter/overlay?src0=https%3a%2f%2fi.vimeocdn.com%2fvideo%2f1390952176-93a902607d3811d11a66de5828ce204fa39a69119d3abbe36d8cc602a2eb6193-d_1280x720&src1=https%3a%2f%2ff.vimeocdn.com%2fimages_v6%2fshare%2fplay_icon_overlay.png",
  ],
  uploadDate: "2022-08-24T08:00:00+08:00",
  contentUrl:
    "https://menshealthclinic.com/au/treatments/premature-ejaculation",
  embedUrl:
    "https://player.vimeo.com/video/635035287?h=1479562035&title=0&byline=0&portrait=0",
  potentialAction: {
    "@type": "SeekToAction",
    target:
      "https://menshealthclinic.com/au/treatments/premature-ejaculation={seek_to_second_number}",
    "startOffset-input": "required name=seek_to_second_number",
  },
}

const ErectileDysfunction = ({ data, prefix }) => {
  const articles = data.nodes
    .filter(article => article.tags.includes("Erectile Dysfunction"))
    .filter(article => article.lang === `en-${prefix === "uk" ? "gb" : prefix}`)
    .slice(0, 2)

  const localBusinessSchema = Schemas.localBusinessSchemaED(prefix)
  const serviceSchema = Schemas.serviceSchemaED(prefix)

  const aboutTreatmentImg = React.useMemo(() => {
    switch (prefix) {
      case "nz":
        return NZAbout1
      default:
        return About1
    }
  }, [prefix])

  const EDQuizLink = React.useMemo(() => {
    switch (prefix) {
      default:
        return `https://app.menshealthclinic.com/assessment/quiz?category_id=1&source=website-quiz&formcompleted=embedded-quiz&prefix=${prefix}&formurl=?edwebquiz`
    }
  }, [prefix])

  let EDTitle = "Personalised Erectile Dysfunction Treatment"
  if (prefix === "ca") {
    EDTitle = "Erectile Dysfunction Canada - Treatment & Care"
  } else if (prefix === "nz") {
    EDTitle = "Effective Erectile Dysfunction Treatment in NZ"
  } else if (prefix === "uk") {
    EDTitle = "Erectile Dysfunction Treatment in United Kingdom"
  }

  const returnMetaInfo = prefix => {
    switch (prefix) {
      case "au":
        return {
          title: "Effective ED Treatment Australia  |  Men's Health Clinic",
          description:
            "Explore proven ED treatment options in Australia. Get expert guidance and personalised solutions for erectile dysfunction. Regain your confidence with us.",
        }
      case "ca":
        return {
          title: "Erectile Dysfunction Canada - Expert Treatment & Support",
          description:
            "Explore treatments and support for erectile dysfunction in Canada. Trust our experts for expert advice, medications, and support .Regain confidence with us.",
        }
      case "nz":
        return {
          title:
            "Erectile Dysfunction Treatment NZ | Advanced & Tailored Solutions",
          description:
            "Discover leading Erectile Dysfunction Treatment in NZ. Our experts provide effective solutions for ED, restoring your confidence and intimacy. Learn more.",
        }
      case "uk":
        return {
          title:
            "Erectile Dysfunction Treatment | Effective ED Solutions in UK",
          description:
            "Discover effective erectile dysfunction treatments and solutions. Get expert advice on ED causes, symptoms, and the latest medical and lifestyle options.",
        }
    }
  }

  return (
    <TreatmentsInner
      prefix={prefix}
      schema={localBusinessSchema}
      schema2={serviceSchema}
      mainTitle="MEN'S HEALTH CLINIC"
      title={EDTitle}
      seoTitle={returnMetaInfo(prefix).title}
      seoDescription={returnMetaInfo(prefix).description}
      slug="erectile-dysfunction"
      faqSeoLdJSON={faqLdJSON}
      videoSeoLdJSON={videoLdJSON}
      text="Perform better and restore the confidence you deserve."
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "edmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "edbottomquiz",
        false,
        prefix
      )}
      headerBannerCTAlink={
        returnLink(
          "Website-Quiz",
          "Banner-Quiz",
          "edbannerquiz",
          false,
          prefix
        )
      }
      headerBannerImg={HeaderImg}
      headerBannerImgAlt="Erectile Dysfunction Solutions"
      quizTitle="Men's Health Clinic has been helping men with Erectile Dysfunction treatment for over a decade."
      quizText={EDTextComponents.returnQuizText(prefix)}
      aboutTreatmentImg={aboutTreatmentImg}
      aboutTreatmentImgAlt="Happy Couple Holding Hands while Running on Beach - Men's Health Clinic"
      aboutTreatmentImgSecond={About2}
      aboutTreatmentImgSecondAlt="Close Up of Happy Couple After Long Lasting Sex - Men's Health Clinic"
      processQuizLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edprocessquiz",
        false,
        prefix
      )}
      faqsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edfaqquiz",
        false,
        prefix
      )}
      reviewsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edreviewsquiz",
        false,
        prefix
      )}
      aboutLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "edsymptoms",
        false,
        prefix
      )}
      assessmentQuizBtnLink={`/${prefix}/treatment-cost`}
      iframeLink={EDQuizLink}
      iframeSizeClass="edIframeSize"
      questions={EDTextComponents.ED_Questions(prefix)}
      headerMarksText={EDTextComponents.headerMarksText(prefix)}
      embeddedQuizHeaderText={
        <>
          <strong className="text-16 font-bold">150,000+ MEN</strong>{" "}
          successfully assessed with our FREE 2 minute, symptom checker quiz.
        </>
      }
      experts={experts}
      patientsStories={stories}
      blueDashedText="Meet Dr. Fred Edwards"
      firstDashedTitle="About Our Treatment"
      aboutTreatmentTitle={`Professional ED Treatment at ${prefix === "uk" ? "MHC" : ""
        } Men's Health Clinic.`}
      secondDashedTitle="Erectile Dysfunction (ED)"
      aboutTreatmentTitleSecond="What is Erectile Dysfunction?"
      aboutTreatmentTitleThird="What Causes Erectile Dysfunction?"
      articles={articles}
      page="ed"
    />
  )
}
export default ErectileDysfunction

ErectileDysfunction.propTypes = {
  prefix: PropTypes.string,
}
