import React from "react"
import ThankYou from "../../components/PageComponents/thank-you"

const ThankYouPage = ({ location }) => {
  const prefix = "uk"

  return (
    <ThankYou 
      prefix={prefix} 
      location={location} 
      link="https://www.youtube.com/embed/JfWJgfbY6HY?si=S1Dqb_YHiz2cbCLY" 
    />
  )
}

export default ThankYouPage
