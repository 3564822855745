import React from "react"
import PrematureEjaculation from "../../../components/PageComponents/premature-ejaculation"
import { graphql } from "gatsby"

const PrematureEjaculationPage = ({ data }) => {
  const prefix = "au"

  return <PrematureEjaculation data={data.allPrismicWebsiteArticle} prefix={prefix} />
}

export const pageQuery = graphql`
  query AuPeQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`

export default PrematureEjaculationPage
