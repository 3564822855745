import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { CustomButton } from "../../../components/common/CustomButton"
import { returnLink } from "../../../helpers/returnLink"
import CallImg from "../../../images/thank-you/thankYouCall.webp"
import AssessmentImg from "../../../images/thank-you/processAssessment.webp"
import WhatsAppImg from "../../../images/thank-you/whatsappIcon.webp"
import EDIcon from "../../../images/download-ebook/downloadEbook1.webp"
import PEIcon from "../../../images/download-ebook/downloadEbook2.webp"
import CallWavy from "../../../assets/thank-you/thankYouWavy.svg"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"

const arr = [
  {
    title: "Paquet d'informations sur la dysfonction érectile (DE)",
    text: "Informez-vous davantage sur la dysfonction érectile (DE) en tant que condition et sur les solutions de traitement disponibles.",
    link: "https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/MHC+-+ED+Info+Pack+-+CA.pdf",
    img: EDIcon,
  },
  {
    title: "Paquet d'informations sur l'éjaculation précoce (EP)",
    text: "Découvrez comment prolonger la durée et les options de traitement personnalisées disponibles pour l'éjaculation précoce (EP).",
    link: "https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/MHC+-+PE+Info+Pack+-+CA.pdf",
    img: PEIcon,
  },
]

const ThankYouDownload = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Merci pour le téléchargement de votre PDF.",
    description:
      "Félicitations pour avoir franchi la première étape vers l'acquisition de la confiance que vous méritez. Nous avons aidé des milliers d'hommes dans le monde entier à surmonter ce défi complexe avec des traitements médicaux abordables et personnalisés.",
    url: `https://menshealthclinic.com/${prefix}/thank-you-pdf`,
  }
  // WEBPAGE SCHEMA END //

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "thankyoumenuquizpdf",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "thankyoubottomquizpdf",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Men's Health Clinic | Leaders dans le traitement de la dysfonction érectile (DE) et de l'éjaculation précoce (EP)."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/thank-you-pdf/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/thank-you-pdf/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/thank-you-pdf/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/thank-you-pdf/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/thank-you-pdf/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/thank-you-pdf/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/thank-you-pdf/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article>
        <div className="left-blue-semicircle left-blue-semicircle relative mb-100 h-full min-h-420 overflow-hidden bg-030654 pb-175 text-center max680:min-h-320">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="header__image--title inline-block pt-80 max680:pt-60">
            Merci pour votre téléchargement
          </h1>
          <p className="m-auto w-full max-w-500 text-F5F5F5">
            Quelle est la prochaine étape ?
          </p>
        </div>
        <div className="width__container mb-75 mt-min225 flex justify-between max680:mb-60 max1160:block">
          {arr.map((item, index) => {
            return (
              <div
                className="border-with-radius relative mx-5 mt-30 flex w-full max-w-360 flex-col items-center bg-F5F5F5 pb-35 pt-50 text-center max400:px-25 max1160:mx-auto max1160:mb-20"
                key={index}
              >
                <div className="flex w-full max-w-140 justify-center">
                  <img src={item.img} alt="img" />
                </div>
                <h3 className="pt-20">{item.title}</h3>
                <p className="w-full max-w-290 border-solid border-BDBDBD pb-20 pt-5">
                  {item.text}
                </p>

                <CustomButton
                  className="orangeFilled slim__btn w-full max-w-290"
                  text="Téléchargement"
                  link={item.link}
                />
              </div>
            )
          })}
        </div>
        <div className="width__container mb-75 max680:mb-60">
          <p className="mx-auto mb-20 w-full max-w-1000 text-center">
            Félicitations pour avoir franchi la première étape vers
            l'acquisition de la confiance que vous méritez. Nous avons aidé des
            milliers d'hommes dans le monde entier à surmonter ce défi complexe
            avec des traitements médicaux abordables et personnalisés.
          </p>
          <p className="mx-auto w-full max-w-1000 text-center">
            Rencontrer des défis liés à votre santé n'est rien dont il faut
            avoir honte. La plupart des hommes ne rechercheront jamais de
            traitement et souffriront en silence. Prenez le contrôle de votre
            santé et passez à l'étape suivante dès maintenant.
          </p>
        </div>
      </article>
      <main>
        <section className="relative mb-145 flex max680:mb-65 max1200:flex-wrap-reverse">
          <div className="left-top__blue-circle relative h-730 w-full bg-030654 max730:h-400 max1200:max-w-100%">
            <CallWavy className="absolute right-0 top-0" />
            <img
              className="thank-you__call-now-img absolute right-min60 top-200 z-10 rounded-20 max730:top-100 max730:h-310 max730:w-310"
              src={CallImg}
            />
          </div>
          <div className="w-full bg-E0E0E0 px-15 1200:pl-75 max1200:pb-50">
            <div className="mt-75 flex w-full max-w-500 items-center px-25 max600:max-w-240 max600:flex-wrap max600:justify-center max600:px-0 max1200:mx-auto max1200:mt-50">
              <img
                className="w-85 600:w-115 600:pr-25 max600:mb-20"
                src={AssessmentImg}
              />
              <div className="w-full max-w-350">
                <p className="mb-10 text-center text-20_36 font-bold">
                  Appelez maintenant
                </p>
                <ul className="mb-40 pl-25">
                  <li className="list-disc">
                    Heures d'ouverture de la clinique : 9h00 à 20h00.
                  </li>
                  <li className="list-disc">
                    Consultation GRATUITE avec un Coordinateur Principal des
                    Patients.
                  </li>
                  <li className="list-disc">Évaluation sans obligation</li>
                </ul>
                <CustomButton
                  className="orangeFilled slim__btn w-full max-w-290 text-center"
                  text="Passer un appel"
                  link={`tel:${TextCopies.countryPhone(prefix)}`}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="width__container mb-145 text-center max680:mb-60">
          <p className="blueText process__blueText">RÉSERVEZ MAINTENANT</p>
          <h2 className="section__title process__section--title-box">
            Réservez votre évaluation GRATUITE
          </h2>
          <div className="h-full w-full">
            <iframe
              src={TextCopies.acuityLink(prefix)}
              style={{ height: "630px", width: "100%" }}
              title="book"
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default ThankYouDownload
