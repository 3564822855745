import * as React from "react"
import Div100vh from "react-div-100vh"
import PropTypes from "prop-types"

const PdfViewer = ({ pdfLink }) => {
  return (
    <Div100vh>
      <object data={pdfLink} type="application/pdf" width="100%" height="100%">
        <iframe
          type="application/pdf"
          src={`https://docs.google.com/gview?url=${pdfLink}&embedded=true`}
          width="100%"
          height="100%"
          scrolling="auto"
          frameBorder="0"
        ></iframe>
      </object>
    </Div100vh>
  )
}

export default PdfViewer

PdfViewer.propTypes = {
  pdfLink: PropTypes.string,
}
