import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useQuizUrl from "../../hooks/useQuizUrl"

const QuizPage = ({ location }) => {
  const quizUrl = useQuizUrl("AU", location)

  return (
    <>
      <GatsbySeo
        title="Take the Erectile Dysfunction & Premature Ejaculation Treatment Quiz"
        description="Looking for treatment for ED or PD in Australia? Take our erectile dysfunction and premature ejaculation quiz now."
      />

      <iframe
        src={quizUrl}
        title="Men's Health Clinic Typeform Quiz"
        width="100%"
        style={{ height: "100vh" }}
      ></iframe>
    </>
  )
}

export default QuizPage
