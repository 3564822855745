import React from "react"
import ErectileDysfunction from "../../../components/PageComponents/erectile-dysfunction"
import { graphql } from "gatsby"

const ErectileDysfunctionPage = ({ data }) => {
  const prefix = "au"

  return <ErectileDysfunction data={data.allPrismicWebsiteArticle} prefix={prefix} />
}

export const pageQuery = graphql`
  query AuEdQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`

export default ErectileDysfunctionPage
