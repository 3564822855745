import * as React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import ExternalRedirect from "../../components/PageComponents/externalRedirect"

const EDInfoPackCA = () => {
  return (
    <>
      <GatsbySeo
        title="Men's Health Clinic | Download ED Treatment Information"
        description="Men's Health Clinic helps treat erectile dysfunction in Canada. Download our Information Pack ED treatment now."
      />
      <ExternalRedirect link="https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/ED%20Info%20Pack%20-%20CA.pdf" />
    </>
  )
}

export default EDInfoPackCA
