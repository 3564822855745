import React from "react"
import Chronicpain from "../../../components/PageComponents/chronic-pain"
import { graphql } from "gatsby"

const ChronicPain = ({ data }) => {
  const prefix = "au"

  return <Chronicpain data={[...data.allPrismicBothArticle.nodes, ...data.allPrismicWebsiteArticle.nodes]} prefix={prefix} />
}

export const pageQuery = graphql`
  query AuCpQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`

export default ChronicPain
