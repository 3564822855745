import React from "react"
import DownloadEbook from "../../components/PageComponents/fr-ca/download-ebook"

const DownloadEbookPage = () => {
  const prefix = "fr-ca"

  return <DownloadEbook prefix={prefix} />
}

export default DownloadEbookPage
