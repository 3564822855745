import { getUrlParams } from "./urlParams"

export const BASE_URL = "https://mhcquiz.typeform.com/to/"

export const TYPEFORM_QUIZ_ID = {
  AU: "Y409seB9",
  PH: "Oqgo0Gyb",
  NZ: "QQkeR0Jm",
  UK: "Vke0BkzI",
  CA: "UsxkM3U9",
}

export function getQuizUrl(countryCode, url = "") {
  const quizId = TYPEFORM_QUIZ_ID[countryCode]

  return `${BASE_URL}${quizId}${getUtmParamString(url)}`
}

export function getUtmParamString(url) {
  if (!url) {
    return ""
  }

  const params = getUrlParams(url)
  const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term"]

  const paramString = utmParams
    .filter(param => params[param])
    .map(param => `${param}=${params[param]}`)
    .join("&")

  return paramString ? `?${paramString}` : ""
}
