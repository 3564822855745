import React from "react"
import { useState, useEffect, useRef } from "react"
import { Layout } from "../layout"
import { returnLink } from "../../helpers/returnLink"
import { AcuityModal } from "../AcuityModal/AcuityModal"
import PropTypes from "prop-types"

const Video = ({ prefix, videoSrc, title, showBooking }) => {
  const acuityLink = useRef({
    au: "https://app.acuityscheduling.com/schedule.php?owner=18563844&calendarID=3386645",
    ca: "https://app.acuityscheduling.com/schedule.php?owner=24056947&calendarID=6108046",
    uk: "https://app.squarespacescheduling.com/schedule/713fa596/appointment/33136276/calendar/6812959?appointmentTypeIds%5B%5D=33136276",
    nz: "https://app.acuityscheduling.com/schedule.php?owner=21987254&appointmentType=20305939",
  })

  const [ready, setReady] = useState(false)
  useEffect(() => {
    setReady(true)
  }, [])

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "homemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "homebottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
      displayOnlyEssentialDetails={false}
    >
      {ready && (
        <div className="width__container mt-60 mb-150 flex items-center justify-between max1300:flex-wrap max1300:justify-center">
          <div className="order-2 ml-50 flex w-full max-w-500 flex-col gap-20 max480:mb-10 max1300:order-1 max1300:ml-0 max1300:mt-30 max1300:max-w-700 max1300:flex-col-reverse max1300:items-center max1300:text-center">
            <div className="flex flex-col gap-20">
              <h1 className="section__title">
                You’re in expert hands for sexual dysfunction treatments.
              </h1>
              <p>
                Erectile Dysfunction (ED) and Premature Ejaculation (PE) can
                affect your confidence, strain your relationships, and take away
                the joy of intimacy.
              </p>
              <p>
                Free yourself from the weight of these sexual health challenges
                and reclaim control over your life. Our highly personalised
                treatments are carefully formulated to help you achieve optimal
                performance without unwanted side effects. Let’s work together
                to regain your confidence in the bedroom.
              </p>
            </div>
            {showBooking ? (
              <div className="flex justify-center">
                <AcuityModal
                  link={acuityLink.current[prefix]}
                  text="Schedule an appointment"
                  classNames="cursor-pointer orangeFilled slim__btn whitespace-nowrap text-15"
                />
              </div>
            ) : null}
          </div>

          <div className="w-full max-w-700">
            <h2 className="mb-12 text-center text-xl font-medium">{title}</h2>
            <div className="video block overflow-hidden rounded-20">
              <video
                autoPlay
                muted
                controls
                playsInline
                height={370}
                width={660}
                className="rounded-lg"
              >
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Video

Video.propTypes = {
  prefix: PropTypes.string,
  videoSrc: PropTypes.string,
  title: PropTypes.string,
  showBooking: PropTypes.bool,
}
