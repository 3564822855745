import React from 'react'
import Authors from '../../../components/PageComponents/authors'

const AuthorsPage = () => {
  const prefix = "au"

  return (
    <Authors prefix={prefix} />
  )
}

export default AuthorsPage