import * as React from "react"
import Div100vh from "react-div-100vh"

const WelcomeVideoUK = () => {
  return (
    <Div100vh>
      <iframe
        src={`https://player.vimeo.com/video/857744866?h=9cb0a66b1b&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=true`}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      ></iframe>
    </Div100vh>
  )
}

export default WelcomeVideoUK
