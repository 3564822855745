import * as React from "react"
import PdfViewer from "../components/PageComponents/pdfViewer"

const FitnessPlan = () => {
  return (
    <PdfViewer pdfLink="https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/MHC+Fitness+Plan.pdf" />
  )
}

export default FitnessPlan
