import React from "react"
import ContactUs from "../../components/PageComponents/contact-us"

const ContactUsPage = () => {
  const prefix = "uk"

  return <ContactUs prefix={prefix} />
}

export default ContactUsPage
