import * as React from "react"
import ExternalRedirect from "../../components/PageComponents/fr-ca/externalRedirect"

const PEInfoPackCA = () => {
  return (
    <ExternalRedirect link="https://docs.google.com/gview?url=https://mhc-web-public.s3.ap-southeast-2.amazonaws.com/PE+Info+Pack+-+CA.pdf" />
  )
}

export default PEInfoPackCA
