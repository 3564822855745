import * as React from "react"

export const Checkbox = ({
  className,
  text,
  isChecked,
  setIsChecked,
  setSubmitData,
  item,
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <span
        className="inline-block cursor-pointer"
        onClick={() => {
          if (item) {
            if (item === isChecked) {
              setIsChecked("")
              return
            }
            setIsChecked(item)
            setSubmitData(prev => {
              return { ...prev, condition: item }
            })
            return
          }
          setIsChecked(!isChecked)
        }}
      >
        <span className="mr-15 flex h-20 w-20 items-center justify-center rounded-50 border border-solid border-BDBDBD">
          {item
            ? item === isChecked && (
                <span className="inline-block h-12 w-12 rounded-50 bg-FF5733"></span>
              )
            : isChecked && (
                <span className="inline-block h-12 w-12 rounded-50 bg-FF5733"></span>
              )}
        </span>
      </span>
      <span className="text-12 text-333333">{text}</span>
    </div>
  )
}
