import * as React from "react"
import TreatmentsInner from "./treatmentsInner"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { PETextComponents } from "../../TextComponents/premature-ejaculation"
import { returnLink } from "../../../helpers/returnLink"
import HeaderImg from "../../../images/premature-ejaculation/peHeader.webp"
import About1 from "../../../images/premature-ejaculation/peImg1.webp"
import About2 from "../../../images/premature-ejaculation/peImg2.webp"

const faqLdJSON = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Comment la Men's Health Clinic peut-elle aider?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "La Men's Health Clinic est une clinique de télésanté en ligne qui dessert des milliers d'hommes à travers l'Australie. Notre équipe de médecins agréés australiens est spécialisée dans la santé masculine, avec des décennies d'expérience dans le domaine. Nous proposons des plans de traitement personnalisés aux patients luttant contre l'éjaculation précoce. Nous donnons aux hommes les moyens de reprendre le contrôle de leur vie sexuelle et de se sentir plus confiants à l'intérieur et à l'extérieur de la chambre à coucher. Pour en savoir plus sur les solutions personnalisées de la Men's Health Clinic, appelez-nous dès aujourd'hui au 1300 959 733.",
      },
    },
    {
      "@type": "Question",
      name: "Comment puis-je commencer le traitement de l'éjaculation précoce ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Il peut être gênant et inconfortable de discuter de ces sujets. De nombreux hommes peuvent être traités avec succès et facilement grâce à des options de traitement abordables. La partie la plus difficile est de franchir le premier pas et de parler à un médecin. À la Men's Health Clinic, nos médecins ont une vaste expérience dans la santé masculine et comprennent la nature délicate des troubles sexuels. Programmez une consultation virtuelle avec notre équipe médicale.",
      },
    },
    {
      "@type": "Question",
      name: "Quelles sont les meilleures options de traitement pour l'éjaculation précoce et comment puis-je la guérir ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tout comme aucun homme n'est identique, il n'existe pas de médicament ou de plan de traitement universel pour résoudre l'éjaculation précoce. Nos médecins à la Men's Health Clinic comprennent cela, c'est pourquoi ils travaillent avec vous pour créer des plans de traitement personnalisés pour augmenter les chances de succès. Selon la cause et la gravité de votre éjaculation précoce, vous pouvez opter pour un traitement plus naturel.\nCela comprend :\n- exercices et techniques comportementaux\n- la masturbation avant les rapports sexuels\n- Les exercices de Kegel\n- La technique du démarrage-arrêt\n- La technique de compression\n- Conseils : Votre santé mentale et votre santé sexuelle sont étroitement liées. Tout comme des problèmes psychologiques peuvent déclencher l'éjaculation précoce, votre éjaculation précoce peut entraîner des problèmes de santé mentale tels que la dépression et l'anxiété.\nIl y a beaucoup de pressions sociales sur les hommes, et associées aux stress quotidiens, cela peut se manifester par une dysfonction sexuelle. Si vous faites face à l'éjaculation précoce et à des problèmes de santé mentale, votre médecin vous recommandera de parler à un professionnel de la santé mentale. Avec une thérapie pour l'éjaculation précoce, vous pouvez apprendre à faire face aux inquiétudes psychologiques qui provoquent votre état. Vous acquerrez également les outils pour gérer la détresse émotionnelle causée par l'éjaculation précoce. Parfois, votre partenaire pourrait participer à la thérapie avec vous.",
      },
    },
    {
      "@type": "Question",
      name: "Est-ce que l'éjaculation précoce peut être traitée ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Oui, l'éjaculation précoce peut être traitée. Cependant, comme aucun homme n'est identique, il n'y a pas de plan de traitement universel qui convienne à tout le monde. Cela signifie que, pour certains hommes, trouver la bonne combinaison de traitement peut nécessiter du temps et de la patience. Votre gestionnaire de cas vérifiera périodiquement avec vous pour vous aider à obtenir les résultats que vous souhaitez.",
      },
    },
    {
      "@type": "Question",
      name: "Comment puis-je être diagnostiqué pour l'éjaculation précoce ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Pour recevoir un diagnostic d'éjaculation précoce ici à la Men's Health Clinic, tout ce que vous avez à faire est de remplir un questionnaire d'évaluation en ligne ou de planifier une consultation en ligne. Vous aurez une consultation complète et discrète de télésanté avec un vrai médecin par téléphone. Pour mieux comprendre ce qui fonctionnera le mieux pour vous, ils vous poseront des questions basées sur votre mode de vie, votre vie sexuelle, les symptômes d'éjaculation précoce que vous éprouvez ainsi que des détails sur votre relation.\nAprès tout, c'est un acte intime pour vous deux. Une fois que vous avez été diagnostiqué et que vous êtes éligible pour notre plan de traitement, les médecins de la Men's Health Clinic travailleront avec vous pour créer un plan de traitement efficace et personnalisé qui vous est propre.",
      },
    },
    {
      "@type": "Question",
      name: "Quelles sont les causes de l'éjaculation précoce ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "De temps en temps, vous pourriez remarquer que vous atteignez l'orgasme rapidement pendant les rapports sexuels. Il est normal que cela se produise de temps en temps. Cela peut se produire si vous n'avez pas éjaculé depuis un certain temps ou si vous couchez avec un nouveau partenaire sexuel pour la première fois. Cela peut être causé par des facteurs psychologiques tels que le stress, des problèmes relationnels, un manque de confiance en soi et même des attentes irréalistes quant aux performances sexuelles. L'éjaculation précoce peut également être déclenchée par des facteurs physiques tels que le diabète, l'hypertension et l'hyperthyroïdie (1).\nSi vous êtes frustré parce que vous éjaculez systématiquement plus tôt que vous le souhaiteriez (souvent dans les trois premières minutes des rapports sexuels), vous devriez être évalué pour l'éjaculation précoce.",
      },
    },
  ],
}

const videoLdJSON = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Rencontrez le Dr Fred Edwards.",
  description:
    "Jetez un coup d'œil dans le monde de la Men's Health Clinic, une clinique de télésanté établie avec plus de 10 ans d'expérience, une équipe de plus de 150 membres et un bilan de traitement de plus de 15 000 hommes dans le monde entier.",
  thumbnailUrl: [
    "https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1390952176-93a902607d3811d11a66de5828ce204fa39a69119d3abbe36d8cc602a2eb6193-d_1280x720&src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png",
  ],
  uploadDate: "2022-08-24T08:00:00+08:00",
  contentUrl:
    "https://menshealthclinic.com/au/treatments/premature-ejaculation",
  embedUrl:
    "https://player.vimeo.com/video/635035287?h=1479562035&title=0&byline=0&portrait=0",
  potentialAction: {
    "@type": "SeekToAction",
    target:
      "https://menshealthclinic.com/au/treatments/premature-ejaculation={seek_to_second_number}",
    "startOffset-input": "required name=seek_to_second_number",
  },
}

const PrematureEjaculation = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Traitement de l'éjaculation précoce",
    description: `La Men's Health Clinic aide les hommes avec le traitement de l'éjaculation précoce depuis plus d'une décennie. Le traitement de l'éjaculation précoce à la Men's Health Clinic est discret et personnalisé rien que pour vous. Selon la cause de votre éjaculation précoce, nos médecins canadiens agréés peuvent recommander des options de traitement allant d'exercices comportementaux et de techniques à un plan de traitement sur mesure.`,
    url: `https://menshealthclinic.com/${prefix}/treatments/premature-ejaculation`,
  }
  // WEBPAGE SCHEMA END //

  const headerBannerImg = React.useMemo(() => {
    return HeaderImg
  }, [prefix])

  const aboutTreatmentImg = React.useMemo(() => {
    return About1
  }, [prefix])

  const PEQuizLink = React.useMemo(() => {
    return `https://app.menshealthclinic.com/assessment/quiz?category_id=2&source=Website-Quiz&formCompleted=Embedded-Quiz&prefix=${prefix}&formUrl=?pewebquiz`
  }, [prefix])

  let PESeoTitle =
    "Traitement de l'éjaculation précoce au Canada | Solutions pour l'éjaculation précoce | MHC"
  let PESeoDescription =
    "Obtenir un traitement pour l'éjaculation précoce au Canada est facile et pratique avec la Men's Health Clinic. Programmez une consultation en ligne pour le traitement de l'éjaculation précoce dès aujourd'hui."
  let PETitle = "Traitement de l'éjaculation précoce"
  let PEHeaderBannerImgAlt = "Solutions pour l'éjaculation précoce"

  const stories = [
    {
      user: "Brian J.",
      title: "Dysfonction érectile",
      story: `J'ai trouvé la Men's Health Clinic très utile et soutenante pour restaurer ma confiance en moi et ma relation avec ma partenaire. La Men's Health Clinic a été très accessible et m'a contacté régulièrement pour évaluer mes progrès.`,
    },
    {
      user: "Christian M.",
      title: "Éjaculation précoce",
      story: `Depuis que je suis sur le programme de la Men's Health Clinic pour aider avec l'éjaculation précoce, cela a été une excellente expérience. Ils sont très utiles et savent de quoi ils parlent. Je recommande vivement ce programme à tout homme cherchant de l'aide !`,
    },
    {
      user: "A Sal.",
      title: "",
      story: `Depuis que j'ai rejoint la Men's Health Clinic (MHC), ma vie s'est nettement améliorée. Ma confiance en moi, ma relation, ma santé générale et mon bien-être se sont considérablement améliorés. Les conseils et le soutien à la Men's Health Clinic ont été exceptionnels. Merci pour tout, j'attends avec impatience de travailler avec vous à l'avenir. Je recommanderais à quiconque de parler aux gars de la Men's Health Clinic, vous ne serez pas déçu.`,
    },
  ]

  const questions = [
    {
      question: "Comment la Men's Health Clinic peut-elle aider?",
      answer:
        "La Men's Health Clinic est une clinique de télésanté en ligne qui dessert des milliers d'hommes à travers l'Australie. Notre équipe de médecins agréés australiens est spécialisée dans la santé masculine, avec des décennies d'expérience dans le domaine. Nous proposons des plans de traitement personnalisés aux patients luttant contre l'éjaculation précoce.\n\n Nous donnons aux hommes les moyens de reprendre le contrôle de leur vie sexuelle et de se sentir plus confiants à l'intérieur et à l'extérieur de la chambre à coucher. Pour en savoir plus sur les solutions personnalisées de la Men's Health Clinic, appelez-nous dès aujourd'hui au +1 (800) 422 8149.",
      telLink: TextCopies.countryPhone(prefix),
    },
    {
      question:
        "Comment puis-je commencer le traitement de l'éjaculation précoce ?",
      answer:
        "Il peut être gênant et inconfortable de discuter de ces sujets. De nombreux hommes peuvent être traités avec succès et facilement grâce à des options de traitement abordables. La partie la plus difficile est de franchir le premier pas et de parler à un médecin. À la Men's Health Clinic, nos médecins ont une vaste expérience dans la santé masculine et comprennent la nature délicate des troubles sexuels. Programmez une consultation virtuelle avec notre équipe médicale.",
    },
    {
      question:
        "Quelles sont les meilleures options de traitement pour l'éjaculation précoce et comment puis-je la guérir ?",
      answer:
        "Tout comme aucun homme n'est identique, il n'existe pas de médicament ou de plan de traitement universel pour résoudre l'éjaculation précoce. Nos médecins à la Men's Health Clinic comprennent cela, c'est pourquoi ils travaillent avec vous pour créer des plans de traitement personnalisés pour augmenter les chances de succès. Selon la cause et la gravité de votre éjaculation précoce, vous pouvez opter pour un traitement plus naturel.\nCela comprend :\n- exercices et techniques comportementaux\n- la masturbation avant les rapports sexuels\n- Les exercices de Kegel\n- La technique du démarrage-arrêt\n- La technique de compression\n- Conseils : Votre santé mentale et votre santé sexuelle sont étroitement liées. Tout comme des problèmes psychologiques peuvent déclencher l'éjaculation précoce, votre éjaculation précoce peut entraîner des problèmes de santé mentale tels que la dépression et l'anxiété.\n\nIl y a beaucoup de pressions sociales sur les hommes, et associées aux stress quotidiens, cela peut se manifester par une dysfonction sexuelle. Si vous faites face à l'éjaculation précoce et à des problèmes de santé mentale, votre médecin vous recommandera de parler à un professionnel de la santé mentale. Avec une thérapie pour l'éjaculation précoce, vous pouvez apprendre à faire face aux inquiétudes psychologiques qui provoquent votre état. Vous acquerrez également les outils pour gérer la détresse émotionnelle causée par l'éjaculation précoce. Parfois, votre partenaire pourrait participer à la thérapie avec vous.",
    },
    {
      question: "Est-ce que l'éjaculation précoce peut être traitée ?",
      answer:
        "Oui, l'éjaculation précoce peut être traitée. Cependant, comme aucun homme n'est identique, il n'y a pas de plan de traitement universel qui convienne à tout le monde. Cela signifie que, pour certains hommes, trouver la bonne combinaison de traitement peut nécessiter du temps et de la patience. Votre gestionnaire de cas vérifiera périodiquement avec vous pour vous aider à obtenir les résultats que vous souhaitez.",
    },
    {
      question:
        "Comment puis-je être diagnostiqué pour l'éjaculation précoce ?",
      answer:
        "Pour recevoir un diagnostic d'éjaculation précoce ici à la Men's Health Clinic, tout ce que vous avez à faire est de remplir un questionnaire d'évaluation en ligne ou de planifier une consultation en ligne. Vous aurez une consultation complète et discrète de télésanté avec un vrai médecin par téléphone. Pour mieux comprendre ce qui fonctionnera le mieux pour vous, ils vous poseront des questions basées sur votre mode de vie, votre vie sexuelle, les symptômes d'éjaculation précoce que vous éprouvez ainsi que des détails sur votre relation.\nAprès tout, c'est un acte intime pour vous deux. Une fois que vous avez été diagnostiqué et que vous êtes éligible pour notre plan de traitement, les médecins de la Men's Health Clinic travailleront avec vous pour créer un plan de traitement efficace et personnalisé qui vous est propre.",
    },
    {
      question: "Quelles sont les causes de l'éjaculation précoce ?",
      answer: {
        Component: () => (
          <>
            <span>
              De temps en temps, vous pourriez remarquer que vous atteignez
              l'orgasme rapidement pendant les rapports sexuels. Il est normal
              que cela se produise de temps en temps. Cela peut se produire si
              vous n'avez pas éjaculé depuis un certain temps ou si vous couchez
              avec un nouveau partenaire sexuel pour la première fois. Cela peut
              être causé par des facteurs psychologiques tels que le stress, des
              problèmes relationnels, un manque de confiance en soi et même des
              attentes irréalistes quant aux performances sexuelles.
              L'éjaculation précoce peut également être déclenchée par des
              facteurs physiques tels que le diabète, l'hypertension et
              l'hyperthyroïdie{" "}
              <a
                className="text-3FB1FC"
                href="https://www.ncbi.nlm.nih.gov/books/NBK546701"
              >
                (1)
              </a>
              .{`\n\n`}
              Si vous êtes frustré parce que vous éjaculez systématiquement plus
              tôt que vous le souhaiteriez (souvent dans les trois premières
              minutes des rapports sexuels), vous devriez être évalué pour
              l'éjaculation précoce."
            </span>
          </>
        ),
      },
    },
  ]

  return (
    <TreatmentsInner
      prefix={prefix}
      schema={schema}
      mainTitle="MEN'S HEALTH CLINIC"
      title={PETitle}
      seoTitle={PESeoTitle}
      seoDescription={PESeoDescription}
      slug="premature-ejaculation"
      faqSeoLdJSON={faqLdJSON}
      videoSeoLdJSON={videoLdJSON}
      text="Durez plus longtemps et retrouvez la confiance que vous méritez."
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "pemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "pebottomquiz",
        false,
        prefix
      )}
      headerBannerCTAlink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "pebannerquiz",
        false,
        prefix
      )}
      headerBannerImg={headerBannerImg}
      headerBannerImgAlt={PEHeaderBannerImgAlt}
      quizTitle={PETextComponents.quizTitle(prefix)}
      quizText={PETextComponents.quizText(prefix)}
      aboutTreatmentTitle="Traitement de l'éjaculation précoce à la Men's Health Clinic."
      aboutTreatmentText={PETextComponents.aboutTreatmentText(prefix)}
      aboutTreatmentImg={aboutTreatmentImg}
      aboutTreatmentImgAlt="Traitement de l'éjaculation précoce au Canada - Men's Health Clinic"
      aboutTreatmentTitleSecond="Qu'est-ce que l'éjaculation précoce ?"
      aboutTreatmentTextSecond={PETextComponents.aboutTreatmentTextSecond(
        prefix
      )}
      aboutTreatmentImgSecond={About2}
      aboutTreatmentImgSecondAlt="Diagnostic de l'éjaculation précoce - Men's Health Clinic"
      secondDashedTitle="Éjaculation précoce (EP)"
      firstDashedTitle="À propos de notre traitement"
      processQuizLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "peprocessquiz",
        false,
        prefix
      )}
      faqsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "pefaqquiz",
        false,
        prefix
      )}
      reviewsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "pereviewsquiz",
        false,
        prefix
      )}
      aboutLink={returnLink(
        "Website-Form",
        "Supplementary-Quiz",
        "pesymptoms",
        false,
        prefix
      )}
      assessmentQuizBtnLink={`/${prefix}/treatment-cost`}
      iframeLink={PEQuizLink}
      iframeSizeClass="peIframeSize"
      questions={questions}
      headerMarksText={[
        `Médecins autorisés au Canada`,
        "Traitements pratiques et sur mesure.",
        "Expédition discrète.",
        "Soins dévoués continus aux patients.",
      ]}
      embeddedQuizHeaderText={
        <>
          <strong className="text-16 font-bold">60 000 HOMMES</strong> ont été
          évalués avec succès grâce à notre quiz de vérification des symptômes
          GRATUIT de 2 minutes.
        </>
      }
      experts={TextCopies.experts(prefix)}
      expertsTitle="Offrant un haut degré de qualité, d'excellence et de soin."
      patientsStories={stories}
      blueDashedText="Rencontrez le Dr. Fred Edwards"
    />
  )
}
export default PrematureEjaculation
