import React from "react"
import Author from "../../../../components/PageComponents/author"
import DrFredEdwards from "../../../../images/experts/DrFred.webp"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const DrFredEdwardsAuthorPage = () => {
  const prefix = "fr-ca"
  const authorInfo = {
    name: "Dr. Fred Edwards",
    title: "MBBS, FRACGP",
    bio: "Le Dr Fred Edwards est un médecin généraliste qualifié et possède plus de 30 ans d’expérience. Son objectif est d’élargir le champ de traitement au sein du MHC pour inclure les problèmes de santé généraux afin d’aider les hommes.",
    img: DrFredEdwards,
    filterText: "Dr. Fred Edwards, MBBS, FRACGP",
  }
  const data = useStaticQuery(graphql`
    query DrEdwardsCAQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Author prefix={prefix} data={data} authorInfo={authorInfo} />
}

DrFredEdwardsAuthorPage.propTypes = {
  data: PropTypes.object,
}

export default DrFredEdwardsAuthorPage
