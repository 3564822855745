import React from "react"
import ErectileDysfunction from "../../../components/PageComponents/fr-ca/erectile-dysfunction"

const ErectileDysfunctionPage = () => {
  const prefix = "fr-ca"

  return <ErectileDysfunction prefix={prefix} />
}

export default ErectileDysfunctionPage
