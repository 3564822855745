import React from "react"
import useQuizUrl from "../../hooks/useQuizUrl"

const QuizPage = ({ location }) => {
  const quizUrl = useQuizUrl("UK", location)

  return (
    <iframe
      src={quizUrl}
      title="Men's Health Clinic Typeform Quiz"
      width="100%"
      style={{ height: "100vh" }}
    ></iframe>
  )
}

export default QuizPage
