import * as React from "react"

const CATermsConditions = () => {
  return (
    <main>
      <section className="width__container mb-165 flex justify-center">
        <div>
          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"privacy"}>
              TERMES ET CONDITIONS
            </h2>
            <p>
              VEUILLEZ LIRE ATTENTIVEMENT CES TERMES ET CONDITIONS AVANT
              D'UTILISER NOTRE SERVICE OU CE SITE
            </p>
            <p>
              Ces termes vous indiquent les règles d'utilisation de notre site
              web www.menshealthclinic.com/ca (notre site).
              www.menshealthclinic.com/ca est un site exploité par la Men's
              Health Clinic MHC (« Nous » ou « Notre » ou « Nos »). Nous sommes
              enregistrés au Canada sous le numéro BC 1317300 et avons notre
              siège social au 1175 Douglas St 7th Floor, Victoria,
              Colombie-Britannique, BC V8W 2E1, Canada.
            </p>
            <p>
              Pour nous contacter, veuillez envoyer un e-mail à
              info@menshealthclinic.ca ou téléphoner à notre service client au
              <a className="hover:text-3FB1FC" href={`tel:+1 (800) 422 8149`}>
                +1 (800) 422 8149.
              </a>
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115">
            <h2 className="section__title mb-20" id={"privacy"}>
              EN UTILISANT NOTRE SITE, VOUS ACCEPTEZ CES TERMES
            </h2>
            <p>
              En utilisant notre site, vous confirmez que vous acceptez ces
              conditions d'utilisation et que vous vous engagez à les respecter.
            </p>
            <p>
              Si vous n'acceptez pas ces termes, vous ne devez pas utiliser
              notre site.
            </p>
            <p>
              Nous vous recommandons d'imprimer une copie de ces termes pour
              référence future.
            </p>
            <p>
              Ces conditions d'utilisation font référence aux termes
              supplémentaires suivants, qui s'appliquent également à votre
              utilisation de notre site :
            </p>
            <ul className="mb-50 hidden pl-30 text-16 sm:block">
              <li className="list-disc">
                Notre Politique de confidentialité qui explique comment nous
                pouvons utiliser vos informations personnelles (lorsque vous
                mettez ces informations à notre disposition, par exemple, en
                créant un compte).
              </li>
              <li className="list-disc">
                Notre Politique relative aux cookies, qui fournit des
                informations sur les cookies sur notre site. Nous modifions ces
                termes de temps à autre et sauf indication contraire, les
                modifications prennent effet immédiatement. Chaque fois que vous
                souhaitez utiliser notre site, vous êtes responsable de vérifier
                ces termes pour vous assurer de comprendre les termes qui
                s'appliquent à ce moment-là. Si vous continuez à utiliser ce
                site après toute modification des termes, vous serez réputé
                avoir accepté ces modifications. Ces termes ont été mis à jour
                pour la dernière fois le 18/05/2021.
              </li>
              <li className="list-disc">
                Nous pouvons mettre à jour et changer notre site de temps en
                temps pour refléter les changements dans nos produits, les
                besoins de nos utilisateurs et nos priorités commerciales ou
                pour toute autre raison. Nous essaierons de vous donner un
                préavis raisonnable de tout changement majeur.
              </li>
            </ul>

            <p className="mb-10">
              Notre site est mis à disposition gratuitement.
            </p>
            <p className="mb-10">
              Nous ne garantissons pas que notre site, ou tout contenu sur
              celui-ci, sera toujours disponible ou ininterrompu. Nous pouvons
              suspendre, retirer ou restreindre la disponibilité de tout ou
              partie de notre site pour des raisons commerciales et
              opérationnelles. Nous essaierons de vous donner un préavis
              raisonnable de toute suspension ou retrait.
            </p>
            <p className="mb-10">
              Vous êtes responsable de vous assurer que toutes les personnes qui
              accèdent à notre site via votre connexion Internet sont informées
              de ces conditions d'utilisation et d'autres conditions générales
              applicables, et qu'elles s'y conforment.
            </p>
            <p className="mb-10">
              Nous pouvons transférer nos droits et obligations en vertu de ces
              termes à une autre organisation. Nous vous informerons toujours
              par écrit si cela se produit et nous veillerons à ce que le
              transfert n'affecte pas vos droits en vertu du contrat.
            </p>
            <p className="mb-10">
              Notre site s'adresse aux personnes résidant au Canada. Nous ne
              prétendons pas que le contenu disponible sur notre site est
              approprié pour une utilisation ou disponible dans d'autres
              endroits{" "}
              <span>
                ou satisfait aux lois d'autres pays. [Si vous accédez au site en
                dehors du Canada, vous êtes responsable de vous assurer que
                votre accès et votre utilisation du site ne sont ni illégaux ni
                interdits, et que vous respectez les lois locales applicables.]
              </span>
              .
            </p>

            <p className="mb-10">
              Si vous choisissez, ou si on vous fournit, un code
              d'identification d'utilisateur, un mot de passe ou toute autre
              information dans le cadre de nos procédures de sécurité, vous
              devez traiter ces informations comme confidentielles. Vous ne
              devez les divulguer à aucun tiers.
            </p>
            <p className="mb-10">
              Nous avons le droit de désactiver à tout moment tout code
              d'identification d'utilisateur ou mot de passe, que vous avez
              choisi ou qui vous a été attribué, si, à notre avis raisonnable,
              vous avez omis de respecter l'une des dispositions de ces
              conditions d'utilisation.
            </p>
            <p className="mb-10">
              Si vous savez ou soupçonnez que quelqu'un d'autre que vous connaît
              votre code d'identification d'utilisateur ou votre mot de passe,
              vous devez nous en informer rapidement à{" "}
              <a href={`mailto:info@menshealthclinic.ca`}>
                {" "}
                info@menshealthclinic.ca
              </a>
            </p>
            <p className="mb-10">
              Nous sommes propriétaires ou titulaires de toutes les droits de
              propriété intellectuelle de notre site,{" "}
              <span>
                y compris le réseau, le système, les données ou le matériel qui
                sous-tendent ou sont connectés à notre site (le système
                sous-jacent),{" "}
              </span>
              et du matériel publié sur celui-ci. Ces œuvres sont protégées par
              les lois sur le droit d'auteur et les traités du monde entier.
              Tous ces droits sont réservés.
            </p>
            <p className="mb-10">
              Vous pouvez imprimer une copie et télécharger des extraits de
              toute page de notre site pour votre usage personnel et attirer
              l'attention d'autres personnes au sein de votre organisation sur
              le contenu publié sur notre site.
            </p>
            <p className="mb-10">
              Vous ne devez pas modifier les copies papier ou numériques de tout
              matériel que vous avez imprimé ou téléchargé de quelque manière
              que ce soit, et vous ne devez pas utiliser d'illustrations, de
              photographies, de séquences vidéo ou audio ou de graphiques
              séparément du texte accompagnant.
            </p>
            <p className="mb-10">
              Notre statut (et celui de tout contributeur identifié) en tant
              qu'auteurs du contenu de notre site doit toujours être reconnu.
            </p>
            <p className="mb-10">
              Vous ne devez utiliser aucune partie du contenu de notre site à
              des fins commerciales sans obtenir une licence de notre part ou de
              nos concédants.
            </p>
            <p className="mb-10">
              Si vous imprimez, copiez ou téléchargez une partie de notre site
              en violation de ces conditions d'utilisation, votre droit
              d'utiliser notre site prendra fin immédiatement et vous devrez, à
              notre discrétion, restituer ou détruire toute copie des matériaux
              que vous avez faite.
            </p>
          </div>

          <div className="mb-30 w-full max-w-700 sm:mb-115" id={"userPolicy"}>
            <h2 className="section__title mb-20">
              NE PAS SE FIERS AUX INFORMATIONS SUR CE SITE{" "}
            </h2>
            <p className="mb-10">
              Le contenu de notre site est fourni uniquement à des fins
              d'information générale. Il n'est pas destiné à constituer un
              conseil sur lequel vous devez vous fier.{" "}
              <span>Nous vous recommandons</span> d'obtenir des conseils
              professionnels ou spécialisés avant de prendre, ou de vous
              abstenir de prendre, toute mesure sur la base du contenu de notre
              site.
            </p>
            <p className="mb-10">
              Bien que nous fassions des efforts raisonnables pour mettre à jour
              les informations sur notre site, nous ne faisons aucune
              déclaration, garantie ou engagement, qu'ils soient explicites ou
              implicites, quant à l'exactitude, l'exhaustivité ou l'actualité du
              contenu de notre site.
            </p>
            <p className="mb-10">
              Lorsque notre site contient des liens vers d'autres sites et
              ressources fournis par des tiers, ces liens sont fournis
              uniquement à titre d'information. Ces liens ne doivent pas être
              interprétés comme une approbation de notre part des sites liés ou
              des informations que vous pouvez en obtenir. Nous n'avons aucun
              contrôle sur le contenu de ces sites ou ressources.
            </p>
            <p className="mb-10">
              Ce site peut inclure des informations et des documents téléchargés
              par d'autres utilisateurs du site, y compris sur les babillards
              électroniques et les salles de discussion. Ces informations et ces
              documents n'ont pas été vérifiés ou approuvés par nous. Les
              opinions exprimées par d'autres utilisateurs sur notre site ne
              représentent pas nos opinions ou nos valeurs.
            </p>
            <p className="mb-10">
              Si vous souhaitez vous plaindre du contenu téléchargé par d'autres
              utilisateurs, veuillez contacter{" "}
              <a href={`mailto:info@menshealthclinic.ca`}>
                {" "}
                info@menshealthclinic.ca
              </a>
            </p>
            <p className="mb-10">
              Les limitations et exclusions de responsabilité suivantes ne
              s'appliquent pas à la fourniture de services et/ou de produits de
              traitement par MHC. Veuillez consulter les conditions générales du
              client.
            </p>
            <p className="mb-10">
              Dans la mesure permise par la loi, nous excluons toutes les
              conditions implicites, garanties, représentations ou autres termes
              qui peuvent s'appliquer à notre site ou à son contenu. Dans la
              mesure permise par la loi, nous ne serons pas responsables envers
              vous de toute perte ou dommage, qu'il soit contractuel, délictuel
              (y compris la négligence), violation d'une obligation légale ou
              autre, même prévisible, découlant de ou lié à;
            </p>
            <ul className="mb-50 hidden pl-30 text-16 sm:block">
              <li className="list-disc">
                utilisation de notre site ou incapacité de l'utiliser ; ou
              </li>
              <li className="list-disc">
                utilisation ou dépendance à l'égard de tout contenu affiché sur
                notre site.
              </li>
            </ul>
            <p>En particulier, nous ne serons pas responsables de :</p>
            <ul className="mb-50 hidden pl-30 text-16 sm:block">
              <li className="list-disc">interruption d'activité;</li>
              <li className="list-disc">perte d'économies prévues;</li>
              <li className="list-disc">
                perte d'opportunité commerciale, de réputation ou de bonne
                volonté; ou
              </li>
              <li className="list-disc">
                toute perte ou dommage indirect ou consécutif.
              </li>
            </ul>
            Nous n'excluons ni ne limitons de quelque manière que ce soit notre
            responsabilité envers vous dans la mesure où cela serait illégal.
            Par exemple, sauf dans la mesure permise par la loi, rien dans ces
            termes n'a pour effet de déroger à la Loi canadienne sur la sécurité
            des produits de consommation (LCSPC) ou à toute loi sur la
            protection des consommateurs. Nous n'utiliserons les informations
            personnelles que conformément à notre Politique de confidentialité.
            <p className="mb-10">
              Chaque fois que vous utilisez une fonctionnalité qui vous permet
              de télécharger du contenu sur notre site ou de prendre contact
              avec d'autres utilisateurs de notre site, vous garantissez que si
              une telle contribution est conforme aux normes acceptables, vous
              serez responsable envers nous et nous indemniserez en cas de
              violation de cette garantie. Cela signifie que vous serez
              responsable de toute perte ou dommage que nous subissons en raison
              de votre violation de garantie.
            </p>
            <p className="mb-10">
              Nous ne garantissons pas que notre site sera sécurisé ou exempt de
              bogues
              <span>, de virus ou d'autres formes d'interférences</span>.
            </p>
            <p className="mb-10">
              Vous êtes responsable de vous assurer que le processus par lequel
              vous accédez et utilisez notre site (y compris la configuration de
              votre technologie de l'information, les programmes informatiques
              et la plateforme) vous protège contre les bogues, les virus ou
              d'autres formes d'interférences. Vous ne devez pas introduire
              délibérément des virus, des chevaux de Troie, des vers, des bombes
              logiques ou tout autre matériel malveillant ou technologiquement
              nuisible sur notre site ou sur le système sous-jacent.
            </p>
            <p className="mb-10">
              Vous ne devez pas tenter d'accéder illégalement à notre site, au
              serveur sur lequel notre site est stocké, ou à tout serveur,
              ordinateur ou base de données connecté à notre site.{" "}
              <span>
                Vous ne pouvez accéder à notre site qu'à l'aide de navigateurs
                web standard et non par d'autres méthodes telles que le
                scraping, le deep-linking, le harvesting, le data mining ou
                toute autre méthode de collecte, d'extraction ou de surveillance
                de données.
              </span>
            </p>
            <p className="mb-10">
              Vous ne devez pas attaquer notre site par le biais d'une attaque
              par déni de service ou d'une attaque par déni de service
              distribué. <span>Nous nous réservons le droit de</span> signaler
              toute violation de ce type aux autorités compétentes en matière de
              maintien de l'ordre et de coopérer avec ces autorités en
              divulguant votre identité. En cas de violation de ce type, votre
              droit d'utiliser notre site prendra fin immédiatement.
            </p>
            <p className="mb-10">
              Vous pouvez créer un lien vers notre page d'accueil, à condition
              de le faire de manière équitable et légale et sans nuire à notre
              réputation ni en tirer avantage.
            </p>
            <p className="mb-10">
              Vous ne devez pas établir de lien de manière à suggérer une
              quelconque forme d'association, d'approbation ou d'endossement de
              notre part là où il n'y en a pas.
            </p>
            <p className="mb-10">
              Vous ne devez pas établir de lien vers notre site depuis un site
              qui ne vous appartient pas.
            </p>
            <p className="mb-10">
              Notre site ne doit pas être encadré sur un autre site, et vous ne
              pouvez pas créer de lien vers une partie de notre site autre que
              la page d'accueil.
            </p>
            <p className="mb-10">
              <span>
                Nous nous réservons le droit de retirer les autorisations de
                lien. Si nous retirons une autorisation de lien ou découvrons un
                lien qui enfreint les exigences ci-dessus, nous pouvons vous
                demander de supprimer le lien immédiatement. Vous acceptez de
                vous conformer à cette demande.
              </span>
            </p>
            <p className="mb-10">
              Si vous souhaitez créer un lien vers ou faire un autre usage du
              contenu de notre site autre que celui indiqué ci-dessus, veuillez
              contacter{" "}
              <a href={`mailto:info@menshealthclinic.ca`}>
                {" "}
                info@menshealthclinic.ca
              </a>
            </p>
          </div>
          <div className="w-full max-w-700" id={"userCookies"}>
            <h2 className="section__title mb-20">
              INFORMATIONS COLLECTÉES SUR NOTRE SITE
            </h2>
            <p className="mb-10">
              Nous pouvons utiliser des "cookies" et d'autres technologies
              internet similaires sur notre site. Les "cookies" sont de petits
              fichiers de données que votre navigateur internet stocke sur le
              dispositif par lequel vous accédez au site. Les cookies sont
              stockés afin que votre navigateur internet puisse naviguer sur un
              site web et les cookies eux-mêmes ne peuvent collecter aucune
              information stockée sur votre ordinateur ou autre dispositif. Nous
              n'utiliserons pas de cookies pour collecter vos informations
              personnelles d'identification.
            </p>
            <p className="mb-10">
              L'utilisation de cookies et de technologies similaires nous aide à
              surveiller la manière dont les visiteurs utilisent le site (par
              exemple, les pages visitées) et peut améliorer l'expérience
              utilisateur en se souvenant de vos préférences.
            </p>
            <p className="mb-10">
              Notre site utilise des cookies pour vous distinguer des autres
              utilisateurs de notre site web. Cela nous aide à vous fournir une
              bonne expérience lorsque vous parcourez notre site web et nous
              permet également d'améliorer notre site.
            </p>
            <p className="mb-10">Nous utilisons les cookies suivants :</p>
            <ul className="mb-50 hidden pl-30 text-16 sm:block">
              <li className="list-disc">
                Cookies essentiels : Ce sont des cookies nécessaires au
                fonctionnement de notre site. Ils comprennent, par exemple, les
                cookies qui vous permettent de vous connecter aux zones
                sécurisées de notre site.
              </li>
              <li className="list-disc">
                Cookies analytiques ou de performance : Ils nous permettent de
                reconnaître et de compter le nombre de visiteurs et de voir
                comment les visiteurs se déplacent sur notre site lorsqu'ils
                l'utilisent. Cela nous aide à améliorer le fonctionnement de
                notre site.
              </li>
              <li className="list-disc">
                Cookies de fonctionnalité : Ils sont utilisés pour vous
                reconnaître lorsque vous revenez sur notre site. Cela nous
                permet de personnaliser notre contenu pour vous, de vous saluer
                par votre nom (si vous avez créé un compte sur le site) et de
                mémoriser vos préférences.
              </li>
              <li className="list-disc">
                Cookies de ciblage : Ces cookies enregistrent votre visite sur
                notre site, les pages que vous avez visitées et les liens que
                vous avez suivis. Nous utiliserons ces informations pour rendre
                notre site et la publicité qui y est affichée plus pertinents
                pour vos intérêts.
              </li>
            </ul>
            <p className="mb-10">
              Vous pouvez bloquer les cookies en activant le paramètre de votre
              navigateur qui vous permet de refuser l'installation de tous ou de
              certains cookies. Cependant, si vous utilisez les paramètres de
              votre navigateur pour bloquer tous les cookies (y compris les
              cookies essentiels), vous pourriez ne pas être en mesure d'accéder
              à tout ou partie de notre site.
            </p>
            <p className="mb-10">
              Notre site peut inclure des liens vers des sites web, des plug-ins
              et des applications de tiers. En cliquant sur ces liens ou en
              activant ces connexions, des tiers peuvent collecter ou partager
              des données vous concernant. Nous ne sommes pas responsables du
              traitement de vos informations personnelles par ces tiers. Lorsque
              vous quittez notre site, il est fortement recommandé de lire la
              politique de confidentialité et la politique de cookies de ces
              tiers.
            </p>
            <p className="mb-10">
              Veuillez noter que ces conditions d'utilisation, leur objet et
              leur formation sont régis par la loi canadienne. Vous et nous nous
              soumettons à la juridiction non exclusive des tribunaux canadiens
              en ce qui concerne tout litige lié à ces conditions ou à ce site.
              Pour que nous renoncions à un droit en vertu des conditions
              d'utilisation, la renonciation doit être écrite.
            </p>
            <p className="mb-10">
              Si une partie de ces conditions est ou devient illégale,
              inapplicable ou invalide, cette partie est réputée modifiée dans
              la mesure nécessaire pour remédier à l'illégalité, à
              l'inapplicabilité ou à l'invalidité. Si une modification n'est pas
              possible, la partie sera traitée comme détachée de ces conditions.
              Le reste des conditions restera contraignant.
            </p>
            <p className="mb-10">
              Si vous avez des demandes, des questions, des préoccupations ou
              des commentaires, nous sommes toujours heureux d'entendre parler
              de nos patients. Vous pouvez nous contacter à l'adresse e-mail
              suivante :
              <a href={`mailto:info@menshealthclinic.ca`}>
                {" "}
                info@menshealthclinic.ca
              </a>
            </p>
            <p className="mb-10">Numéro de téléphone : +1 (800) 422 8149</p>
            <p className="mb-10">
              Bureau au Canada : 1175 Douglas St 7th Floor, Victoria,
              Colombie-Britannique, BC V8W 2E1, Canada.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default CATermsConditions
