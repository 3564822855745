export const TreatmentCostTextComponents = {
  headerText: prefix => {
    const headerText = {
      au: "If you are ready to take the next step and would like to understand the different treatment options please fill out this form.",
      ca: "If you are ready to take the next step and would like to understand the different treatment options please fill out this form.",
      "fr-ca":
        "Si vous êtes prêt à passer à l'étape suivante et souhaitez comprendre les différentes options de traitement, veuillez remplir ce formulaire.",
      ph: "Ready to take the next step? Fill out this form, and we will formulate the appropriate treatment options for you.",
      nz: "Ready to take the next step? Fill out this form, and we will formulate the appropriate treatment options for you.",
      uk: "Ready to take the next step? Fill out this form, and we will formulate the appropriate treatment options for you.",
    }
    return headerText[prefix]
  },
  faqAnswerOne: prefix => {
    const faqAnswerOne = {
      au: "Treatment is affordable. Every man’s needs are different. The severity of your condition and the likely causes are different from the next man. As such you require a customised and managed treatment program which is why the cost of treatment plans (including medication) varies.",
      ca: "Treatment is affordable in Canada. Every man's needs are different, and the severity of your condition and the likely causes differ from those of other individuals. As such, you require a customised and managed treatment program. This is the reason the cost of treatment plans (including medication) varies.",
      "fr-ca":
        "Le traitement est abordable au Canada. Les besoins de chaque homme sont différents, et la gravité de votre condition ainsi que les causes probables diffèrent de celles d'autres individus. En conséquence, vous avez besoin d'un programme de traitement personnalisé et géré. C'est la raison pour laquelle le coût des plans de traitement (y compris les médicaments) peut varier.",
      ph: "Treatment is affordable. Every man’s needs are different. The severity of your condition and the likely causes are different from the next man. As such, you require a customized and managed treatment program, so the cost of treatment plans (including medication) varies.",
      nz: "Treatment is affordable. Every man’s needs are different. The severity of your condition and the likely causes are different from the next man. As such, you require a customized and managed treatment program, so the cost of treatment plans (including medication) varies.",
      uk: "Treatment is affordable. Every man’s needs are different. The severity of your condition and the likely causes are different from the next man. As such, you require a customized and managed treatment program, so the cost of treatment plans (including medication) varies.",
    }
    return faqAnswerOne[prefix]
  },
  faqAnswerTwo: prefix => {
    const faqAnswerTwo = {
      au: `Every patient has a unique treatment plan for their premature ejaculation or erectile dysfunction. Lifestyle changes such as weight-loss, quitting smoking and diet will also be encouraged. Think of Men’s Health Clinic as your partner in improving your overall wellness and sexual confidence. Our goal is to help you achieve longer, harder erections so you can have better, more satisfying sex.`,
      ca: `Every patient requires a unique treatment plan for premature ejaculation or erectile dysfunction. Lifestyle changes, such as weight loss, quitting smoking, and diet modifications, will also be encouraged. Think of Men's Health Clinic as your partner in improving your overall wellness and sexual confidence. Our goal is to help you achieve longer, harder erections, so you can experience better and more satisfying sex.`,
      "fr-ca": `Chaque patient nécessite un plan de traitement unique pour l'éjaculation précoce ou la dysfonction érectile. Des changements de mode de vie, tels que la perte de poids, l'arrêt du tabac et des modifications alimentaires, seront également encouragés. Considérez la Men's Health Clinic comme votre partenaire pour améliorer votre bien-être général et votre confiance sexuelle. Notre objectif est de vous aider à obtenir des érections plus longues et plus dures, afin que vous puissiez vivre des expériences sexuelles meilleures et plus satisfaisantes.`,
      ph: `Every patient has a unique treatment plan for their premature ejaculation or erectile dysfunction. Lifestyle changes such as weight-loss, quitting smoking and diet will also be encouraged. Think of Men’s Health Clinic Philippines as your partner in improving your overall wellness and sexual confidence. Our goal is to help you achieve longer, harder erections so you can have better, more satisfying sex.`,
      nz: `Every patient has a unique treatment plan for premature ejaculation or erectile dysfunction. Lifestyle changes such as weight loss, quitting smoking, and diet will also be encouraged. Think of Men’s Health Clinic New Zealand as your partner in improving your overall wellness and sexual confidence. Our goal is to help you achieve longer, harder erections to have better, more satisfying sex.`,
      uk: "Every patient has a unique treatment plan for premature ejaculation or erectile dysfunction. Lifestyle changes such as weight loss, quitting smoking, and diet will also be encouraged. Think of MHC Men’s Health Clinic UK. as your partner in improving your overall wellness and sexual confidence. Our goal is to help you achieve longer, harder erections to have better, more satisfying sex.",
    }
    return faqAnswerTwo[prefix]
  },
  faqAnswerThree: prefix => {
    const faqAnswerThree = {
      au: "At the Men’s Health Clinic, we do not lock you in any contracts. We do recommend that you adhere to the treatment to the frequency and duration that the doctor deemed necessary in order to overcome the symptoms.",
      ca: "At the Men’s Health Clinic, we do not lock you in any contracts. We do recommend that you adhere to the treatment to the frequency and duration that the doctor deemed necessary in order to overcome the symptoms.",
      "fr-ca":
        "À la Men's Health Clinic, nous ne vous engageons dans aucun contrat. Nous recommandons cependant de suivre le traitement à la fréquence et à la durée que le médecin juge nécessaires pour surmonter les symptômes.",
      ph: "At the Men’s Health Clinic, we do not lock you in any contracts. We do recommend that you adhere to the treatment to the frequency and duration that the doctor deemed necessary in order to overcome the symptoms.",
      nz: "At the Men’s Health Clinic, we do not lock you in any contracts. Instead, we recommend that you adhere to the treatment frequency and duration that the doctor deemed necessary to overcome the symptoms.",
      uk: "At MHC Men’s Health Clinic, we do not lock you in any contracts. Instead, we recommend that you adhere to the treatment frequency and duration that the doctor deemed necessary to overcome the symptoms.",
    }
    return faqAnswerThree[prefix]
  },
  faqAnswerFour: prefix => {
    const faqAnswerFour = {
      au: "In order to receive a customised price, you will need to fill out our short survey above. Our doctor will give the final clearance and custom treatment plan. Having a sexual dysfunction is nothing to be ashamed of. Not doing anything about it is.",
      ca: "To receive a customised price, you will need to fill out our short survey above. Our doctor will give the final clearance and custom treatment plan, which will determine the price.",
      "fr-ca":
        "Pour recevoir un prix personnalisé, vous devrez remplir notre court sondage ci-dessus. Notre médecin donnera l'autorisation finale et élaborera un plan de traitement personnalisé, qui déterminera le prix.",
      ph: "To receive a customized price, you must fill out our short survey above. Then, our doctor will give the final clearance and custom treatment plan. Having a sexual dysfunction is nothing to be ashamed of. Not doing anything about it is.",
      nz: "To receive a customized price, you must fill out our short survey above. Then, our doctor will give the final clearance and custom treatment plan. Having a sexual dysfunction is nothing to be ashamed of.",
      uk: "You must fill out our short survey above to receive a customized price. Then, our doctor will give the final clearance and custom treatment plan. Having a sexual dysfunction is nothing to be ashamed of.",
    }
    return faqAnswerFour[prefix]
  },
}
