import React from "react"
import PrivacyPolicy from "../../components/PageComponents/privacy-policy"

const PrivacyPolicyPage = () => {
  const prefix = "uk"

  return <PrivacyPolicy prefix={prefix} />
}

export default PrivacyPolicyPage
