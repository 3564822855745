import * as React from "react"
import TreatmentsInner from "./treatmentsInner"
import { returnLink } from "../../helpers/returnLink"
import HeaderImg from "../../images/chronic-pain/chronicPainHeader.webp"
import About1 from "../../images/chronic-pain/chronicPainImg1.webp"
import About2 from "../../images/chronic-pain/chronicPainImg2.webp"
import DrVahid from "../../images/experts/DrVahid.webp"
import DrTroy from "../../images/experts/DrTroy.webp"
import DrAayu from "../../images/experts/DrAayuMehmi.webp"
import DrLara from "../../images/experts/DrLara.webp"
import DrFred from "../../images/experts/DrFred.webp"
import DrJonathan from "../../images/experts/DrJonathan.webp"
import PropTypes from "prop-types"

export const experts = [
  {
    doctor: "Dr. Vahid Mohabbati",
    img: DrVahid,
    text: `“The art of medicine is to cure sometimes, to relieve often, to comfort always.” I believe medicine is an art and comfort and hope should be offered to every patient, in every encounter. One must never lose sight of the power we have in changing our patients’ perceptions and expectations of their diseases. While cure may not often be achievable, but relief is always possible. The art is to tailor a host of available options to fit one’s needs.`,
    imgText: "Pain Interventionist",
  },
  {
    doctor: "Dr. Troy Wallace",
    img: DrTroy,
    text: `Troy completed his Bachelor of Applied Science in Physiotherapy at the University of Sydney in 2011. Troy has extensive treatment experience, specialising in shoulder, knee, lower back and neck injuries. He was the Physiotherapist for Manly Sea Eagles U16/18 rugby league teams in 2012-2013. Troy also enjoys the challenge of combining his Physio knowledge and experience in both playing golf and competing as an amateur boxer to optimise performance in these individuals.`,
    imgText: "Physiotherapist",
  },
  {
    doctor: "Dr. Aayu Mehmi",
    img: DrAayu,
    text: `Aayu Mehmi is a qualified Psychologist, having completed her Bachelor’s Degree in Psychology with a further Master’s Degree in Counseling at Monash University. Aayu is a licensed Mental Health Counselor with PACFA and is passionate in helping treat patients suffering in Chronic Pain.`,
    imgText: "Psychologist",
  },
  {
    doctor: "Lara Abu-Zarour",
    img: DrLara,
    text: `Accredited Practising Dietitian and a member of Dietitians Australia, Lara has a passion for helping individuals; educating patients on how powerful nutrition and living a healthy lifestyle is when preventing acute and chronic conditions.`,
    imgText: "Dietitian",
  },
  {
    doctor: "Dr. Fred Edwards",
    img: DrFred,
    text: `Dr. Fred Edwards is a qualified GP and has over 30+ years’ experience. He aims to widen the scope of treatment within MHC to include general health issues to help men.`,
    imgText: "MBBS, FRACGP",
  },
  {
    doctor: "Dr. Jonathan Herald",
    img: DrJonathan,
    text: `Voted as Australia’s Best Doctor (orthopaedics) on the internationally doctor-rated Best Doctor website; Dr Herald has 20 years’ surgical experience and has a passion for helping patients with their Chronic Pain management.`,
    imgText: "MBBS, MSpMed",
  },
]

const headerMarksText = [
  "Australian licenced doctors.",
  "Convenient, tailored treatments.",
  "Discreet shipping.",
  "Dedicated ongoing patient care.",
]

const CP_Questions = [
  {
    question: "What are the symptoms of Chronic Pain?",
    answer:
      "Symptoms of chronic pain typically take shape in the form of mild to very bad pain that persists for a period of 3 months and longer. The affected area may also feel sore, stiff and tight. Shooting, burning, stinging, throbbing, aching pain are common symptoms of chronic pain.",
  },
  {
    question: "How do I treat Chronic Pain?",
    answer:
      "Our Chronic Pain management solutions are custom made and tailored to each patients needs. Rather than following a generic approach to Chronic Pain, we assess the patient entirely. Reviewing symptoms and lifestyle habits while creating realistic goals.\n\nTreatment may also include a pain relief cream if required and all treatment plans include telehealth consultations in the form of a consultation, physiotherapy session and frequent discussions with out Performance Managers to ensure you are getting the most out of your treatment.",
  },
  {
    question: "How to get diagnosed for Chronic Pain?",
    answer:
      "Diagnosing Chronic Pain involves the following steps:\n\n1. A free assessment with our team of experts.\n2. Identifying any causes, triggers or genetic predispositions that may inflict Chronic Pain.\n3. Setting realistic treatment goals for long term Chronic Pain Management.\n\nWe often recommend sufferers of Chronic Pain to keep a pain diary in order to monitor any causes, triggers or factors that may inflict or exacerbate existing symptoms.",
  },
  {
    question: "What are the 4 types of Chronic Pain?",
    answer: {
      Component: () => (
        <span>
          The 4 types of Chronic Pain include: {`\n\n`}
          1. Nociceptive pain: This type of pain is often caused by injury.{" "}
          {`\n`}
          2. Neuropathic Pain: This type of pain may be caused by injury that
          affects the nerve or can occur due to progressive nerve disease.{" "}
          {`\n`}
          3. Functional Pain: This is a type of pain that occurs without an
          identifiable reason or cause; an example of this may include
          musculoskeletal pain in fibromyalgia (FM) or visceral pain in
          irritable bowel syndrome (IBS).
          <a
            className="text-3FB1FC"
            href="https://academic.oup.com/bjaed/article/16/10/334/2288619"
          >
            (1)
          </a>{" "}
          {`\n`}
          4. Inflammatory Pain: This type of pain occurs when there is
          spontaneous hypersensitivity to pain that occurs in response to tissue
          damage and inflammation.
          <a
            className="text-3FB1FC"
            href="https://www.sciencedirect.com/topics/medicine-and-dentistry/inflammatory-pain#:~:text=inflammatorypainisthespontaneous,painctraumacarthritis"
          >
            (2)
          </a>
        </span>
      ),
    },
  },
]

const ChronicPain = ({ data, prefix }) => {
  const articles = data
    .filter(article => article.tags.includes("Chronic Pain"))
    .filter(article => article.lang === `en-${prefix === "uk" ? "gb" : prefix}`)
    .slice(0, 2)

  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What are the symptoms of Chronic Pain?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Symptoms of chronic pain typically take shape in the form of mild to very bad pain that persists for a period of 3 months and longer. The affected area may also feel sore, stiff and tight. Shooting, burning, stinging, throbbing, aching pain are common symptoms of chronic pain.",
        },
      },
      {
        "@type": "Question",
        name: "How do I treat Chronic Pain?",
        acceptedAnswer: {
          "@type": "Answer",
          text: `Our Chronic Pain management solutions are custom made and tailored to each patients needs. Rather than following a generic approach to Chronic Pain, we assess the patient entirely. Reviewing symptoms and lifestyle habits while creating realistic goals.\n
        Treatment may also include a pain relief cream if required and all treatment plans include telehealth consultations in the form of a consultation, physiotherapy session and frequent discussions with out Performance Managers to ensure you are getting the most out of your treatment.`,
        },
      },
      {
        "@type": "Question",
        name: "How to get diagnosed for Chronic Pain?",
        acceptedAnswer: {
          "@type": "Answer",
          text: `Diagnosing Chronic Pain involves the following steps:\n
        1. A free assessment with our team of experts.\n
        2. Identifying any causes, triggers or genetic predispositions that may inflict Chronic Pain.\n
        3. Setting realistic treatment goals for long term Chronic Pain Management.\n
        We often recommend sufferers of Chronic Pain to keep a pain diary in order to monitor any causes, triggers or factors that may inflict or exacerbate existing symptoms.`,
        },
      },
      {
        "@type": "Question",
        name: "What are the 4 types of Chronic Pain?",
        acceptedAnswer: {
          "@type": "Answer",
          text: `The 4 types of Chronic Pain include:\n
        1. Nociceptive pain: This type of pain is often caused by injury.\n
        2. Neuropathic Pain: This type of pain may be caused by injury that affects the nerve or can occur due to progressive nerve disease.\n
        3. Functional Pain: This is a type of pain that occurs without an identifiable reason or cause; an example of this may include musculoskeletal pain in fibromyalgia (FM) or visceral pain in irritable bowel syndrome (IBS).\n
        4. Inflammatory Pain: This type of pain occurs when there is spontaneous hypersensitivity to pain that occurs in response to tissue damage and inflammation.`,
        },
      },
    ],
  }
  // WEBPAGE SCHEMA END //

  return (
    <TreatmentsInner
      seoTitle={
        prefix === "au"
          ? "The Men's Health Clinic | Chronic Pain Treatment in Australia"
          : "Chronic Pain Treatment & Solutions | Men's Health Clinic"
      }
      seoDescription={
        prefix === "au"
          ? "Treatment for chronic pain is made easy and comfortable at the Men's Health Clinic. Schedule an online consultation for chronic pain treatment today."
          : "At Men's Health Clinic, our licensed doctors create customised treatment plans for chronic pain. Remedy testicular, back, foot and pelvic chronic pain issues with Men's Health Clinic"
      }
      slug="chronic-pain"
      prefix={prefix}
      schema={schema}
      mainTitle="MEN'S HEALTH CLINIC"
      title="Chronic Pain Treatments & Solutions"
      text="Restore quality of life with our tailor made treatment plans."
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "chronicpainmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "chronicpainbottomquiz",
        false,
        prefix
      )}
      headerBannerCTAlink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "chronicpainbannerquiz",
        false,
        prefix
      )}
      headerBannerImg={HeaderImg}
      headerBannerImgAlt="Chronic Pain Solutions"
      quizTitle="The Chronic Pain Clinic."
      quizText="Are you suffering with Chronic Pain? If so, you’re not alone. Today 3.2 million Australians live in chronic or disabling pain. With more than 4 million patients suffering from arthritis. Unfortunately, more than 68% of chronic pain patients are of working age.  Despite how prevalent Chronic Pain is in our community, many opt to suffer in silence; simply due to the difficulties and stresses that come with sourcing treatment solutions."
      quizTextSecond="At the Men’s Health Clinic, we pride ourselves on restoring the health and confidence of men Australia wide. Working with some of the best Licenced Chronic Pain Doctors in the world to offer bespoke treatment plans."
      aboutTreatmentTitle="What is Chronic Pain?"
      aboutTreatmentText={`Pain is regarded as chronic when it does not go away and is experienced by a patient on most days of the week for at least 3 months.  Although about 1 in 5 Australians suffer from chronic pain, symptoms of the condition can vastly differ for each patient. In some cases, Chronic Pain can be isolated in one region of the body and in other cases the pain can be spread across multiple regions of the body.\n
      Chronic Pain is associated with several conditions, such as arthritis, migraines, osteoporosis and even trauma. Chronic Pain can also include facial pain, muscle pain and pelvic pain, these are some symptoms of the diagnosis that can go unnoticed. Therefore, if you’re noticing persisting pain in your body, it’s time to speak to our doctors.`}
      aboutTreatmentImg={About1}
      aboutTreatmentImgAlt="What is Chronic Pain?"
      aboutTreatmentTitleSecond="Can Chronic Pain be treated?"
      aboutTreatmentTextSecond="Yes! Chronic Pain can be treated. At Men’s Health Clinic Australia, we treat patients as a whole. Not one aspect of a patient’s health is priority to another.  Our Chronic Pain management solutions are tailored to each individual patient and their needs using a multidisciplinary approach. Although Chronic Pain cannot be cured, we aim to help patients improve their quality of life by allowing them participate in day-to-day activities that seem impossible to achieve."
      aboutTreatmentImgSecond={About2}
      aboutTreatmentImgSecondAlt="Chronic Pain Treatment"
      secondDashedTitle="Chronic Pain Treatment"
      firstDashedTitle="Chronic Pain"
      processQuizLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "chronicpainprocessquiz",
        false,
        prefix
      )}
      faqsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "chronicpainfaqquiz",
        false,
        prefix
      )}
      reviewsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "chronicpainreviewsquiz",
        false,
        prefix
      )}
      aboutLink={returnLink(
        "Website-Form",
        "Supplementary-Quiz",
        "chronicpainsymptoms",
        false,
        prefix
      )}
      assessmentQuizBtnLink={`/${prefix}/treatment-cost`}
      iframeLink={`https://app.menshealthclinic.com/assessment/quiz?category_id=3&source=website-quiz&formcompleted=embedded-quiz&prefix=${prefix}&formurl=?chronicpainwebquiz`}
      iframeSizeClass="cpIframeSize"
      questions={CP_Questions}
      headerMarksText={headerMarksText}
      embeddedQuizHeaderText={
        <>
          <strong className="text-16 font-bold">150,000+ MEN</strong>{" "}
          successfully assessed with our FREE 2 minute, symptom checker quiz.
        </>
      }
      experts={experts}
      blueDashedText="Meet Dr. Jonathan Herald"
      ourProcessTitle="Our chronic pain solutions are easy, convenient and all about you!"
      expertsTitle="Our chronic pain specialists provide a high degree of quality, excellence and care."
      articles={articles}
    />
  )
}
export default ChronicPain

ChronicPain.propTypes = {
  prefix: PropTypes.string,
}
