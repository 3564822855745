import React from "react"
import ThankYouPdf from "../../components/PageComponents/fr-ca/thank-you-pdf"

const ThankYouPdfPage = () => {
  const prefix = "fr-ca"

  return <ThankYouPdf prefix={prefix} />
}

export default ThankYouPdfPage
