import React from "react"
import PrematureEjaculation from "../../../components/PageComponents/fr-ca/premature-ejaculation"

const PrematureEjaculationPage = () => {
  const prefix = "fr-ca"

  return <PrematureEjaculation prefix={prefix} />
}

export default PrematureEjaculationPage
