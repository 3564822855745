import * as React from "react"
import { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { TreatmentCostTextComponents } from "../../TextComponents/treatment-cost"
import { CustomButton } from "../../../components/common/CustomButton"
import { Checkbox } from "../../../components/common/Checkbox"
import { returnLink } from "../../../helpers/returnLink"
import { blockedLeadEmails } from "../../../helpers/blockedLeadEmails"
import ImgTop from "../../../images/treatment-cost/pricingBottom.webp"
import ImgBottom from "../../../images/treatment-cost/pricingBottom2.webp"
import SelectArrowDown from "../../../assets/common/selectArrowDown.svg"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import axios from "axios"

const TreatmentCost = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Coût du traitement",
    description:
      "Le traitement est abordable. Les besoins de chaque individu sont différents, et la gravité de votre condition ainsi que les causes probables diffèrent de celles d'autres personnes. En conséquence, vous avez besoin d'un programme de traitement personnalisé et géré. C'est la raison pour laquelle le coût des plans de traitement (y compris les médicaments) peut varier.",
    url: `https://menshealthclinic.com/${prefix}/treatment-cost`,
  }
  // WEBPAGE SCHEMA END //

  const [isChecked, setIsChecked] = useState(false)
  const [isSecondChecked, setIsSecondChecked] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [queryId, setQueryId] = useState("")

  const [isOpen, setIsOpen] = useState({})
  const [submitData, setSubmitData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    condition: "",
    comments: "",
    lead_source: "Website Form",
    web_form_completed: "Treatment-Cost",
    website_form_url: `https://menshealthclinic.com/${prefix}/treatment-cost`,
    country: "FR-CA",
  })

  const conditionsArr = [
    {
      text: "Dysfonction érectile",
      value: "Erectile Dysfunction",
    },
    {
      text: "Éjaculation précoce",
      value: "Premature Ejaculation",
    },
  ]

  const questions = [
    {
      question: "Quel est le coût du traitement de la dysfonction érectile ?",
      answer: TreatmentCostTextComponents.faqAnswerOne(prefix),
    },
    {
      question:
        "Que comprend un traitement contre l'éjaculation précoce ou la dysfonction érectile ?",
      answer: TreatmentCostTextComponents.faqAnswerTwo(prefix),
    },
    {
      question:
        "Y a-t-il des contrats pour les traitements de la dysfonction érectile ou de l'éjaculation précoce ?",
      answer: TreatmentCostTextComponents.faqAnswerThree(prefix),
    },
    {
      question: `Comment obtenir un prix personnalisé pour la dysfonction érectile ou l'éjaculation précoce ?`,
      answer: TreatmentCostTextComponents.faqAnswerFour(prefix),
    },
  ]

  const url = "https://api.menshealthclinic.com/"

  useEffect(() => {
    switch (submitData.condition) {
      case "Erectile Dysfunction":
        setQueryId("1")
        break
      case "Premature Ejaculation":
        setQueryId("2")
        break
    }
  }, [submitData.condition])

  const handleSubmit = async () => {
    if (!validateEmail(submitData.email)) {
      setEmailError("Veuillez entrer une adresse e-mail valide")
      return
    }

    if (blockedLeadEmails.includes(submitData.email)) {
      setEmailError("L'adresse e-mail ne peut pas être utilisée")
      return
    }

    try {
      const response = await axios.post(
        `${url}public/v1/inquiry/treatment-cost`,
        submitData
      )
      if (response.status === 200) {
        navigate(`/${prefix}/thank-you?queryId=${queryId}`)
      }
    } catch (e) {
      console.log(e)
    }
    return
  }

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(/\S+@\S+\.\S+/)
  }

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "treatmentcostform",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "treatmentcostform",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Coûts du traitement pour la dysfonction érectile (DE) et l'éjaculation précoce (PE) | MHC"
        description="Men's Health Clinic propose des plans de traitement personnalisés pour l'éjaculation précoce, la dysfonction érectile et les douleurs chroniques. Découvrez davantage d'informations sur les coûts de traitement."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/treatment-cost/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/treatment-cost/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/treatment-cost/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/treatment-cost/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/treatment-cost/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/treatment-cost/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/treatment-cost/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="mb-85 max680:mb-60">
        <div className="left-blue-semicircle relative h-full min-h-420 bg-030654 text-center max680:min-h-320">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="header__image--title relative z-10 inline-block pt-125 max680:pt-60">
            Coût du traitement
          </h1>
          <div className="mx-auto w-full max-w-700 px-100 max680:px-25">
            <p className="relative z-10 pb-25 text-F5F5F5">
              {TreatmentCostTextComponents.headerText(prefix)}
            </p>
          </div>
        </div>
      </article>
      <main>
        <section className="width__container mb-75 flex items-center justify-between max1000:flex-wrap max1000:justify-center">
          <div className="mr-75 w-full max-w-565 rounded-20 max1000:mx-auto max1000:mb-45 max1000:max-w-[380px]">
            <img
              className="min-h-[350px] rounded-20 object-cover"
              src={ImgTop}
              alt="Coût du traitement pour la dysfonction érectile (DE), l'éjaculation précoce (PE) et les douleurs chroniques."
            />
          </div>
          <form className="relative w-full max-w-675 rounded-md bg-white px-4 py-30 shadow sm:px-7 max600:p-15 max600:pb-75 max600:pt-25">
            <div className="relative mt-20 flex justify-between 900:mb-25 max900:flex-wrap">
              <div className="w-full 1000:mr-10">
                <label
                  htmlFor="first_name"
                  className="block text-14 font-medium text-gray-700"
                >
                  Prénom
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, first_name: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
              </div>
              <div className="w-full 1000:ml-10">
                <label
                  htmlFor="last_name"
                  className="block text-14 font-medium text-gray-700"
                >
                  Nom de famille
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, last_name: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
              </div>
            </div>
            <div className="relative flex justify-between 900:mb-25 max900:flex-wrap">
              <div className="w-full 1000:mr-10">
                <label
                  htmlFor="mobile"
                  className="block text-14 font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, mobile: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                  type="number"
                />
              </div>
              <div className="w-full 1000:ml-10">
                <label
                  htmlFor="email"
                  className="block text-14 font-medium text-gray-700"
                >
                  Adresse e-mail
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, email: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
                {emailError && (
                  <span className="text-red-500">{emailError}</span>
                )}
              </div>
            </div>
            <div className="mb-15">
              <p className="mb-10 font-medium">État de santé</p>
              <div className="flex flex-wrap">
                {conditionsArr.map((item, index) => {
                  return (
                    <Checkbox
                      className="mb-10 mr-15 flex justify-between"
                      text={item.text}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      setSubmitData={setSubmitData}
                      item={item.value}
                      key={index}
                    />
                  )
                })}
              </div>
            </div>
            <div className="relative mb-25 w-full">
              <label
                htmlFor="comments"
                className="block text-14 font-medium text-gray-700"
              >
                En quoi pouvons-nous vous aider ?
              </label>
              <textarea
                onChange={e =>
                  setSubmitData(prev => {
                    return { ...prev, comments: e.target.value }
                  })
                }
                className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300"
              />
            </div>
            <Checkbox
              className="mb-40 flex justify-between"
              text="En cliquant sur cette case, vous confirmez avoir lu et accepté nos conditions générales d'utilisation et notre politique de confidentialité, et vous acceptez d'être contacté de manière discrète et privée par téléphone, e-mail, SMS ou tout autre moyen électronique."
              isChecked={isSecondChecked}
              setIsChecked={setIsSecondChecked}
            />
            <div
              onClick={() => {
                if (isSecondChecked) {
                  return handleSubmit()
                }
                setIsSubmitError(true)
                return
              }}
            >
              {isSubmitError && (
                <span className="mb-10 inline-block text-FF5733">
                  Vous devez lire et accepter nos conditions avant de pouvoir
                  continuer.
                </span>
              )}
              <CustomButton
                className="mb-20 flex w-full justify-center rounded-md border border-transparent bg-030654 px-4 py-2 text-sm  font-medium text-white shadow-sm transition duration-300 hover:bg-[#00003D] focus:outline-none focus:ring-2 focus:ring-offset-2"
                text="Envoyer un message"
                link="#!"
              />
            </div>
          </form>
        </section>
        <section className="width__container mb-145 flex justify-between max1000:mb-60 max1000:flex-wrap max1000:justify-center">
          <div className="w-full max-w-675 flex-1">
            <p className="dashed__text--blue blueText mb-50 uppercase">
              Coût du traitement de la dysfonction érectile et de l'éjaculation
              précoce.
            </p>
            {questions.map((item, itemIndex) => {
              const index = itemIndex + 1
              return (
                <div
                  className={`mb-15 w-full rounded-10 bg-white038`}
                  key={index}
                >
                  <div
                    className={`relative cursor-pointer py-20 pl-20 pr-50 font-medium ${
                      isOpen[index] && "text-3FB1FC"
                    }`}
                    onClick={() =>
                      setIsOpen(prev => {
                        if (prev[index]) {
                          delete prev[index]
                          return { ...prev }
                        }
                        return { ...prev, [index]: index }
                      })
                    }
                  >
                    <SelectArrowDown className="absolute right-30 top-30" />
                    {index}
                    {" . "}
                    {item.question}
                  </div>
                  {isOpen[index] && (
                    <div className="cursor-pointer pb-20 pl-20 pr-50 text-14 text-333333">
                      {item.answer}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <div className="ml-75 w-full max-w-[380px] flex-1 self-center rounded-20 max800:hidden max1000:mx-auto">
            <img
              className="rounded-20"
              src={ImgBottom}
              alt="Coût du traitement de la dysfonction érectile et de l'éjaculation précoce."
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default TreatmentCost
