import React from "react"
import { Layout } from "../../../components/layout"
import { returnLink } from "../../../helpers/returnLink"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { Treatment } from "../../../components/Treatment/treatment"
import { TextCopies, experts } from "../../../helpers/countryTextCopies"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Experts } from "../../../components/Experts/experts"
import HonestyIcon from "../../../assets/about/honestyIcon.svg"
import QualityIcon from "../../../assets/about/qualityIcon.svg"
import CareIcon from "../../../assets/about/careIcon.svg"
import TeamworkIcon from "../../../assets/about/teamworkIcon.svg"
import ResearchIcon from "../../../assets/about/researchAndDevelopmentIcon.svg"
import { ProcessCardIcon } from "../../../components/ProcessCard/processCardIcon"
import { CustomButton } from "../../../components/common/CustomButton"
import { Video } from "../../../components/Video/video"
import { Faqs } from "../../../components/Faqs/faqs"
import { Schemas } from "../../../schemas/schemas"
import { Reviews } from "../../../components/Reviews/reviews"
import { graphql } from "gatsby"
import { Articles } from "../../../components/Articles/articles"

const PETreatmentLondon = ({ data }) => {
  const prefix = "uk"

  const processCards = [
    {
      titleText: "Quality",
      text: `Our treatments have the highest degree of quality in Australia. We use advanced, industry-leading formulations.`,
      img: QualityIcon,
    },
    {
      titleText: "Research & Development",
      text: "We continuously seek ways to enhance our treatment plans and improve the experiences of our patients by leveraging the most advanced technology available.",
      img: ResearchIcon,
    },
    {
      titleText: "Care",
      text: "Our treatments are tailor made to the individual patient. No two people are the same and that’s why treatment is created based on your individual needs.",
      img: CareIcon,
    },
    {
      titleText: "Teamwork",
      text: "Understanding your goals and collaboratively working towards achieving them are fundamental principles for our team.",
      img: TeamworkIcon,
    },
    {
      titleText: "Honesty",
      text: "Ensuring the qualification of our patients for treatment is of utmost importance to us. Your safety is our top priority, and we will always provide honest and transparent advice",
      img: HonestyIcon,
    },
  ]

  const questions = [
    {
      question:
        "What is Premature Ejaculation, and how common is it in London?",
      answer:
        "Premature ejaculation (PE) is a common sexual issue. In London, it affects a significant number of men. It's characterized by rapid ejaculation during sexual activity.",
    },
    {
      question:
        "How do I know if I need Premature Ejaculation treatment in London?",
      answer:
        "If you're experiencing persistent issues with premature ejaculation, such as difficulty controlling ejaculation, it may be time to consider treatment. A consultation with a specialist can help determine if treatment is necessary.",
    },
    {
      question:
        "What treatment options are available for Premature Ejaculation in London?",
      answer:
        "London offers a range of PE treatment options, including behavioral therapies, medications, and counseling. The choice of treatment depends on individual factors and preferences.",
    },
    {
      question:
        "How long does it take to see results with Premature Ejaculation treatment in London?",
      answer:
        "The timeline for improvement varies among individuals. Some may experience positive changes early in treatment, while others may take a few sessions. Your specialist will discuss expectations during your consultation.",
    },
    {
      question:
        "Are Premature Ejaculation treatments in London confidential and discreet?",
      answer:
        "Yes, confidentiality and discretion are essential aspects of PE treatment services in London. Healthcare providers and clinics prioritize patient privacy and respect their sensitive nature.",
    },
  ]

  const articles = data.allPrismicBothArticle.nodes
    .filter(article => article.lang === `en-${prefix === "uk" ? "gb" : prefix}`)
    .filter(article => article.tags.includes("Premature Ejaculation"))
    .slice(0, 2)

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "petreatmentlondonmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "petreatmentlondonbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Premature Ejaculation Treatment London | Men's Health Clinic"
        description="Experience effective Premature Ejaculation treatment in London. Our confidential care delivers lasting results for your intimate satisfaction. Contact us now!"
      />
      <JsonLd json={Schemas.localBusinessSchemaPELondon(prefix)} />
      <JsonLd json={Schemas.serviceSchemaPELondon(prefix)} />
      <section className="treatments__inner--container mb-60 flex flex-wrap justify-between pt-40 max1360:justify-center">
        <div className="mb-60 w-full 1330:mr-15 max480:px-25 max680:mb-85">
          <h1 className="section__title mb-20">
            Effective Premature Ejaculation Treatment in London
          </h1>
        </div>
      </section>
      <section className="width__container mb-145 max680:mb-60">
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Understanding Premature Ejaculation
            </h2>
            <p className="mb-20 whitespace-pre-line">
              <a
                href="https://en.wikipedia.org/wiki/Premature_ejaculation"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Premature Ejaculation
              </a>{" "}
              (PE) is an increasingly common sexual dysfunction that happens
              when a man ejaculates earlier than desired during sexual activity.
              This can lead to distress and dissatisfaction for both partners,
              impacting intimacy and relationships. While occasional episodes of
              PE are normal, persistent and recurrent instances may indicate the
              need for intervention. Effective management of this medical
              condition requires knowledge of the causes and possible
              treatments.
            </p>
            <p className="mb-20 whitespace-pre-line">
              At Men’s Health Clinic, we recognise the importance of addressing
              premature ejaculation with sensitivity and expertise. Our
              comprehensive approach involves exploring the underlying factors
              contributing to PE, including psychological and physiological
              aspects. By understanding the individual circumstances of each
              client, we tailor our treatments to provide effective solutions
              for lasting improvement.
            </p>
          </div>
        </div>
        <div className="mb-75 flex flex-row-reverse flex-wrap items-center justify-between max680:mb-45 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Factors contributing to Premature Ejaculation in London
            </h2>
            <p className="mb-20 whitespace-pre-line">
              Several factors can contribute to the development of premature
              ejaculation, ranging from psychological factors such as
              performance anxiety and stress to physiological issues like
              hypersensitivity of the penis or hormonal imbalances. Lifestyle
              factors like excessive alcohol use or smoking may also play a role
              in exacerbating PE symptoms. In the bustling city of London, where
              stress levels can be high and demand relentless, addressing
              premature ejaculation becomes even more critical for maintaining
              intimate relationships and overall well-being.
            </p>
            <p className="mb-20 whitespace-pre-line">
              At{" "}
              <a
                href="https://menshealthclinic.com/uk/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                Men’s Health Clinic
              </a>
              , we recognise the unique challenges faced by individuals in
              London and offer specialised treatments to address premature
              ejaculation effectively. Our skilled medical professionals
              collaborate closely with patients to pinpoint and treat the
              particular causes of their PE, offering them individualised care
              and support all the way through the course of treatment.
            </p>
          </div>
        </div>
        <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
          <div className="w-full">
            <h2 className="section__title mb-20">
              Benefits of Premature Ejaculation Therapy in London
            </h2>
            <p className="mb-20 whitespace-pre-line">
              Premature ejaculation therapy offers a range of benefits for
              individuals seeking lasting relief from PE symptoms. Our
              confidential and effective treatments are designed to improve
              ejaculatory control, enhance sexual satisfaction, and restore
              confidence in intimate relationships. By addressing the underlying
              causes of premature ejaculation, our therapy aims to provide
              long-term solutions for improved sexual performance and overall
              well-being.
            </p>
            <p className="mb-20 whitespace-pre-line">
              Experience the benefits of{" "}
              <a
                href="https://menshealthclinic.com/uk/treatments/pe-treatment-london/"
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 underline underline-offset-4 hover:text-blue-500"
              >
                premature ejaculation treatment in London
              </a>{" "}
              with Men’s Health Clinic. Our discreet and professional services
              are tailored to meet your needs, ensuring a comfortable and
              supportive environment for addressing sensitive sexual health
              concerns. Don’t let premature ejaculation impact your intimate
              relationships any longer – contact us now for confidential and
              effective treatment options.
            </p>
          </div>
        </div>
      </section>
      <Treatment
        isBtn={true}
        blueText={"Our Treatments"}
        titleText={"View our range of bespoke treatment plans."}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "treatmenttreatmentquiz",
            false,
            prefix
          )
        }
        prefix={prefix}
      />
      <OurProcess prefix={prefix} />
      <Reviews link={
        returnLink(
          "Website-Quiz",
          "Supplementary-Quiz",
          "treatmenttreatmentquiz",
          false,
          prefix
        )
      } prefix={prefix} />
      <Experts
        experts={experts}
        slider={true}
        className="flex-wrap"
        prefix={prefix}
      />
      <section className="width__container mb-125 max680:mb-60">
        <div className="flex flex-wrap justify-between max1360:justify-center">
          <div className="about__process--title-box">
            <h2 className="dashed__text--blue treatments__blue--text blueText">
              Our Principles / Values
            </h2>
            <h2 className="section__title company__title">
              Our values align with yours.
            </h2>
          </div>
          {processCards.map((item, index) => (
            <ProcessCardIcon
              Component={item.img}
              titleText={item.titleText}
              text={item.text}
              key={index}
              className={index % 2 === 0 ? "1000:ml-5" : "1000:mr-5"}
            />
          ))}
        </div>

        <CustomButton
          className="orangeFilled slim__btn ml-[50%] mt-40 translate-x-[-50%] max680:mt-0"
          text="Online Assessment"
          link={
            returnLink(
              "Website-Quiz",
              "Supplementary-Quiz",
              "aboutvaluesquiz",
              false,
              prefix
            )
          }
        />
      </section>
      <Video blueDashedText={"Meet Dr. Fred Edwards"} prefix={prefix} />
      <Faqs
        blueText="FAQS"
        titleText="Frequently Asked Questions"
        isButton={true}
        bottomText={true}
        className="mb-100"
        prefix={prefix}
        link={
          returnLink(
            "Website-Quiz",
            "Supplementary-Quiz",
            "trtfaqsquiz",
            false,
            prefix
          )
        }
        questions={questions}
      />
      <Articles
        blueText="Articles"
        titleText="Learn More About Premature Ejaculation"
        slider={true}
        buttonClass="bottom-0"
        className="mb-145 pb-75"
        navLink={`/${prefix}/resources/#resourcesArticles`}
        articles={articles}
        prefix={prefix}
      />
    </Layout>
  )
}
export default PETreatmentLondon

export const pageQuery = graphql`
  query UkindexQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`
