import React from "react"
import Home from "../../components/PageComponents/fr-ca/index"
import { graphql } from "gatsby"

const Index = ({ data }) => {
  const prefix = "fr-ca"

  return <Home data={data.allPrismicWebsiteArticle} prefix={prefix} />
}

export const pageQuery = graphql`
  query CaIndexQuery {
    allPrismicWebsiteArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
          date
        }
        lang
        tags
        uid
      }
    }
    allPrismicBothArticle {
      nodes {
        data {
          featured_image {
            url
          }
          text {
            richText
          }
          title {
            text
          }
          byliner {
            richText
          }
        }
        lang
        tags
        uid
      }
    }
  }
`
export default Index
