import React, { useEffect, useState } from "react"
import { Layout } from "../layout"
import { returnLink } from "../../helpers/returnLink"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Author = ({ prefix, data, authorInfo }) => {
  const allArticles = [
    ...data.allPrismicBothArticle.nodes,
    ...data.allPrismicWebsiteArticle.nodes,
  ]

  const [authorArticles, setAuthorArticles] = useState([])

  const checkRegionLocale = (lang, prefix) => {
    switch (prefix) {
      case "au":
        return lang === `en-au`
      case "ca":
        return lang === `en-ca`
      case "nz":
        return lang === `en-nz`
      case "uk":
        return lang === `en-gb`
      case "fr-ca":
        return lang === `fr-ca`
    }
  }

  useEffect(() => {
    setAuthorArticles(
      allArticles
        .filter(article => checkRegionLocale(article.lang, prefix))
        .filter(
          article =>
            article?.data?.byliner?.richText[0]?.text === authorInfo.filterText
        )
    )
  }, [])

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "authormenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "authorbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <main>
        <div className="width__container mb-200 mt-40 sm:mt-80">
          <section className="mx-auto mb-40 flex w-full max-w-1000 flex-col items-center gap-[40px] sm:flex-row">
            <img
              src={authorInfo.img}
              alt={`Picture of ${authorInfo.name}`}
              className="mx-auto h-[160px] w-[160px] rounded-full object-cover"
            />
            <article className="flex flex-col gap-2">
              <h1 className="text-xl font-bold">{authorInfo.name}</h1>
              <h2 className="font-medium">{authorInfo.title}</h2>
              <p>{authorInfo.bio}</p>
            </article>
          </section>
          <hr className="mb-40" />
          <section className="mx-auto flex w-full max-w-1000 flex-col gap-40">
            {authorArticles.map((article, index) => (
              <Link to={`/${prefix}/resource/${article?.uid}`} key={index}>
                <article className="flex flex-col items-center sm:items-start sm:gap-40 lg:flex-row">
                  <img
                    src={article?.data?.featured_image?.url}
                    alt={
                      article?.data?.featured_image?.alt ||
                      "Men's Health Clinic resource article image"
                    }
                    className="mb-12 w-full rounded-lg lg:max-w-400"
                  />
                  <div>
                    <div className="mb-12">
                      <h3>{article?.data?.title?.text}</h3>
                      <p>Published {article?.data?.date}</p>
                      <p>
                        {article?.data?.text?.richText[0]?.text?.substr(0, 200)}
                        ...
                      </p>
                    </div>
                    <div className="hidden gap-2 sm:flex">
                      {article?.tags?.map(tag => (
                        <p
                          key={tag}
                          className="rounded-md bg-[#494a4d] bg-opacity-50 p-2 text-sm text-white"
                        >
                          {tag}
                        </p>
                      ))}
                    </div>
                  </div>
                </article>
              </Link>
            ))}
          </section>
        </div>
      </main>
    </Layout>
  )
}

Author.propTypes = {
  prefix: PropTypes.string,
  data: PropTypes.object,
  authorInfo: PropTypes.object,
}

export default Author
