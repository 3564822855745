import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { AboutStatistics } from "../../../components/AboutStatistics/aboutStatistics"
import { OurProcess } from "../../../components/OurProcess/ourProcess"
import { Experts } from "../../../components/Experts/experts"
import { PatientStories } from "../../../components/PatientStories/patientStories"
import { Video } from "../../../components/Video/video"
import { Faqs } from "../../../components/Faqs/faqs"
import { Reviews } from "../../../components/Reviews/reviews"
import { HeaderMarks } from "../../../components/common/HeaderMarks"
import { CustomButton } from "../../../components/common/CustomButton"
import { TextCopies } from "../../../helpers/countryTextCopies"
import WavyHeader from "../../../assets/treatments/treatmentsInnerHeadWavy.svg"
import HeaderCircleRight from "../../../assets/treatments/quizHeaderCircleRight.svg"
import HeaderRedRight from "../../../images/treatments/quizHeaderRedRight.webp"
import QuizHeaderLeft from "../../../images/treatments/quizHeaderLeft.webp"
import Doctor from "../../../images/treatments/quizDoctor.webp"
import SmallDoctor from "../../../images/treatments/smallDoctor.webp"

let windowObj
if (typeof window !== `undefined`) {
  windowObj = window
}

const TreatmentsInner = ({
  prefix,
  schema,
  mainTitle,
  title,
  text,
  headerLink,
  footerLink,
  headerBannerCTAlink,
  headerBannerImg,
  headerBannerImgAlt,
  quizTitle,
  quizText,
  quizTextSecond,
  aboutTreatmentTitle,
  aboutTreatmentText,
  aboutTreatmentImg,
  aboutTreatmentImgAlt,
  aboutTreatmentTitleSecond,
  aboutTreatmentTextSecond,
  aboutTreatmentImgSecond,
  aboutTreatmentImgSecondAlt,
  faqsLink,
  reviewsLink,
  aboutLink,
  iframeLink,
  questions,
  secondDashedTitle = "About The Treatment",
  firstDashedTitle,
  headerMarksText,
  embeddedQuizHeaderText,
  experts,
  aboutTreatmentTitleThird,
  aboutTreatmentTextThird,
  patientsStories,
  blueDashedText,
  seoTitle,
  seoDescription,
  slug,
  faqSeoLdJSON,
  videoSeoLdJSON,
  ourProcessTitle,
  expertsTitle,
  faqTitleText = "Frequently Asked Questions",
}) => {
  return (
    <Layout headerLink={headerLink} footerLink={footerLink} prefix={prefix}>
      <GatsbySeo
        title={seoTitle}
        description={seoDescription}
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: `https://menshealthclinic.com/au/resource/${slug}/`,
          },
          {
            hrefLang: "en-au",
            href: `https://menshealthclinic.com/au/resource/${slug}/`,
          },
          {
            hrefLang: "en-ca",
            href: `https://menshealthclinic.com/ca/resource/${slug}/`,
          },
          {
            hrefLang: "en-nz",
            href: `https://menshealthclinic.com/nz/resource/${slug}/`,
          },
          {
            hrefLang: "en-ph",
            href: `https://menshealthclinic.com/ph/resource/${slug}/`,
          },
          {
            hrefLang: "en-gb",
            href: `https://menshealthclinic.com/uk/resource/${slug}/`,
          },
          {
            hrefLang: "fr-ca",
            href: `https://menshealthclinic.com/fr-ca/resource/${slug}/`,
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="relative mb-85 flex max680:mb-60">
        <div className="header__left-semicircle relative flex h-auto w-full justify-center overflow-hidden px-25 py-80 pl-100 680:bg-030654 1100:w-6/12 max680:py-60 max1100:h-full max1100:min-h-320 max1100:justify-center max1100:pl-25">
          <img
            className="absolute left-0 top-0 z-[-1] h-full w-full 680:hidden"
            style={{
              backgroundImage: `url(${headerBannerImg})`,
              backgroundImage: `linear-gradient(to top,rgba(3, 6, 84, 0.4),rgba(3, 6, 84, 1)),url(${headerBannerImg})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
            }}
          />
          <WavyHeader className="absolute bottom-0 right-min200 hidden 680:block" />
          <div className="w-full max-w-565">
            <p className="header__image--dash-text">{mainTitle}</p>
            <h1 className="header__image--title">{title}</h1>
            <p className="mb-25 text-F5F5F5">{text}</p>
            <HeaderMarks
              className="mb-50 block text-F5F5F5 max680:mb-25"
              headerMarksText={headerMarksText}
              firstText={`Real ${
                prefix === "ph" || prefix === "nz" ? "licensed" : "licenced"
              } ${TextCopies.countryAsAdjective(prefix)} doctors.`}
            />
            <CustomButton
              className="orangeFilled slim__btn"
              text="Commencez l'évaluation"
              link={headerBannerCTAlink}
            />
          </div>
        </div>
        <img
          className="hidden h-800 w-6/12 1100:block"
          src={headerBannerImg}
          alt={headerBannerImgAlt}
          style={{
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
      </article>
      <main>
        <section className="treatments__inner--container mb-60 flex flex-wrap justify-between max1360:justify-center">
          <div className="mb-60 w-full max-w-565 1330:mr-15 max480:px-25 max680:mb-85">
            <p className="dashed__text--blue treatments__blue--text blueText 1360:pr-10">
              15,000+ HOMMES SATISFAITS
            </p>
            <h2 className="section__title mb-20">{quizTitle}</h2>
            <p
              className={`whitespace-pre-line ${
                quizTextSecond ? "mb-20" : "mb-60"
              }`}
            >
              {quizText}
            </p>
            <p className="mb-60">{quizTextSecond}</p>
            <AboutStatistics itemClass="mb-25" prefix={prefix} />
          </div>
          <div className="relative h-[500px] w-full max-w-565 530:h-[680px]">
            <div className="absolute top-0 flex h-120 w-full items-end rounded-t-20 bg-030654 px-50 680:items-center max680:px-15">
              <HeaderCircleRight className="absolute right-0 top-0" />
              <img src={HeaderRedRight} className="absolute bottom-0 right-0" />
              <img
                src={QuizHeaderLeft}
                className="absolute bottom-0 left-0 h-[95%]"
              />
              <div className="translate__left-half absolute top-min60 flex h-100 w-100 items-end justify-center overflow-hidden rounded-50 bg-white 680:hidden">
                <img className="h-100" src={SmallDoctor} />
              </div>
              <p className="relative z-10 mx-auto pb-20 text-center text-14 font-medium leading-5 text-F5F5F5 680:pb-0">
                {embeddedQuizHeaderText}
              </p>
            </div>
            <img
              className="1330:right-minus-190 absolute bottom-40 right-[-240px] z-10 h-[400px] max-h-[400px] object-cover 1330:h-auto max850:hidden"
              src={Doctor}
              alt="Erectile Dysfunction Doctor - Men's Health Clinic"
            />
            <div className="h-[100%] overflow-y-auto rounded-20">
              <iframe
                className={`mx-auto h-[100%] w-full pt-70 ${
                  prefix === "ph" ? "530:h-[100%]" : ""
                }`}
                src={iframeLink}
                title="quiz"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="width__container mb-145 max680:mb-60">
          <div className="mb-125 flex flex-wrap items-center justify-between max1200:mb-60 max1200:justify-center">
            <div className="w-full max-w-550 pr-10">
              <p className="dashed__text--blue treatments__blue--text blueText">
                {firstDashedTitle}
              </p>
              <h2 className="section__title mb-20">{aboutTreatmentTitle}</h2>
              <p className="mb-20 whitespace-pre-line">{aboutTreatmentText}</p>
            </div>
            <img
              className="h-280 w-full rounded-20 object-cover 600:h-450 600:max-w-450 1300:max-w-550"
              src={aboutTreatmentImg}
              alt={aboutTreatmentImgAlt}
            ></img>
          </div>
          <div className="mb-75 flex flex-row-reverse flex-wrap items-center justify-between max680:mb-45 max1200:justify-center">
            <div className="w-full max-w-550 pl-10">
              <p className="dashed__text--blue treatments__blue--text blueText">
                {secondDashedTitle}
              </p>
              <h3 className="section__title mb-20">
                {aboutTreatmentTitleSecond}
              </h3>
              <p className="mb-50 whitespace-pre-line">
                {aboutTreatmentTextSecond}
              </p>
              {aboutTreatmentTitleThird && (
                <h3 className="section__title mb-20">
                  {aboutTreatmentTitleThird}
                </h3>
              )}
              {aboutTreatmentTextThird && (
                <p className="mb-20 whitespace-pre-line">
                  {aboutTreatmentTextThird}
                </p>
              )}
            </div>
            <img
              className="h-280 w-full rounded-20 object-cover 600:h-450 600:max-w-450 1300:max-w-550"
              src={aboutTreatmentImgSecond}
              alt={aboutTreatmentImgSecondAlt}
            ></img>
          </div>
          <div className="flex justify-center">
            <CustomButton
              className="orangeFilled slim__btn"
              text="Vérificateur de symptômes"
              link={
                TextCopies.assessmentQuizLinks(prefix)
                  ? TextCopies.assessmentQuizLinks(prefix)
                  : aboutLink
              }
            />
          </div>
        </section>
        <OurProcess
          prefix={prefix}
          title={ourProcessTitle}
          treatmentType={title}
        />
        <Experts experts={experts} title={expertsTitle} prefix={prefix} />
        <PatientStories stories={patientsStories} prefix={prefix} />
        <Video
          blueDashedText={blueDashedText}
          prefix={prefix}
          ldJSON={videoSeoLdJSON}
          treatmentType={title}
        />
        <Faqs
          blueText="FAQS"
          titleText={faqTitleText}
          bottomText={true}
          link={faqsLink}
          questions={questions}
          prefix={prefix}
          ldJSON={faqSeoLdJSON}
        />
        {windowObj &&
        windowObj.location.pathname.split("/").at(-2) === "chronic-pain" &&
        prefix === "au" ? null : (
          <Reviews
            className="mb-185 max680:mb-145"
            link={
              TextCopies.assessmentQuizLinks(prefix)
                ? TextCopies.assessmentQuizLinks(prefix)
                : reviewsLink
            }
            prefix={prefix}
          />
        )}
      </main>
    </Layout>
  )
}

export default TreatmentsInner
