import React from "react"
import Author from "../../../../components/PageComponents/author"
import DrSurenNaidoo from "../../../../images/experts/DrSuren.webp"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const DrSurenNaidooAuthorPage = () => {
  const prefix = "au"
  const authorInfo = {
    name: "Dr. Suren Naidoo",
    title: "MRCGP, MBBCh",
    bio: "As a private GP and Aesthetic Practitioner, Dr. Suren practices medicine with a holistic approach by listening and dedicating the best possible care to his patients.",
    img: DrSurenNaidoo,
    filterText: "Dr. Suren Naidoo, MRCGP, MBBCh"
  }
  const data = useStaticQuery(graphql`
    query DrNaidooAUQuery {
      allPrismicWebsiteArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicWebsiteArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
      allPrismicBothArticle {
        nodes {
          data {
            featured_image {
              url
              alt
            }
            text {
              richText
            }
            title {
              text
            }
            byliner {
              richText
            }
            date
            body {
              ... on PrismicBothArticleDataBodyGeneralCard {
                primary {
                  title {
                    text
                  }
                  description {
                    text
                  }
                }
              }
            }
          }
          lang
          tags
          uid
        }
      }
    }
  `)

  return <Author prefix={prefix} data={data} authorInfo={authorInfo} />
}



DrSurenNaidooAuthorPage.propTypes = {
  data: PropTypes.object
}

export default DrSurenNaidooAuthorPage
