import * as React from "react"

export const ImageTextEllipse = ({
  text,
  textArr = [],
  className,
  onClick,
}) => {
  return text ? (
    <span onClick={onClick} className={`max480:text-14 ${className}`}>
      {text}
    </span>
  ) : (
    <span onClick={onClick} className={`${className}`}>
      {textArr.map((item, index) => (
        <span
          className={`block max480:text-14 ${textArr.length > 1 && "pb-5"}`}
          key={index}
        >
          {item}
        </span>
      ))}
    </span>
  )
}
