import * as React from "react"
import TreatmentsInner from "./treatmentsInner"
import { TextCopies, experts } from "../../helpers/countryTextCopies"
import {
  PETextComponents,
  stories,
} from "../TextComponents/premature-ejaculation"
import { returnLink } from "../../helpers/returnLink"
import HeaderImg from "../../images/premature-ejaculation/peHeader.webp"
import About1 from "../../images/premature-ejaculation/peImg1.webp"
import About2 from "../../images/premature-ejaculation/peImg2.webp"
import NZHeaderImg from "../../images/nz/peHeader.webp"
import NZAbout1 from "../../images/nz/peImg1.webp"
import PropTypes from "prop-types"
import { Schemas } from "../../schemas/schemas"

const faqLdJSON = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How does Men's Health Clinic help?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Men’s Health Clinic is an online telehealth clinic that serves thousands of men all across Australia. Our team of licensed Australian doctors are experts in men’s health, with decades of experience in the field. We provide personalised treatment plans to patients struggling with Premature Ejaculation. We empower men to reclaim their sex lives and feel more confident inside and outside of the bedroom. To learn more about the tailored solutions from Men’s Health Clinic, call us today on +61 1300 959 733.",
      },
    },
    {
      "@type": "Question",
      name: "How can I start Premature Ejaculation treatment?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It can be awkward and uncomfortable to discuss these topics. Many men can be treated successfully and easily with affordable treatment options. The hardest part is taking the first step and talking to a doctor. At Men’s Health Clinic, our doctors have an extensive background in men’s health and understand the sensitive nature of sexual dysfunction. Schedule a virtual consultation with our medical team.",
      },
    },
    {
      "@type": "Question",
      name: "What are the best treatment options for PE and how can I cure it?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Just like how no two men are the same, there is no one size fits all medication or treatment plan to solve Premature Ejaculation. Our doctors at Men’s Health Clinic understand this, which is why they work with you to create custom treatment plans for higher chances of success. Depending on the cause and severity of your Premature Ejaculation, you may opt for a more natural treatment.\nThis includes:\n- behavioural exercises and techniques\n- masturbating before sex\n- Kegel exercises\n- Stop-start technique\n- Squeeze technique\n- Counselling: Your mental health and sexual health are deeply interconnected. Just like how psychological issues can trigger Premature Ejaculation, your Premature Ejaculation can go on to create mental health problems, like depression and anxiety.\nThere’s a lot of societal pressure on men and coupled with daily stressors, this may manifest into sexual dysfunction. If you’re dealing with Premature Ejaculation and mental health troubles, your doctor will recommend that you speak with a mental health professional.\nWith therapy for Premature Ejaculation, you can learn how to cope with the psychological worries that are causing your condition. You’ll also gain the tools to deal with the emotional distress brought on by the Premature Ejaculation. Sometimes, your partner might participate in therapy with you.",
      },
    },
    {
      "@type": "Question",
      name: "Can Premature Ejaculation be treated?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, Premature Ejaculation can be treated. However, as no two men are the same, there is not a one size fits all treatment plan that works for everyone. This means, for some men, finding the right treatment combination for you may requires time and patience. Your case manager will periodically check in with you to help you get the results you desire.",
      },
    },
    {
      "@type": "Question",
      name: "How do I get diagnosed for PE?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "To receive a PE diagnosis here at the Men’s Health Clinic, all you need to do is complete an online assessment quiz or schedule a consultation online. You’ll have a complete discreet telehealth consult with a real doctor over the phone. To get a better understanding of what will work best for you, they’ll ask you questions based on your lifestyle, sex life, the symptoms of Premature Ejaculation you’re experiencing as well as details about your relationship.\nAfter all, it’s an intimate act for the both of you. Once you have been diagnosed and qualify for our treatment plan, our Men’s Health Clinic doctors will work with you to create an effective, customised treatment plan just for you.",
      },
    },
    {
      "@type": "Question",
      name: "What causes Premature Ejaculation?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Periodically, you might notice that you orgasm quickly during sex. It’s normal for this to occur occasionally. It can happen if you haven’t ejaculated in a while or if you’re sleeping with a new sexual partner for the first time. It can be caused by psychological factors such as stress, relationship problems, lack of self-confidence and even unrealistic expectations about sexual performance. Premature Ejaculation can also be triggered by physical factors such as diabetes, hypertension, and hyperthyroidism (1).\nIf you are frustrated because you’re consistently ejaculating earlier than you’d like(often within the first three minutes of sex), then you should be evaluated for Premature Ejaculation.",
      },
    },
  ],
}

const videoLdJSON = {
  "@context": "https://schema.org",
  "@type": "VideoObject",
  name: "Meet Dr. Fred Edwards",
  description:
    "Take a look into the world of the Men's Health Clinic, an established telehealth clinic with over 10 years of experience, a team of 150+ members, and a track record of treating 150,000 men worldwide.",
  thumbnailUrl: [
    "https://i.vimeocdn.com/filter/overlay?src0=https%3a%2f%2fi.vimeocdn.com%2fvideo%2f1390952176-93a902607d3811d11a66de5828ce204fa39a69119d3abbe36d8cc602a2eb6193-d_1280x720&src1=https%3a%2f%2ff.vimeocdn.com%2fimages_v6%2fshare%2fplay_icon_overlay.png",
  ],
  uploadDate: "2022-08-24T08:00:00+08:00",
  contentUrl:
    "https://menshealthclinic.com/au/treatments/premature-ejaculation",
  embedUrl:
    "https://player.vimeo.com/video/635035287?h=1479562035&title=0&byline=0&portrait=0",
  potentialAction: {
    "@type": "SeekToAction",
    target:
      "https://menshealthclinic.com/au/treatments/premature-ejaculation={seek_to_second_number}",
    "startOffset-input": "required name=seek_to_second_number",
  },
}

const PrematureEjaculation = ({ data, prefix }) => {
  const articles = data.nodes
    .filter(article => article.tags.includes("Premature Ejaculation"))
    .filter(article => article.lang === `en-${prefix === "uk" ? "gb" : prefix}`)
    .slice(0, 2)

  const localBusinessSchema = Schemas.localBusinessSchemaPE(prefix)
  const serviceSchema = Schemas.serviceSchemaPE(prefix)

  const headerBannerImg = React.useMemo(() => {
    switch (prefix) {
      case "nz":
        return NZHeaderImg
      default:
        return HeaderImg
    }
  }, [prefix])

  const aboutTreatmentImg = React.useMemo(() => {
    switch (prefix) {
      case "nz":
        return NZAbout1
      default:
        return About1
    }
  }, [prefix])

  const PEQuizLink = React.useMemo(() => {
    switch (prefix) {
      default:
        return `https://app.menshealthclinic.com/assessment/quiz?category_id=2&source=website-quiz&formcompleted=embedded-quiz&prefix=${prefix}&formurl=?pewebquiz`
    }
  }, [prefix])

  let PETitle = "PE Treatment in Australia"
  let PEHeaderBannerImgAlt = "Premature Ejaculation Solutions"

  if (prefix === "ca") {
    PETitle = "Premature Ejaculation Canada - Effective Treatment & Care"
    PEHeaderBannerImgAlt =
      "Premature Ejaculation Solutions Canada - Men's Health Clinic"
  } else if (prefix === "nz") {
    PETitle = "Premature Ejaculation NZ - Treatment & Care"
  } else if (prefix === "uk") {
    PETitle = "Effective Premature Ejaculation Treatment"
  }

  const returnMetaInfo = prefix => {
    switch (prefix) {
      case "au":
        return {
          title: "PE Treatment Australia: Solutions for Premature Ejaculation",
          description:
            "Explore our  effective premature ejaculation treatments in Australia. Find help for PE with expert solutions and advice. Don't let PE hold you back.",
        }
      case "ca":
        return {
          title: "Premature Ejaculation Canada | Restore Your Confidence",
          description:
            "Explore the causes, treatments, and support for premature ejaculation in Canada. Get personalized help from Men's Health Clinic for effective solutions.",
        }
      case "nz":
        return {
          title: "Premature Ejaculation NZ | Effective Solutions & Expert Care",
          description:
            "Explore effective solutions for Premature Ejaculation in NZ. Regain control and confidence with our expert treatments tailored for you. Contact us now!",
        }
      case "uk":
        return {
          title: "Effective Premature Ejaculation Treatment: Delay the Moment",
          description:
            "Discover effective premature ejaculation treatment options in the UK to enhance your intimacy and regain control. Get lasting results with our expert solutions.",
        }
    }
  }

  return (
    <TreatmentsInner
      prefix={prefix}
      schema={localBusinessSchema}
      schema2={serviceSchema}
      mainTitle="MEN'S HEALTH CLINIC"
      title={PETitle}
      seoTitle={returnMetaInfo(prefix).title}
      seoDescription={returnMetaInfo(prefix).description}
      slug="premature-ejaculation"
      faqSeoLdJSON={faqLdJSON}
      videoSeoLdJSON={videoLdJSON}
      text="Last longer and restore the confidence you deserve."
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "pemenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "pebottomquiz",
        false,
        prefix
      )}
      headerBannerCTAlink={
        returnLink(
          "Website-Quiz",
          "Banner-Quiz",
          "pebannerquiz",
          false,
          prefix
        )
      }
      headerBannerImg={headerBannerImg}
      headerBannerImgAlt={PEHeaderBannerImgAlt}
      quizTitle="Men's Health Clinic has been helping men with treatment for Premature Ejaculation for over a decade."
      quizText={PETextComponents.quizText(prefix)}

      aboutTreatmentImg={aboutTreatmentImg}
      aboutTreatmentImgAlt="Premature Ejaculation Treatment Canada - Men's Health Clinic"
      aboutTreatmentTextSecond={PETextComponents.aboutTreatmentTextSecond(
        prefix
      )}
      aboutTreatmentImgSecond={About2}
      aboutTreatmentImgSecondAlt="Premature Ejaculation Diagnosis - Men's Health Clinic"
      processQuizLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "peprocessquiz",
        false,
        prefix
      )}
      faqsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "pefaqquiz",
        false,
        prefix
      )}
      reviewsLink={returnLink(
        "Website-Quiz",
        "Supplementary-Quiz",
        "pereviewsquiz",
        false,
        prefix
      )}
      aboutLink={returnLink(
        "Website-Form",
        "Supplementary-Quiz",
        "pesymptoms",
        false,
        prefix
      )}
      assessmentQuizBtnLink={`/${prefix}/treatment-cost`}
      iframeLink={PEQuizLink}
      iframeSizeClass="peIframeSize"
      questions={PETextComponents.PE_Questions(prefix)}
      headerMarksText={PETextComponents.headerMarksText(prefix)}
      embeddedQuizHeaderText={
        <>
          <strong className="text-16 font-bold">150,000+ MEN</strong>{" "}
          successfully assessed with our FREE 2 minute, symptom checker quiz.
        </>
      }
      experts={experts}
      patientsStories={stories}
      blueDashedText="Meet Dr. Fred Edwards"
      firstDashedTitle="About Our Treatment"
      aboutTreatmentTitle={`Effective Premature Ejaculation Treatment at ${prefix === "uk" ? "MHC" : ""
        } Men's Health Clinic.`}
      secondDashedTitle="Premature Ejaculation (PE)"
      aboutTreatmentTitleSecond="What is Premature Ejaculation"
      articles={articles}
      page="pe"
    />
  )
}
export default PrematureEjaculation

PrematureEjaculation.propTypes = {
  prefix: PropTypes.string,
}
