import * as React from "react"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { ContactCard } from "./contactCard/contactCard"
import { ContactUsBox } from "./contactUsBox/contactUsBox"
import { Treatment } from "../../../components/Treatment/treatment"
import { Faqs } from "../../../components/Faqs/faqs"
import { TextCopies } from "../../../helpers/countryTextCopies"
import { ContactUsTextComponents } from "../../TextComponents/contact-us"
import { returnLink } from "../../../helpers/returnLink"
import News from "../../../assets/contact-us/contactsNews.svg"
import Sales from "../../../assets/contact-us/contactsSales.svg"
import Support from "../../../assets/contact-us/contactsSupport.svg"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import Letter from "../../../assets/contact-us/contactsLetter.svg"
import Mobile from "../../../assets/contact-us/contactsMobile.svg"
import ContactUsWavy from "../../../assets/contact-us/contactUsWavy.svg"
import "../../../styles/contact.scss"

const arr = [
  { img: News, title: "Siège social" },
  { img: Sales, title: "Soutien de la clinique" },
  { img: Support, title: "Presse et actualités" },
]

const Contacts = ({ prefix }) => {
  // CONTACTPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    name: "Contact",
    description:
      "Parlez à nos membres d'équipe sympathiques qui peuvent vous guider dans la bonne direction.",
    url: `https://menshealthclinic.com/${prefix}/contact-us`,
  }
  // CONTACTPAGE SCHEMA END //

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "Banner-Quiz",
        "contactusmenuquiz",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "Bottom-Quiz",
        "contactusbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="MHC Men's Health Clinic | Leaders dans le traitement de la dysfonction érectile (ED) et de l'éjaculation précoce (PE)."
        description="Men's Heath Clinic aide pour la dysfonction érectile, l'éjaculation précoce et les douleurs chroniques, avec des plans de traitement personnalisés. Contactez nos médecins agréés dès aujourd'hui."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/contact-us/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/contact-us/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/contact-us/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/contact-us/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/contact-us/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/contact-us/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/contact-us/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article>
        <div className="left-blue-semicircle relative h-420 bg-030654 text-center">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <div className="width__container">
            <h1 className="header__image--title inline-block pt-85">Contact</h1>
            <p className="m-auto w-full max-w-500 text-F5F5F5">
              {ContactUsTextComponents.headerText(prefix)}
            </p>
          </div>
        </div>
        <div className="width__container mb-75 mt-min175 flex justify-between max900:flex-wrap max900:justify-center">
          {arr.map((item, index) => {
            return (
              <ContactCard
                ImgComponent={item.img}
                title={item.title}
                prefix={prefix}
                key={index}
              />
            )
          })}
        </div>
      </article>
      <main>
        <section className="relative mb-350 900:mb-150 max600:mb-310">
          <div className="absolute left-0 top-0 h-[100%] w-6/12 bg-E0E0E0 max1200:w-full"></div>
          <div className="right__blue-bg absolute right-0 top-0 h-[100%] w-6/12 bg-030654 max1200:hidden"></div>
          <div className="width__container flex justify-between py-100 max1200:flex-wrap max1200:justify-center max1200:py-50">
            <div className="relative mt-100 w-full max-w-420 max1200:mb-20 max1200:mt-0">
              <p className="dashed__text--blue blueText">Contactez-nous</p>
              <h2 className="section__title">
                Entrez en contact dès aujourd'hui !
              </h2>
              <p className="mb-40">
                Pour des questions générales ou pour prendre rendez-vous en
                consultation avec un médecin canadien agréé, veuillez remplir ce
                formulaire.
              </p>
              <div className="mb-20 flex items-center">
                <span className="relative z-20 flex h-55 w-55 items-center justify-center rounded-50 bg-030654">
                  <Letter />
                </span>
                <a
                  href={`mailto:${TextCopies.email(prefix)}`}
                  className="text-20 ml-20 font-bold leading-8 text-333333"
                >
                  {TextCopies.email(prefix)}
                </a>
              </div>
              <div className="mb-20 flex items-center">
                <span className="relative z-20 flex h-55 w-55 items-center justify-center rounded-50 bg-030654">
                  <Mobile />
                </span>
                <a
                  href={`tel:${TextCopies.countryPhone(prefix)}`}
                  className="text-20 ml-20 font-bold leading-8 text-333333"
                >
                  {TextCopies.countryPhone(prefix)}
                </a>
              </div>
            </div>
            <ContactUsWavy className="absolute hidden max1200:right-0 max1200:top-0 max1200:block" />
            <ContactUsBox prefix={prefix}>
              <ContactUsWavy className="absolute top-min200 max1200:hidden" />
            </ContactUsBox>
          </div>
        </section>
        <Treatment
          isBtn={true}
          blueText={"Nos traitements"}
          titleText={"Découvrez notre gamme de plans de traitement sur mesure."}
          link={
            TextCopies.assessmentQuizLinks(prefix)
              ? TextCopies.assessmentQuizLinks(prefix)
              : returnLink(
                  "Website-Quiz",
                  "Supplementary-Quiz",
                  "contactselfassessquiz",
                  false,
                  prefix
                )
          }
          prefix={prefix}
        />
        <Faqs
          className="max680:mb-145"
          blueText="FAQS"
          titleText="Foire aux questions"
          isButton={true}
          bottomText={true}
          link={
            TextCopies.assessmentQuizLinks(prefix)
              ? TextCopies.assessmentQuizLinks(prefix)
              : returnLink(
                  "Website-Quiz",
                  "Supplementary-Quiz",
                  "contactusfaqquiz",
                  false,
                  prefix
                )
          }
          prefix={prefix}
        />
      </main>
    </Layout>
  )
}

export default Contacts
