import * as React from "react"
import { navigate } from "gatsby"
import { ArticleCard } from "./articleCard"
import { ImageTextEllipse } from "../common/ImageTextEllipse"
import { CustomButton } from "../common/CustomButton"
import { CustomSlider } from "../common/Slider/CustomSlider"
import "./articles.css"
import { useWindowWidth } from "../../hooks/useWindowWidth"
import { IndexTextComponents } from "../TextComponents"

export const Articles = ({
  blueText,
  titleText,
  className,
  slider = false,
  buttonClass,
  articles,
  prefix,
}) => {
  const navigateToArticle = (
    img,
    text,
    title,
    articles,
    imgText,
    date,
    uid
  ) => {
    navigate(`/${prefix}/resource/${uid}`, {
      state: {
        img,
        text,
        title,
        articles,
        imgText,
        date,
      },
    })
  }

  const windowWidth = useWindowWidth()

  return (
    <section className={`articles relative ${className}`}>
      <div className="width__container">
        <p className="dashed__text--blue treatments__blue--text blueText">
          {blueText}
        </p>
        <div className="treatments__title--box">
          <div>
            <h2 className="section__title">{titleText}</h2>
            <p className="mt-12 max-w-900 mr-40">{IndexTextComponents.articlesParagraph(prefix)}</p>
          </div>
          <CustomButton
            className={`articles__btn orangeFilled slim__btn ${buttonClass}`}
            text={
              prefix === "fr-ca"
                ? "Parcourez tous les blogs"
                : "Browse All Blogs"
            }
            navLink={`/${prefix}/resources/#resourcesArticles`}
          />
        </div>
        {(windowWidth >= 1000 || !slider) && (
          <div className="flex justify-between gap-y-80 max1000:flex-wrap max1400:justify-around">
            {articles.map((item, index) => {
              return (
                <ArticleCard
                  key={index}
                  img={item.data.featured_image.url}
                  imgText={item.tags}
                  text={item.data.text}
                  title={item.data.title.text}
                  date={item.data.date}
                  uid={item.uid}
                  articles={articles}
                  prefix={prefix}
                />
              )
            })}
          </div>
        )}
      </div>
      {slider && windowWidth < 1000 && (
        <div>
          <CustomSlider isBtn={false} slidesToShow={1} centerMode={true}>
            {articles.map((item, index) => {
              const img = item.data.featured_image.url
              const text = item.data.text
              const title = item.data.title.text
              const imgText = item.tags
              const date = item.data.date
              const uid = item.uid
              return (
                <div
                  onClick={() =>
                    navigateToArticle(
                      img,
                      text,
                      title,
                      articles,
                      imgText,
                      date,
                      uid
                    )
                  }
                  className="cursor-pointer px-5"
                  key={index}
                >
                  <div className="relative mb-20 w-full max-w-580" key={index}>
                    <div
                      className="article__card--img rounded-20"
                      style={{
                        backgroundImage: `url(${item.data.featured_image.url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                      }}
                    ></div>
                    <div
                      className={`relative w-max max500:px-5 ${item.tags.length === 1 ? "mb-45" : "mb-20"
                        }`}
                    >
                      <ImageTextEllipse
                        textArr={item.tags}
                        className="imageTextEllipse imageTextEllipseGreyBg block"
                      />
                    </div>
                    <h3 className="mb-20 max500:px-5 max500:text-15">
                      {item.data.title.text}
                    </h3>
                  </div>
                </div>
              )
            })}
          </CustomSlider>
        </div>
      )}
    </section>
  )
}
