import React from "react"
import PrivacyPolicy from "../../components/PageComponents/fr-ca/privacy-policy"

const PrivacyPolicyPage = () => {
  const prefix = "fr-ca"

  return <PrivacyPolicy prefix={prefix} />
}

export default PrivacyPolicyPage
