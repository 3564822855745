import * as React from "react"
import Div100vh from "react-div-100vh"

const WelcomeVideoCA = () => {
  return (
    <Div100vh>
      <iframe
        src={`https://player.vimeo.com/video/635035287?autoplay=1`}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      ></iframe>
    </Div100vh>
  )
}

export default WelcomeVideoCA
