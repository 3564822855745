import * as React from "react"
import { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Layout } from "./layout/layout"
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo"
import { FeaturedOn } from "../../../components/featuredOn/featuredOn"
import { CustomButton } from "../../../components/common/CustomButton"
import { Select } from "./common/Select"
import { Checkbox } from "../../../components/common/Checkbox"
import { returnLink } from "../../../helpers/returnLink"
import { blockedLeadEmails } from "../../../helpers/blockedLeadEmails"
import HeaderRightSvg from "../../../assets/header/headerRight.svg"
import InfoPackImg from "../../../images/download-ebook/downloadImg.webp"
import axios from "axios"

const optionArray = [
  { text: "Dysfonction érectile", value: "Erectile Dysfunction" },
  { text: "Éjaculation précoce", value: "Premature Ejaculation" },
]

const DownloadBook = ({ prefix }) => {
  // WEBPAGE SCHEMA START //
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Téléchargez votre livre électronique gratuit",
    description:
      "Téléchargez votre livre électronique gratuit pour en savoir plus sur la condition que vous pourriez vivre et les options de traitement disponibles.",
    url: `https://menshealthclinic.com/${prefix}/download-ebook`,
  }
  // WEBPAGE SCHEMA END //

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then(res => {
        setSubmitData(prev => {
          return { ...prev, country: res.data.country }
        })
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const url = "https://api.menshealthclinic.com/"

  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("L'enquête concerne")
  const [isChecked, setIsChecked] = useState(false)
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [error, setError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [submitData, setSubmitData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    pdf_selection: "",
    lead_source: "Website Form",
    web_form_completed: "E-Book Download",
    website_form_url: `https://menshealthclinic.com/${prefix}/download-ebook`,
    country: "",
  })

  useEffect(() => {
    if (submitData.pdf_selection === "Chronic Pain") {
      setSubmitData(prev => {
        return { ...prev, country: "PCA" }
      })
    }
  }, [submitData.pdf_selection])

  const handleSubmit = async () => {
    if (
      !submitData.first_name ||
      !submitData.last_name ||
      !submitData.email ||
      !submitData.mobile ||
      !submitData.pdf_selection ||
      !submitData.country
    ) {
      setError("Le champ est obligatoire")
      return
    }

    if (!validateEmail(submitData.email)) {
      setEmailError("Veuillez entrer une adresse e-mail valide")
      return
    }

    if (blockedLeadEmails.includes(submitData.email)) {
      setEmailError("L'utilisation de l'adresse e-mail n'est pas possible")
      return
    }

    try {
      const response = await axios.post(
        `${url}public/v1/inquiry/download-book`,
        submitData
      )
      if (response.status === 200) {
        navigate(`/${prefix}/thank-you-pdf`)
      }
    } catch (e) {
      console.log(e)
    }
    return
  }

  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(/\S+@\S+\.\S+/)
  }

  return (
    <Layout
      headerLink={returnLink(
        "Website-Quiz",
        "E-Book Download",
        "ebookdownloadform",
        false,
        prefix
      )}
      footerLink={returnLink(
        "Website-Quiz",
        "E-Book Download",
        "ebookbottomquiz",
        false,
        prefix
      )}
      prefix={prefix}
    >
      <GatsbySeo
        title="Men's Health Clinic | Téléchargez les livres électroniques sur le traitement de la dysfonction érectile, de l'éjaculation précoce et des douleurs chroniques."
        description="Men's Health Clinic aide pour la dysfonction érectile, l'éjaculation précoce et les douleurs chroniques. Téléchargez nos livres électroniques pour plus d'informations sur l'éjaculation précoce et la dysfonction érectile."
        languageAlternates={[
          {
            hrefLang: "x-default",
            href: "https://menshealthclinic.com/au/download-ebook/",
          },
          {
            hrefLang: "en-au",
            href: "https://menshealthclinic.com/au/download-ebook/",
          },
          {
            hrefLang: "en-ca",
            href: "https://menshealthclinic.com/ca/download-ebook/",
          },
          {
            hrefLang: "en-nz",
            href: "https://menshealthclinic.com/nz/download-ebook/",
          },
          {
            hrefLang: "en-ph",
            href: "https://menshealthclinic.com/ph/download-ebook/",
          },
          {
            hrefLang: "en-gb",
            href: "https://menshealthclinic.com/uk/download-ebook/",
          },
          {
            hrefLang: "fr-ca",
            href: "https://menshealthclinic.com/fr-ca/download-ebook/",
          },
        ]}
      />
      <JsonLd json={schema} />
      <article className="mb-85 max680:mb-60">
        <div className="left-blue-semicircle relative h-full min-h-420 overflow-hidden bg-030654 px-25 text-center max680:min-h-320">
          <HeaderRightSvg className="absolute bottom-0 right-0" />
          <h1 className="header__image--title relative inline-block pt-125  max680:pt-60">
            Téléchargez votre livre électronique gratuit
          </h1>
          <p className="relative m-auto w-full max-w-500 pb-25 text-F5F5F5">
            Pour mieux comprendre la condition que vous pourriez vivre et les
            options de traitement disponibles.
          </p>
        </div>
      </article>
      <main>
        <section className="width__container mb-145 flex justify-between max1000:mb-60 max1000:flex-wrap max1000:justify-center">
          <div className="mr-75 w-full max-w-565 rounded-20 max1000:mx-auto max1000:mb-45">
            <img className="rounded-20" src={InfoPackImg} alt="img" />
          </div>
          <form className="relative w-full max-w-675 rounded-md bg-white px-4 py-30 shadow sm:px-7 max600:p-15 max600:pb-25 max600:pt-25">
            <div className="relative mt-20 flex justify-between 900:mb-25 max900:flex-wrap">
              <div className="w-full 1000:mr-10">
                <label
                  htmlFor="first_name"
                  className="block text-14 font-medium text-gray-700"
                >
                  Prénom
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, first_name: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
                {!submitData.first_name && (
                  <span className="text-red-500">{error}</span>
                )}
              </div>
              <div className="w-full 1000:ml-10">
                <label
                  htmlFor="last_name"
                  className="block text-14 font-medium text-gray-700"
                >
                  Nom de famille
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, last_name: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
                {!submitData.last_name && (
                  <span className="text-red-500">{error}</span>
                )}
              </div>
            </div>
            <div className="relative flex justify-between 900:mb-25 max900:flex-wrap">
              <div className="w-full 1000:mr-10">
                <label
                  htmlFor="mobile"
                  className="block text-14 font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, mobile: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                  type="number"
                />
                {!submitData.mobile && (
                  <span className="text-red-500">{error}</span>
                )}
              </div>
              <div className="w-full 1000:ml-10">
                <label
                  htmlFor="email"
                  className="block text-14 font-medium text-gray-700"
                >
                  Adresse e-mail
                </label>
                <input
                  onChange={e =>
                    setSubmitData(prev => {
                      return { ...prev, email: e.target.value }
                    })
                  }
                  className="w-full rounded-md border border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300 max900:mb-25"
                />
                {!submitData.email && (
                  <span className="text-red-500">{error}</span>
                )}
                {emailError && (
                  <span className="text-red-500">{emailError}</span>
                )}
              </div>
            </div>
            <Select
              className="relative mb-25 w-full rounded-md border border-solid border-gray-300 px-3 py-2 text-14 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-gray-300"
              isSelectOpen={isSelectOpen}
              setIsSelectOpen={setIsSelectOpen}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              optionArray={optionArray}
              setSubmitData={setSubmitData}
              error={error}
            />
            {!submitData.inquiry_related && (
              <span className="text-red-500">{error}</span>
            )}
            <Checkbox
              className="mb-50 flex justify-between"
              text="En cliquant sur cette case, vous confirmez avoir lu et accepté nos conditions générales d'utilisation et notre politique de confidentialité, et vous acceptez d'être contacté de manière discrète et privée par téléphone, e-mail, SMS ou tout autre moyen électronique."
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
            <div
              onClick={() => {
                if (isChecked) {
                  return handleSubmit()
                }
                setIsSubmitError(true)
                return
              }}
            >
              {isSubmitError ? (
                <span className="mb-10 inline-block text-FF5733">
                  Vous devez lire et accepter nos conditions avant de pouvoir
                  continuer.
                </span>
              ) : (
                ""
              )}
              <CustomButton
                className="mb-20 flex w-full justify-center rounded-md border border-transparent bg-030654 px-4 py-2 text-sm font-medium text-white shadow-sm transition duration-300 hover:bg-[#00003D] focus:outline-none focus:ring-2 focus:ring-offset-2"
                text="Envoyer un message"
                link="#!"
              />
            </div>
          </form>
        </section>
        <FeaturedOn className="mb-145 max680:mb-125" prefix={prefix} />
      </main>
    </Layout>
  )
}

export default DownloadBook
